import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import MasterPage from "../components/MasterPage";
import GalleryUpdate from "./GalleryUpdate";
import "./Media.css";
import "./Style.css";
import useAuth from "../hooks/useAuth";

const UploadMediaPage = () => {
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [gallery, setGallery] = useState([]);
  const navigate = useNavigate();
  const { anId } = useParams();
  const { isAuthenticated, isLoading } = useAuth(anId);

  const fetchGallery = useCallback(async () => {
    try {
      const host = process.env.REACT_APP_API_HOST;
      
      const response = await axios.get(`${host}/api/perfilGaleria/${anId}`);
      
      console.log("Respuesta de la API:", response.data);
      setGallery(response.data);
    } catch (error) {
      console.error("Error al obtener la galería:", error);
      setError("Error al cargar la galería.");
    }
  }, [anId]);

  useEffect(() => {
    if (!anId) {
      navigate("/main");
    } else if (isAuthenticated) {
      fetchGallery();
    }
  }, [anId, navigate, fetchGallery, isAuthenticated]);

  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
    setError(""); // Limpiar el mensaje de error
    setSuccess(false); // Limpiar el mensaje de éxito
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!selectedFiles) {
      setError("Por favor selecciona un archivo");
      return;
    }

    const formData = new FormData();
    Array.from(selectedFiles).forEach((file) => {
      formData.append("files", file);
    });

    setLoading(true);

    try {
      const host = process.env.REACT_APP_API_HOST;
      
      await axios.post(`${host}/api/upload/${anId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setSuccess(true);
      setLoading(false);
      fetchGallery();
    } catch (error) {
      console.error("Error al subir archivos:", error);
      setError("Hubo un problema al subir los archivos.");
      setLoading(false);
    }
  };

  if (isLoading) {
    return <p>Validando acceso...</p>;
  }

  if (!isAuthenticated) {
    return <p>Acceso no autorizado.</p>;
  }

  return (
    <MasterPage>
      <div className="upload-media-page-custom">
        <h1 className="upload-title-custom">Subir Fotos o Videos</h1>
        <div className="upload-form-container-custom">
          <form onSubmit={handleUpload} className="upload-form-custom">
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              accept="image/*,video/*"
              className="file-input-custom"
            />
            {error && <p className="error-message-custom">{error}</p>}
            {success && (
              <p className="success-message-custom">Archivos subidos exitosamente</p>
            )}
            <button type="submit" className="upload-button-custom" disabled={loading}>
              {loading ? "Subiendo..." : "Subir Archivos"}
            </button>
          </form>
        </div>
        <div className="gallery-container-custom">
          <h2 className="gallery-title-custom">Galería</h2>
          <GalleryUpdate initialImages={gallery} />
        </div>
      </div>
    </MasterPage>
  );
};

export default UploadMediaPage;
