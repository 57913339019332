import React, { useEffect, useState } from "react";
import MasterPage from "../../components/MasterPage";
import CardExample from "../../components/Card"; // Asegúrate de estar importando el archivo correcto
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { useNavigate } from "react-router-dom";


const GirlAllPage = () => {
  const [profiles, setProfiles] = useState([]);
  const navigate = useNavigate();
 
  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const host = process.env.REACT_APP_API_HOST;
        
        const response = await axios.get(`${host}/api/perfil/tipot`);
        
        setProfiles(response.data);

        // Log para verificar los mensajes
        response.data.forEach(profile => {
        });
      } catch (error) {
        console.error("Error fetching perfiles:", error);
      }
    };
    fetchProfiles();
  }, []);


  // Función para manejar el clic en la tarjeta
  const handleCardClick = (AnId) => {
    navigate(`/main/chicas/${AnId}`);
  };

  return (
    <MasterPage>
      <div className="main-page-container">
        <h1>Todas</h1>
        <div>
          <h2 style={{ fontSize: "20px" }}>
            Bienvenido a Khalii, ¡las chicas más lindas están acá!
          </h2>
        </div>

        <Row className="g-4-row">
          {profiles.length > 0 ? (
            profiles.map((profile, index) => {
              // Condición para aplicar las clases CSS
              let columnClass = "profile-col col-lg-4 col-md-6 col-12"; // Clases básicas para todas las tarjetas
 
              if (profile.AnMsgEst && profile.AnEstadoWeb !== 4) {
                if (profile.AnMsgEst.trim().length > 125) {
                  columnClass = "profile-col long-message col-lg-4 col-md-6 col-12"; // Mensajes largos
                } else {
                  columnClass = "profile-col message col-lg-4 col-md-6 col-12"; // Mensajes normales
                }
              }

              return (
                <Col
                  key={index}
                  className={columnClass}
                >
                  <CardExample
                    name={profile.AnName}
                    age={profile.AnAge}
                    imgSrc={profile.imgUrl} // URL de Amazon S3
                    starRating={profile.calificacionTotal} 
                    AnEstadoWeb={profile.AnEstadoWeb}
                    localidad={
                      profile.Barrio?.Localidad?.LocalidadName ||
                      "Localidad no disponible"
                    }
                    barrio={profile.Barrio?.BarrioName || "Barrio no disponible"}
                    anMsgEst={profile.AnMsgEst}
                    AnMsgDateTime={profile.AnMsgDateTime}
                    horario={profile.AnHorario}
                    tipo={profile.AnTipo}
                    onClick={() => handleCardClick(profile.AnId)} // Manejo del clic
                  />
                </Col>
              );
            })
          ) : (
            <p style={{color: 'white'}}>Aún no hay perfiles cargados.</p>
          )}
        </Row>
      </div>
    </MasterPage>
  );
};

export default GirlAllPage;
