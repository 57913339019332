import React, { useEffect, useState } from "react";
import MasterPage from "./MasterPage";
import CardExample from "./CardBooks"; // Asegúrate de estar importando el archivo correcto
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar"; // Importa ProgressBar
import axios from "axios";
import { useNavigate } from "react-router-dom";

const MainPage = () => {
  const [books, setBooks] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Estado para controlar la carga
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const host = process.env.REACT_APP_API_HOST;
        const response = await axios.get(`${host}/api/books`);
        setBooks(response.data);
      } catch (error) {
        console.error("Error fetching perfiles:", error);
      } finally {
        setIsLoading(false); // Indicar que la carga ha finalizado
      }
    };
    fetchBooks();
  }, []);

  const handleCardClick = (BookId) => {
    navigate(`/main/books/${BookId}`);
  };

  return (
    <MasterPage>
      <div className="main-page-container">
        <h1>Books</h1>
        <div>
          <h2 style={{ fontSize: "20px" }}>
            Fotos exclusivas!!! 🔥❤️
          </h2>
        </div>

        {/* Barra de progreso */}
        {isLoading && (
          <div className="progress-container" style={{ width: '100%', marginTop: '80px' }}>
            <ProgressBar animated now={100} label="Cargando perfiles..." />
          </div>
        )}

        {/* Si no estamos cargando, mostramos los perfiles */}
        {!isLoading && (
          <Row className="g-4-row">
            {books.length > 0 ? (
              books
                
                .map((book, index) => {
                  let columnClass = "profile-col col-lg-4 col-md-6 col-12";
                

                  return (
                    <Col key={index} className={columnClass}>
                      <CardExample
                        name={book.BookName}
                       
                        imgSrc={book.BookImgUrl}
                        
                        
                        onClick={() => handleCardClick(book.BookId)}
                      />
                    </Col>
                  );
                })
            ) : (
              <p>No book available</p>
            )}
          </Row>
        )}
      </div>
    </MasterPage>
  );
};

export default MainPage;
