import React, { useEffect, useState } from "react";
import MasterPage from "../components/MasterPage";
import CardExample from "../components/Card"; // Asegúrate de estar importando el archivo correcto
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProgressBar from "react-bootstrap/ProgressBar"; // Importa ProgressBar
import axios from "axios";
import { useNavigate } from "react-router-dom";

const MainPage = () => {
  const [profiles, setProfiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Estado para controlar la carga
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const host = process.env.REACT_APP_API_HOST;
        const response = await axios.get(`${host}/api/perfil`);
        setProfiles(response.data);
        console.log('perfil: ', response.data);
      } catch (error) {
        console.error("Error fetching perfiles:", error);
      } finally {
        setIsLoading(false); // Indicar que la carga ha finalizado
      }
    };
    fetchProfiles();
  }, []);

  const handleCardClick = (AnId) => {
    navigate(`/main/chicas/${AnId}`);
  };

  return (
    <MasterPage>
      <div className="main-page-container">
        <h1>Khalii</h1>
        <div>
          <h2 style={{ fontSize: "20px" }}>
            Bienvenido a Khalii, ¡las chicas más lindas están acá!
          </h2>
        </div>

        {/* Barra de progreso */}
        {isLoading && (
          <div className="progress-container" style={{ width: '100%', marginTop: '80px' }}>
            <ProgressBar animated now={100} label="Cargando perfiles..." />
          </div>
        )}

        {/* Si no estamos cargando, mostramos los perfiles */}
        {!isLoading && (
          <Row className="g-4-row">
            {profiles.length > 0 ? (
              profiles
                .filter(
                  (profile) => profile.AnEstado === 5 || profile.AnEstado === 1
                )
                .map((profile, index) => {
                  let columnClass = "profile-col col-lg-4 col-md-6 col-12";
                  if (profile.AnMsgEst && profile.AnEstadoWeb !== 4) {
                    columnClass =
                      profile.AnMsgEst.trim().length > 125
                        ? "profile-col long-message col-lg-4 col-md-6 col-12"
                        : "profile-col message col-lg-4 col-md-6 col-12";
                  }

                  return (
                    <Col key={index} className={columnClass}>
                      <CardExample
                        name={profile.AnName}
                        age={profile.AnAge}
                        imgSrc={profile.imgUrl}
                        starRating={profile.calificacionTotal}
                        AnEstadoWeb={profile.AnEstadoWeb}
                        localidad={profile.Barrio?.Localidad?.LocalidadName || "Localidad no disponible"}
                        barrio={profile.Barrio?.BarrioName || "Barrio no disponible"}
                        anMsgEst={profile.AnMsgEst}
                        AnMsgDateTime={profile.AnMsgDateTime}
                        horario={profile.AnHorario}
                        tipo={profile.AnTipo}
                        onClick={() => handleCardClick(profile.AnId)}
                      />
                    </Col>
                  );
                })
            ) : (
              <p>No profiles available</p>
            )}
          </Row>
        )}
      </div>
    </MasterPage>
  );
};

export default MainPage;
