import React, { useState, useMemo } from "react";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComment,
  /* faThumbsUp,
  faThumbsDown,*/
} from "@fortawesome/free-solid-svg-icons";
import "./Style.css"; // Archivo de estilos para el efecto flip

const CardExample = React.memo(
  ({
    name,
    age,
    imgSrc,
    AnEstadoWeb,
    localidad,
    barrio,
    anMsgEst,
    AnMsgDateTime,
    horario,
    starRating,
    videoCount,
    tipo,
    onClick,
  }) => {
    const [flipped, setFlipped] = useState(false);

    // Función para prevenir acciones no deseadas
    const preventDefault = (e) => e.preventDefault();

    // Memoizar cálculo del tiempo transcurrido
    const timeAgo = useMemo(() => {
      const now = new Date();
      const messageDate = new Date(AnMsgDateTime);
      const diffInSeconds = Math.floor((now - messageDate) / 1000);

      if (diffInSeconds < 60) return `${diffInSeconds} seg.`;
      if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} min.`;
      if (diffInSeconds < 86400)
        return `${Math.floor(diffInSeconds / 3600)} hrs.`;
      return `${Math.floor(diffInSeconds / 86400)} días`;
    }, [AnMsgDateTime]);

    // Calcular imagen de estado una sola vez

    const statusImage = useMemo(() => {
    if(AnEstadoWeb ===1 && tipo === 1){
      return "/femaleActive.png";
    }
    if(AnEstadoWeb ===2 && tipo === 1){
      return "/femaleMissing.png";
    }
    if(AnEstadoWeb ===3 && tipo === 1){
      return "/femaleBusy.png";
    }
    if(AnEstadoWeb ===4 && tipo === 1){
      return "/femaleNotActive.png";
    }
    if(AnEstadoWeb == null  && tipo === 1){
      return "/femaleNotActive.png";
    }

    if(AnEstadoWeb ===1 && tipo === 2){
      return "/TransActive.png";
    }
    if(AnEstadoWeb ===2 && tipo === 2){
      return "/TransMissing.png";
    }
    if(AnEstadoWeb ===3 && tipo === 2){
      return "/TransBusy.png";
    }
    if(AnEstadoWeb ===4 && tipo === 2){
      return "/TransNoActive.png";
    }
    if(AnEstadoWeb == null  && tipo === 2){
      return "/TransNoActive.png";
    }
    }, [AnEstadoWeb, tipo]); 

    // Manejar el clic en la tarjeta y activar la navegación
    const handleCardClick = () => {
      setFlipped(true); // Iniciar la animación de volteo
      setTimeout(() => {
        onClick(); // Navegar al perfil después de la animación
      }, 600); // Duración de la animación
    };

    const displayMessage = (message) => {
      if (message.trim().length > 100) {
        return (
          <>
            {message.slice(0, 100)}...{" "}
            <span
              className="ver-mas"
              style={{ fontWeight: "bold", cursor: "pointer" }}
            >
              ver más
            </span>
          </>
        );
      }
      return message;
    };

    return (
      <div
        className={`card-container ${flipped ? "flipped" : ""}`}
        onClick={handleCardClick}
      >
        <div className="card-inner">
          <Card style={{ cursor: "pointer" }} className="custom-card">
            <div className="image-container">
              <Card.Img
                variant="top"
                src={imgSrc}
                onContextMenu={preventDefault}
                onDragStart={preventDefault}
                alt={`Imagen de ${name}`}
              />
              <div
                className="stars"
                style={{
                  position: "absolute",
                  bottom: "10px",
                  left: "10px",
                }}
              >
                {starRating > 0 ? (
                  <span className="star">★</span>
                ) : (
                  <span className="empty-star">☆</span>
                )}
                {starRating > 0 && (
                  <span className="bold-rating" style={{ marginLeft: "5px" }}>
                    {Number(starRating).toFixed(1)}
                  </span>
                )}
              </div>
            </div>
            <Card.Body className="card-body">
              <div className="card-header">
                <img
                  className="status-img"
                  src={statusImage}
                  alt="status"
                />
                <Card.Title className="card-titleh5">
                  {`${name.trim()}, ${age}`}
                </Card.Title>
              </div>
              <Card.Text>{`${localidad} - ${barrio}`}</Card.Text>
              <div className="card-horario">Horario: {horario}</div>
              {(AnEstadoWeb === 1 ||
                AnEstadoWeb === 2 ||
                AnEstadoWeb === 3) && (
                <div className="estado-contenido">
                  <FontAwesomeIcon
                    icon={faComment}
                    className="icono-mensaje"
                  />
                  <div className="mensaje-texto">
                    <span className="mensaje-tiempo">{timeAgo}</span>
                    <span>{displayMessage(anMsgEst)}</span>
                  </div>
                </div>
              )}
            </Card.Body>
          </Card>
          <div className="card-back">{/* Contenido de la parte trasera */}</div>
        </div>
      </div>
    );
  }
);

export default CardExample;
