import React, { useState, useEffect } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import axios from "axios";

const host = process.env.REACT_APP_API_HOST;


export default function GalleryUpdate({ initialImages = [] }) {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [menuOpen, setMenuOpen] = useState(null);
  const [images, setImages] = useState(() => {
    const savedImages = localStorage.getItem("images");
    return savedImages ? JSON.parse(savedImages) : initialImages;
  });

  const isVideo = (fileUrl) => /\.(mp4|webm|ogg)$/i.test(fileUrl.split("?")[0]);

  const handleMediaClick = (i) => {
    setIndex(i);
    //setIsCurrentVideo(isVideo(images[i].signedUrl));
    setOpen(true);
  };

  const handleMenuClick = (e, i) => {
    e.stopPropagation();
    //setSelectedImage(images[i]); // Establece selectedImage
    setMenuOpen(menuOpen === i ? null : i);
  };

  const handleMenuAction = async (action, selectedImage) => {
    console.log(`Acción: ${action}`);
    setMenuOpen(null);

    if (!selectedImage || !selectedImage.AnId || !selectedImage.Perfil_Img_GalImg_GXI) {
      console.error("selectedImage está indefinido o incompleto.");
      return;
    }

    try {
      if (action === "foto perfil") {
        const response = await axios.put(
          `${host}/api/updateGallery/fotoPerfil`, 
          { AnId: selectedImage.AnId, Perfil_Img_GalImg_GXI: selectedImage.Perfil_Img_GalImg_GXI }
        );
        console.log(response.data.message);
      } else if (action === "foto estado") {
        const response = await axios.put(
          `${host}/api/updateGallery/fotoPortada`, 
          { AnId: selectedImage.AnId, Perfil_Img_GalImg_GXI: selectedImage.Perfil_Img_GalImg_GXI }
        );
        console.log(response.data.message);
      }
      else if (action === "eliminar") {
        // Llamada DELETE para eliminar la imagen de la galería
        const response = await axios.delete(
          `${host}/api/updateGallery/eliminarFoto/${selectedImage.AnId}/${selectedImage.Perfil_Img_GalId}`
        );
        console.log(response.data.message);
        
        // Actualizar la lista de imágenes después de la eliminación
        const updatedImages = images.filter(
          (image) => image.Perfil_Img_GalImg_GXI !== selectedImage.Perfil_Img_GalImg_GXI
        );
        setImages(updatedImages);
        localStorage.setItem("images", JSON.stringify(updatedImages));
      }
    } catch (error) {
      console.error(`Error al actualizar ${action}:`, error);
    }
  };

  const updateImageOrder = async (newOrder) => {
    try {
      console.log("array que envia: ", newOrder);
      const response = await axios.put(
        `${host}/api/updateGallery/orden`,
        { images: newOrder }
      );

      if (response.status !== 200) {
        throw new Error("Error al actualizar el orden de las imágenes");
      }

      console.log("Orden de imágenes actualizado en el backend");
    } catch (error) {
      console.error("Error al actualizar el orden:", error);
    }
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedImages = Array.from(images);
    const [removed] = reorderedImages.splice(result.source.index, 1);
    reorderedImages.splice(result.destination.index, 0, removed);

    // Asigna índices consecutivos a cada imagen
    const updatedImages = reorderedImages.map((image, newIndex) => ({
      ...image,
      index: newIndex,
    }));

    setImages(updatedImages);
    localStorage.setItem("images", JSON.stringify(updatedImages)); // Asegúrate de guardar en localStorage
    updateImageOrder(updatedImages);
  };

  useEffect(() => {
    const savedImages = localStorage.getItem("images");
    if (!savedImages || JSON.stringify(initialImages) !== savedImages) {
      setImages(initialImages);
      localStorage.setItem("images", JSON.stringify(initialImages));
    }
  }, [initialImages]);

  useEffect(() => {
    const checkIsMobile = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", checkIsMobile);
    checkIsMobile();
    return () => window.removeEventListener("resize", checkIsMobile);
  }, []);

  const renderMenu = (i) => {
    const file = images[i];
    const isImage = !isVideo(file.signedUrl); // Verifica si el archivo es una imagen
  
    return (
      <div
        className="menu-dropdown"
        style={{ position: "absolute", top: "35px", right: "5px", zIndex: 30 }}
      >
        <ul>
          {isImage && (
            <>
              <li
                onClick={(e) => {
                  e.stopPropagation();
                  handleMenuAction("foto perfil", images[i]);
                }}
              >
                Foto perfil
              </li>
              <li
                onClick={(e) => {
                  e.stopPropagation();
                  handleMenuAction("foto estado", images[i]);
                }}
              >
                Foto estado
              </li>
            </>
          )}
          <li
            onClick={(e) => {
              e.stopPropagation();
              handleMenuAction("eliminar", images[i]);
            }}
          >
            Eliminar
          </li>
          <li
            onClick={(e) => {
              e.stopPropagation();
              handleMenuAction("ocultar", images[i]);
            }}
          >
            Ocultar
          </li>
        </ul>
      </div>
    );
  };
  
  return (
    <div
      className="content"
      style={{
        marginLeft: isMobile ? "-2px" : "37px",
        marginTop: "-10px",
        height: "80vh",
        overflowY: "auto",
      }}
    >
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable-gallery">
          {(provided) => (
            <div
              className="gallery-grid"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {images.length > 0 ? (
                images.map((file, i) => (
                  <Draggable
                    key={file.id || `file-${i}`}
                    draggableId={file.id ? String(file.id) : `file-${i}`}
                    index={i}
                  >
                    {(provided) => (
                      <div
                        className="gallery-item"
                        onClick={() => handleMediaClick(i)}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onDragStart={(e) => {
                          e.stopPropagation(); // Previene el cierre del Lightbox
                        }}
                      >
                        {isVideo(file.signedUrl) ? (
                          <div className="video-thumbnail-container">
                            <video
                              className="gallery-video-thumbnail"
                              src={file.signedUrl + "#t=0.5"}
                              muted
                              playsInline
                              style={{
                                objectFit: "cover",
                                width: "100%",
                                height: "100%",
                              }}
                            />
                            <div
                              className="play-icon-overlay"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleMediaClick(i);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faPlayCircle}
                                className="play-icon"
                              />
                            </div>
                          </div>
                        ) : (
                          <img
                            className="gallery-image-thumbnail"
                            src={file.signedUrl}
                            alt={`Imagen ${i + 1}`}
                            style={{
                              objectFit: "cover",
                              width: "100%",
                              height: "100%",
                            }}
                            loading="lazy"
                          />
                        )}
                        <button
                          className="menu-button"
                          onClick={(e) => handleMenuClick(e, i)}
                          style={{
                            position: "absolute",
                            top: "1px",
                            right: "5px",
                            zIndex: 20,
                            background: "darkgray",
                            border: "none",
                            cursor: "pointer",
                          }}
                        >
                          <FontAwesomeIcon icon={faEllipsisV} />
                        </button>
                        {menuOpen === i && renderMenu(i)}
                      </div>
                    )}
                  </Draggable>
                ))
              ) : (
                <p>No hay imágenes disponibles.</p>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={images.map((file) => ({
          type: isVideo(file.signedUrl) ? "video" : "image",
          src: file.signedUrl,
          alt: "Vista de archivo multimedia",
          width: 1920,
          height: 1080,
          poster: isVideo(file.signedUrl)
            ? `${file.signedUrl}#t=0.5`
            : undefined,
        }))}
        index={index}
        onIndexChange={setIndex}
        render={{
          slide: ({ slide }) => {
            const isCurrentVideo = images[index]?.signedUrl === slide.src;
           // console.log(`Renderizando slide. Activo: ${isCurrentVideo}, URL: ${slide.src}`);
            
            return isVideo(slide.src) ? (
              <ReactPlayer
                url={slide.src}
                controls
                width="99vw"
                height="100vh"
                playing={open && isCurrentVideo} // Reproduce solo si está abierto y es el video actual
                light={false} // Desactiva la miniatura cuando se abre
              />
            ) : undefined
          },
        }}
      />
    </div>
  );
}
