import React from "react";
import { Card, Button } from "react-bootstrap";
import "../components/khalii.css";
import MasterPage from "./MasterPage";
import { useNavigate } from "react-router-dom";


const Khalii = () => {
  const handleClose = () => {
    navigate("/main");
  };
  const navigate = useNavigate();
  return (
    <MasterPage>
      <div className="khalii-card-container">
        <div className="khalii-card">
          {/* <h1></h1> */}

          {/* Logo */}
          <img
            src="Logo_Khalii_Header_New.png"
            alt="Logo"
            style={{
              display: "block",
              width: "80%",
              margin: "20px auto",
            }}
          />

          {/* Imagen de contenido */}
          <img
            src="khalii_phoenix.png" // Cambia este nombre con la imagen correspondiente
            alt="Khalii 2.0 phonix version"
            style={{
              display: "block",
              width: "100%",
              height: "auto",
              marginTop: "20px",
            }}
          />

          {/* Tarjeta con el mensaje */}
          <Card style={{ width: "100%" }} className="p-4 text-center">
            <Card.Body>
              <Card.Title>Version 2.0</Card.Title>
              <Card.Text>Phoenix</Card.Text>
              {/* Botón de cerrar */}
              <Button
                style={{ marginTop: "10px" }}
                className="custom-button"
                onClick={handleClose}
              >
                Cerrar
              </Button>
            </Card.Body>
          </Card>
        </div>
      </div>
    </MasterPage>
  );
};

export default Khalii;
