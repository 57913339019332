import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import MasterPage from "../components/MasterPage";
import Card1 from "../components/CardType1"; // Importa la Card1
import Card2 from "../components/CardType2"; // Importa la Card2
import Card3 from "../components/CardType3"; // Importa la Card2
import Card4 from "../components/CardType4";
import GalleryCard from "../components/GalleryCard"; // Importa la galería
import defaultUserImg from "../images/user.png";

const host = process.env.REACT_APP_API_HOST;
//const port = process.env.REACT_APP_API_PORT;

const PerfilPage = () => {
  const { profileId } = useParams(); // Obtiene el ID del perfil desde la URL
  const [profile, setProfile] = useState(null);
  const [galeria, setGaleria] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Estado para dispositivo móvil
  const [calificaciones, setCalificaciones] = useState([]);
  const [calificacionTotal, setCalificacionTotal] = useState(null);
  const navigate = useNavigate();

  const handleBack = () => {
    window.history.back();
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Actualiza si es móvil o no
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchProfile = async () => {
      try {
        const response = await axios.get(
          `${host}/api/perfil/${profileId}`
        );
        /* console.log("profile: ", response.data); */
        setProfile(response.data);
        
      } catch (error) {
        console.error("Error al obtener el perfil:", error);
      }
    };

    const fetchGaleria = async () => {
      try {
        const response = await axios.get(
          `${host}/api/perfilGaleria/${profileId}`
        );
        setGaleria(response.data); // Asegúrate de que esto sea correcto
        console.log('Galeria perfil', response.data);
      } catch (error) {
        console.error("Error al obtener la galería:", error);
      }
    };
    const fetchCalificaciones = async () => {
      try {
        const response = await axios.get(
          `${host}/api/calificacion/calificacion/${profileId}`
        );

        const { calificaciones, calificacionTotal } = response.data; // Extrae ambos valores
        const fetchedCalificaciones = Array.isArray(calificaciones)
          ? calificaciones
          : [];

        // Procesar cada calificación para agregar la imagen del usuario
        const calificacionesConUsuarios = await Promise.all(
          fetchedCalificaciones.map(async (calificacion) => {
            try {
              const userResponse = await axios.get(
                `${host}/api/profile/${calificacion.CalUser}`
              );
              console.log(userResponse);
              const userImg = userResponse.data.imagenUrl || defaultUserImg; // Usa la imagen por defecto si ImgPerfil está vacío
              return { ...calificacion, userImg };
            } catch (error) {
              console.error(
                `Error al obtener el usuario ${calificacion.CalUser}:`,
                error
              );
              return { ...calificacion, userImg: defaultUserImg }; // Usa la imagen por defecto en caso de error
            }
          })
        );

        setCalificaciones(calificacionesConUsuarios);
        setCalificacionTotal(calificacionTotal); // Establece el total calculado
      } catch (error) {
        console.error("Error al obtener las calificaciones:", error);
      }
    };

    fetchProfile();
    fetchGaleria();
    fetchCalificaciones(); // Llama a la función para obtener calificaciones
  }, [profileId]);

  console.log('profile', profile);

  if (profile && profile.AnEstado === 6) {
    navigate("/main");
  }
  if (!profile) {
    return <p>Cargando perfil...</p>;
  }

  return (
    <MasterPage>
      <div className={isMobile ? "content custom-margin" : ""}>
        <h1 onClick={handleBack} style={{ cursor: "pointer" }}>
          {"<"}
        </h1>
        <div className="content_prf">
          <div>
            {/* Card 1: Imagen, Nombre, Barrio y Localidad */}
            <Card1
              AnId={profile.AnId}
              imgSrc={profile.imgUrl}
              AnName={profile.AnName}
              age={profile.AnAge}
              Barrio={profile.Barrio?.BarrioName || "Barrio no disponible"}
              Localidad={
                profile.Localidad?.LocalidadName || "Localidad no disponible"
              }
              horario={profile.AnHorario}
              AnEstadoWeb={profile.AnEstadoWeb}
              anPhone={profile.AnPhone}
              tarifa={profile.AnTarifa}
              tipo={profile.AnTipo}
              starRating={
                calificacionTotal !== null
                  ? calificacionTotal.toFixed(1)
                  : "N/A"
              } // Maneja valor por defecto
              contWP={profile.AnContactoWP}
            />
            {/* Card 2: Mensaje de Estado y Tiempo Transcurrido */}
            <Card2
              AnMsgEst={profile.AnMsgEst || "Aún no ha escrito nada."}
              AnMsgDateTime={profile.AnMsgDateTime}
              imgUrl={profile.imgUrl2} // Imagen del perfil
              AnName={profile.AnName} // Nombre del perfil
              anPhone={profile.AnPhone}
            />
            <Card3 profile={profile} />
            {/* Card 4: Galería */}
            <GalleryCard images={galeria} />
            {/* Mostrar las calificaciones usando Card4 */}
            {calificaciones.length > 0 ? (
              calificaciones.map((calificacion) => (
                <Card4
                  key={calificacion.CalId}
                  calificadorImg={calificacion.userImg}
                  calEstrellas={calificacion.CalEstrellas}
                  calDateTime={calificacion.CalDateTime}
                  calComentario={calificacion.CalComentario}
                  calUsuario={calificacion.CalUser}
                  respuestaImg={profile.imgUrl2}
                  calResDateTime={calificacion.CalResDateTime}
                  calRespuesta={calificacion.CalRespuesta}
                  respuestaNombre={profile.AnName}
                />
              ))
            ) : (
              <p>No hay calificaciones disponibles.</p>
            )}
          </div>
        </div>
      </div>
    </MasterPage>
  );
};

export default PerfilPage;
