import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"; // Importa useNavigate y useSearchParams
import "../components/RegisterPage.css"; // Asegúrate de importar el archivo de estilos CSS

const host = process.env.REACT_APP_API_HOST;

const AltaPerfil = () => {
  const [perfil, setPerfil] = useState({
    AnId: "",
    AnName: "",
    AnPhone: "",
    AnEmail: "",
    AnAge: "",
    AnTarifa: "",
    AnContactoWP: false,
    AnVerTarifa: false,
    AnAtiendeH: false,
    AnAtiendeA: false,
    AnAtiendeD: false,
    AnHorario: "",
    AnObs: "",
    LocalidadId: "",
    BarrioId: "", // Agregado para el barrio
    AnCategoria: "", // Agregado para la categoría
    AnTipo: "", // Agregado para el género
    AnFecIni: "", // Fecha de creación
    AnFecVto: "", // Fecha de vencimiento
  });

  const [localidades, setLocalidades] = useState([]);
  const [barrios, setBarrios] = useState([]); // Nuevo estado para barrios

  const navigate = useNavigate(); // Para redirigir
  const [searchParams] = useSearchParams(); // Para obtener parámetros de la URL

  const handleCancel = () => {
    navigate("/main");
  };
  // Cargar localidades al cargar el componente
  useEffect(() => {
    const fetchLocalidades = async () => {
      try {
        const response = await axios.get(
          `${host}/api/localidades`
        );
        setLocalidades(response.data);
      } catch (error) {
        console.error("Error al obtener las localidades:", error);
      }
    };

    fetchLocalidades();
  }, []);

  // Obtener barrios cuando cambia la localidad seleccionada
  useEffect(() => {
    const fetchBarrios = async () => {
      if (perfil.LocalidadId) {
        try {
          const response = await axios.get(
            `${host}/api/barrio/localidad/${perfil.LocalidadId}`
          );
          setBarrios(Array.isArray(response.data) ? response.data : []);
          
        } catch (error) {
          console.error("Error al obtener los barrios:", error);
        }
      } else {
        setBarrios([]); // Limpiar barrios si no hay localidad seleccionada
      }
    };

    fetchBarrios();
  }, [perfil.LocalidadId]);

  const location = useLocation();
  // Verificar si se recibe el parámetro AnPhone, y redirigir si no está presente
  useEffect(() => {
    const phoneFromParams = searchParams.get("AnPhone"); // Obtener AnPhone de la URL
    const { AnPhone: phoneFromState } = location.state || {}; // Obtener AnPhone del estado pasado con navigate

    if (!phoneFromParams && !phoneFromState) {
      navigate("/main");
    } else {
      const finalPhone = phoneFromParams || phoneFromState;
      setPerfil((prevPerfil) => ({
        ...prevPerfil,
        AnPhone: finalPhone,
      }));
    }
  }, [navigate, searchParams, location.state]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setPerfil((prevPerfil) => ({
      ...prevPerfil,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Eliminar el primer carácter (0) del teléfono y convertir el resto a número
    const phoneWithoutZero = perfil.AnPhone.startsWith("0")
      ? perfil.AnPhone.slice(1)
      : perfil.AnPhone;

    // Fecha de creación y vencimiento
    const currentDate = new Date();
    const expirationDate = new Date(currentDate);
    expirationDate.setDate(expirationDate.getDate() + 30);

    const perfilConId = {
      ...perfil,
      AnId: parseInt(phoneWithoutZero, 10), // Convertir a número
      AnCategoria: parseInt(perfil.AnCategoria, 10), // Guardar como número
      AnTipo: parseInt(perfil.AnTipo, 10), // Guardar como número
      AnFecIni: currentDate.toISOString(), // Fecha actual
      AnFecVto: expirationDate.toISOString(), // Fecha 30 días después
    };

    try {
      const response = await axios.post(
        `${host}/api/perfil`,
        perfilConId
      );
      
      const { token } = response.data;
      if (token) {
        localStorage.setItem("token", token);
       
        // Redirigir a la pantalla de subida de imágenes/videos con el AnId
        navigate(`/upload/${perfilConId.AnId}`);
      }
    } catch (error) {
      console.error("Error al crear perfil:", error);
    }
  };
  return (
    <div>
      <h1>Alta perfil</h1>
      <div className="register-page-container">
        <div className="register-card">
          <form onSubmit={handleSubmit}>
            {/* Combobox de Categoría */}
            <div className="form-group">
              <label htmlFor="AnCategoria">Categoría</label>
              <select
                id="AnCategoria"
                name="AnCategoria"
                value={perfil.AnCategoria}
                onChange={handleChange}
                className="register-select"
                required
              >
                <option value="">Selecciona una categoría</option>
                <option value="1">Elite 1</option>
                <option value="2">Elite 2</option>
                <option value="3">Elite 3</option>
                <option value="4">Normal</option>
              </select>
            </div>

            {/* Nombre */}
            <div className="mb-3">
              <input
                type="text"
                className="register-input"
                id="AnName"
                name="AnName"
                placeholder="Nombre"
                value={perfil.AnName}
                onChange={handleChange}
                required
              />
            </div>

            {/* Combobox de Género */}
            <div className="form-group">
              <label htmlFor="AnTipo">Género</label>
              <select
                id="AnTipo"
                name="AnTipo"
                value={perfil.AnTipo}
                onChange={handleChange}
                className="register-select"
                required
              >
                <option value="">Selecciona un género</option>
                <option value="1">Chica</option>
                <option value="2">Trans</option>
                <option value="3">Chico</option>
              </select>
            </div>

            {/* Contacto por WhatsApp */}
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                id="AnContactoWP"
                name="AnContactoWP"
                checked={perfil.AnContactoWP}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnContactoWP">
                Contacto por WhatsApp?
              </label>
            </div>

            {/* Teléfono */}
            <div className="mb-3">
              <input
                type="text"
                className="register-input"
                id="AnPhone"
                name="AnPhone"
                placeholder="Teléfono"
                value={perfil.AnPhone}
                onChange={handleChange}
                required
              />
            </div>

            {/* Email */}
            <div className="mb-3">
              <input
                type="email"
                className="register-input"
                id="AnEmail"
                name="AnEmail"
                placeholder="Email"
                value={perfil.AnEmail}
                onChange={handleChange}
              />
            </div>

            {/* Edad */}
            <div className="mb-3">
              <input
                type="number"
                className="register-input"
                id="AnAge"
                name="AnAge"
                placeholder="Edad"
                value={perfil.AnAge}
                onChange={handleChange}
                required
              />
            </div>

            {/* Combobox de Localidad */}
            <div className="form-group">
              <label htmlFor="LocalidadId">Departamento</label>
              <select
                id="LocalidadId"
                name="LocalidadId"
                value={perfil.LocalidadId}
                onChange={handleChange}
                className="register-select"
                required
              >
                <option value="">Selecciona un departamento</option>
                {localidades.map((localidad) => (
                  <option
                    key={localidad.LocalidadId}
                    value={localidad.LocalidadId}
                  >
                    {localidad.LocalidadName}
                  </option>
                ))}
              </select>
            </div>

            {/* Combobox de Barrio */}
            <div className="form-group">
              <label htmlFor="BarrioId">Barrio</label>
              <select
                id="BarrioId"
                name="BarrioId"
                value={perfil.BarrioId}
                onChange={handleChange}
                className="register-select"
                required
              >
                <option value="">Selecciona un barrio</option>
                {barrios.map((barrio) => (
                  <option key={barrio.BarrioId} value={barrio.BarrioId}>
                    {barrio.BarrioName}
                  </option>
                ))}
              </select>
            </div>

            {/* Horario */}
            <div className="mb-3">
              <input
                type="text"
                className="register-input"
                id="AnHorario"
                name="AnHorario"
                placeholder="Horario"
                value={perfil.AnHorario}
                onChange={handleChange}
                required
              />
            </div>

            {/* Mostrar tarifa */}
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                id="AnVerTarifa"
                name="AnVerTarifa"
                checked={perfil.AnVerTarifa}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnVerTarifa">
                Mostrar Tarifa
              </label>
            </div>

            {/* Tarifa */}
            <div className="mb-3">
              <input
                type="number"
                className="register-input"
                id="AnTarifa"
                name="AnTarifa"
                placeholder="Tarifa"
                value={perfil.AnTarifa}
                onChange={handleChange}
              />
            </div>

            <div>
              <h4>Atiendo en:</h4>
            </div>
            {/* Atiendo en: */}
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtiendeH"
                name="AnAtiendeH"
                checked={perfil.AnAtiendeH}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtiendeH">
                Hotel
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtiendeA"
                name="AnAtiendeA"
                checked={perfil.AnAtiendeA}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtiendeA">
                Apto
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtiendeD"
                name="AnAtiendeD"
                checked={perfil.AnAtiendeD}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtiendeD">
                Domicilio
              </label>
            </div>

            {/* Observaciones */}
            <div className="mb-3">
              <textarea
                className="register-input"
                id="AnObs"
                name="AnObs"
                placeholder="Observaciones"
                value={perfil.AnObs}
                onChange={handleChange}
              />
            </div>
            {/*Filtros */}
            {/* Combobox de Altura */}
            <div className="form-group">
              <label htmlFor="AnAtt1">Altura</label>
              <select
                id="AnAtt1"
                name="AnAtt1"
                value={perfil.AnAtt1}
                onChange={handleChange}
                className="register-select"
                required
              >
                <option value="">Seleccionar</option>
                <option value="1">Alta</option>
                <option value="2">Estatura promedio</option>
                <option value="3">Bajita</option>
              </select>
            </div>
            {/* Combobox de Pelo */}
            <div className="form-group">
              <label htmlFor="AnAtt2">Pelo</label>
              <select
                id="AnAtt2"
                name="AnAtt2"
                value={perfil.AnAtt2}
                onChange={handleChange}
                className="register-select"
                required
              >
                <option value="">Seleccionar</option>
                <option value="1">Rubia</option>
                <option value="2">Morocha</option>
                <option value="3">Pelirroja</option>
                <option value="4">Castaña</option>
                <option value="5">Otro</option>
              </select>
            </div>
            {/* Combobox de Pechos */}
            <div className="form-group">
              <label htmlFor="AnAtt15">Pechos</label>
              <select
                id="AnAtt15"
                name="AnAtt15"
                value={perfil.AnAtt15}
                onChange={handleChange}
                className="register-select"
              >
                <option value="">Seleccionar</option>
                <option value="1">Grandes</option>
                <option value="2">Pequeños</option>
                <option value="3">Naturales</option>
                <option value="4">Operados</option>
              </select>
            </div>
            {/* Combobox de Peso */}
            <div className="form-group">
              <label htmlFor="AnAtt16">Peso</label>
              <select
                id="AnAtt16"
                name="AnAtt16"
                value={perfil.AnAtt16}
                onChange={handleChange}
                className="register-select"
              >
                <option value="">Seleccionar</option>
                <option value="1">Delgada</option>
                <option value="2">Peso promedio</option>
                <option value="3">Gordita</option>
                <option value="4">Grande</option>
              </select>
            </div>
            {/* Combobox de Etnia */}
            <div className="form-group">
              <label htmlFor="AnAtt7">Etnia</label>
              <select
                id="AnAtt7"
                name="AnAtt7"
                value={perfil.AnAtt7}
                onChange={handleChange}
                className="register-select"
              >
                <option value="">Seleccionar</option>
                <option value="1">Asiática</option>
                <option value="2">Blanca</option>
                <option value="3">Latina</option>
                <option value="4">Morena</option>
              </select>
            </div>
            {/* Combobox de Orientacion */}
            <div className="form-group">
              <label htmlFor="AnAtt14">Orientación sexual</label>
              <select
                id="AnAtt14"
                name="AnAtt14"
                value={perfil.AnAtt14}
                onChange={handleChange}
                className="register-select"
              >
                <option value="">Seleccionar</option>
                <option value="1">Heterosexual</option>
                <option value="2">Bisexual</option>
                <option value="3">Homosexual</option>
              </select>
            </div>
            {/* Combobox de Generacion */}
            <div className="form-group">
              <label htmlFor="AnAtt8">Generación</label>
              <select
                id="AnAtt8"
                name="AnAtt8"
                value={perfil.AnAtt8}
                onChange={handleChange}
                className="register-select"
              >
                <option value="">Seleccionar</option>
                <option value="1">Lolitas</option>
                <option value="2">Universitarias</option>
                <option value="3">Maduras</option>
              </select>
            </div>
            {/* Idiomas: */}
            <div>
              <h4>Idiomas:</h4>
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt9"
                name="AnAtt9"
                checked={perfil.AnAtt9}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt9">
                Inglés
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt10"
                name="AnAtt10"
                checked={perfil.AnAtt10}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt10">
                Alemán
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt11"
                name="AnAtt11"
                checked={perfil.AnAtt11}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt11">
                Italiano
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt12"
                name="AnAtt12"
                checked={perfil.AnAtt12}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt12">
                Francés
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt13"
                name="AnAtt13"
                checked={perfil.AnAtt13}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt13">
                Portugués
              </label>
            </div>
            <div>
              <hr></hr>
              <h3 style={{ textAlign: "center" }}>Servicios</h3>
              <hr></hr>
            </div>

            <div>
              <h4>Tipo de servicio:</h4>
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt22"
                name="AnAtt22"
                checked={perfil.AnAtt22}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt22">
                Económicas
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt23"
                name="AnAtt23"
                checked={perfil.AnAtt23}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt23">
                Escort de lujo
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt24"
                name="AnAtt24"
                checked={perfil.AnAtt24}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt24">
                Dama de compañia
              </label>
              <hr></hr>
            </div>

            <div>
              <h4>Sexo oral:</h4>
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt33"
                name="AnAtt33"
                checked={perfil.AnAtt33}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt33">
                Con condón
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt34"
                name="AnAtt34"
                checked={perfil.AnAtt34}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt34">
                Acabado en cuerpo
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt35"
                name="AnAtt35"
                checked={perfil.AnAtt35}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt35">
                Acabado en cara
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt36"
                name="AnAtt36"
                checked={perfil.AnAtt36}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt36">
                Garganta profunda
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt37"
                name="AnAtt37"
                checked={perfil.AnAtt37}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt37">
                Me lo trago
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt38"
                name="AnAtt38"
                checked={perfil.AnAtt38}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt38">
                Al natural
              </label>
              <hr></hr>
            </div>
            <div>
              <h4>Atiendo a:</h4>
            </div>

            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt3"
                name="AnAtt3"
                checked={perfil.AnAtt3}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt3">
                Hombres
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt4"
                name="AnAtt4"
                checked={perfil.AnAtt4}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt4">
                Mujeres
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt5"
                name="AnAtt5"
                checked={perfil.AnAtt5}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt5">
                Parejas
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt17"
                name="AnAtt17"
                checked={perfil.AnAtt17}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt17">
                Trio 2 escorts
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt18"
                name="AnAtt18"
                checked={perfil.AnAtt18}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt18">
                Trio 2 clientes
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt19"
                name="AnAtt19"
                checked={perfil.AnAtt19}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt19">
                Orgías
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt20"
                name="AnAtt20"
                checked={perfil.AnAtt20}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt20">
                Personas disc.
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt21"
                name="AnAtt21"
                checked={perfil.AnAtt21}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt21">
                Swingers
              </label>
              <hr></hr>
            </div>
            <div>
              <h4>Salidas:</h4>
            </div>

            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt25"
                name="AnAtt25"
                checked={perfil.AnAtt25}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt25">
                Domicilios
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt26"
                name="AnAtt26"
                checked={perfil.AnAtt26}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt26">
                Viajes largos
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt27"
                name="AnAtt27"
                checked={perfil.AnAtt27}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt27">
                Viajes cortos
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt28"
                name="AnAtt28"
                checked={perfil.AnAtt28}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt28">
                Hoteles
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt29"
                name="AnAtt29"
                checked={perfil.AnAtt29}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt29">
                Fiestas
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt30"
                name="AnAtt30"
                checked={perfil.AnAtt30}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt30">
                Eventos
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt31"
                name="AnAtt31"
                checked={perfil.AnAtt31}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt31">
                Despedidas
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt32"
                name="AnAtt32"
                checked={perfil.AnAtt32}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt32">
                Viajes al exterior
              </label>
              <hr></hr>
            </div>
            <div>
              <h4>Otros:</h4>
            </div>

            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt39"
                name="AnAtt39"
                checked={perfil.AnAtt39}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt39">
                Lésbico real
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt40"
                name="AnAtt40"
                checked={perfil.AnAtt40}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt40">
                Copro
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt41"
                name="AnAtt41"
                checked={perfil.AnAtt41}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt41">
                Besos en boca
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt42"
                name="AnAtt42"
                checked={perfil.AnAtt42}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt42">
                Beso negro
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt43"
                name="AnAtt43"
                checked={perfil.AnAtt43}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt43">
                Lencería
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt44"
                name="AnAtt44"
                checked={perfil.AnAtt44}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt44">
                Juguetes eróticos
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt45"
                name="AnAtt45"
                checked={perfil.AnAtt45}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt45">
                Footjob
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt46"
                name="AnAtt46"
                checked={perfil.AnAtt46}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt46">
                Fantasías
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt47"
                name="AnAtt47"
                checked={perfil.AnAtt47}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt47">
                Anal
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt48"
                name="AnAtt48"
                checked={perfil.AnAtt48}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt48">
                Strap on
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt60"
                name="AnAtt60"
                checked={perfil.AnAtt60}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt60">
                Venta contenido
              </label>
              <hr></hr>
            </div>

            <div>
              <h4>Lugar:</h4>
            </div>

            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt49"
                name="AnAtt49"
                checked={perfil.AnAtt49}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt49">
                Apto compartido
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt50"
                name="AnAtt50"
                checked={perfil.AnAtt50}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt50">
                Apto privado
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt51"
                name="AnAtt51"
                checked={perfil.AnAtt51}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt51">
                Bebidas
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt52"
                name="AnAtt52"
                checked={perfil.AnAtt52}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt52">
                Cama matrimonio
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt53"
                name="AnAtt53"
                checked={perfil.AnAtt53}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt53">
                Casa
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt54"
                name="AnAtt54"
                checked={perfil.AnAtt54}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt54">
                Centro de masajes
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt55"
                name="AnAtt55"
                checked={perfil.AnAtt55}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt55">
                Climatizado
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt56"
                name="AnAtt56"
                checked={perfil.AnAtt56}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt56">
                Con ducha
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt57"
                name="AnAtt57"
                checked={perfil.AnAtt57}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt57">
                Jacuzzi
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt58"
                name="AnAtt58"
                checked={perfil.AnAtt58}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt58">
                Pago con tarjeta
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="AnAtt59"
                name="AnAtt59"
                checked={perfil.AnAtt59}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="AnAtt59">
                Parking cerca
              </label>
            </div>

            <div>
              <hr style={{ marginTop: "10px" }}></hr>
            </div>
            {/* Botón de envío */}
            <button type="submit" className="register-button">
              Siguiente {">>"}
            </button>
            <button
              type="button"
              className="cancel-button"
              onClick={handleCancel}
              style={{marginTop: "10px"}}
            >
              {"<<"}Cancelar
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AltaPerfil;
