import React, { useState, useEffect  } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'; 
import { useNavigate } from 'react-router-dom'; // Importa useNavigate
import '../components/RegisterPage.css'; 
const host = process.env.REACT_APP_API_HOST;


const RegisterPage = () => {
  const [usuario, setUsuario] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [tipo, setTipo] = useState('E'); // Ejemplo de tipo por defecto
  const [showPassword, setShowPassword] = useState(false); // Estado para mostrar/ocultar contraseña
  const navigate = useNavigate(); // Hook para la redirección
  // Asegura que la página empiece desde la parte superior al cargar
  useEffect(() => {
    window.scrollTo(0, 0); // Desplazar a la parte superior
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validaciones según el tipo seleccionado
    let isValid = true;

    if (tipo === 'E' && !/^\d{9}$/.test(usuario)) {
      alert('El número de teléfono debe tener exactamente 9 dígitos.');
      isValid = false;
    }

    if ((tipo === 'V' || tipo === 'S') && !/\S+@\S+\.\S+/.test(email)) {
      alert('El email debe ser válido.');
      isValid = false;
    }

    if (!isValid) return;

    try {
      const response = await axios.post(`${host}/api/register`, { usuario, password, email, tipo });
            // Redirigir si el registro es exitoso y el tipo es "E"
            if (response.status === 201 && tipo === 'E') {
              navigate(`/altaperfil`, { state: { AnPhone: usuario } }); // Redirigir a AltaPage y pasar el usuario
            } else if (response.status === 201 && tipo === 'V') {
              console.log("usuario que mando a panelv: ", usuario);
              const modifiedUser = usuario; 
              navigate(`/panelv/${modifiedUser}`); // Redirigir a PanelV con el usuario como parámetro

            } else {
              // Manejo de casos donde no cumple ninguna de las condiciones anteriores
              console.error('El registro no se pudo procesar correctamente.');
            }
    } catch (error) {
      console.error('Error al registrar:', error.response.data); // Muestra el mensaje de error del servidor
      alert('Error al registrar: ' + (error.response.data.error || error.message));
    }
  };
  const handleCancel = () => {
    navigate("/main");
  };
  return (
    <div><h1>Registro</h1>
    <div className="register-page-container">
      
      <div className="register-card">
        <form onSubmit={handleSubmit}>
          <label>
            Tipo:
            <select
              value={tipo}
              onChange={(e) => {
                setTipo(e.target.value);
                setUsuario(''); // Limpiar el campo de usuario al cambiar el tipo
                setEmail('');   // Limpiar el campo de email al cambiar el tipo
              }}
              className="register-select"
            >
              <option value="E">Escort</option>
              <option value="V">Visitante</option>
              <option value="S">Empresa</option>
            </select>
          </label>
          <br />
          <label>
            Usuario:
            <input
              type={tipo === 'E' ? 'tel' : 'text'}
              pattern={tipo === 'E' ? '[0-9]*' : undefined} // Solo números para "Escort"
              value={usuario}
              onChange={(e) => setUsuario(e.target.value)}
              required
              className="register-input"
              placeholder={tipo === 'E' ? 'Número de teléfono (9 dígitos)' : 'Usuario'}
              maxLength={tipo === 'E' ? 9 : undefined} // Limitar a 9 dígitos para Escort
            />
          </label>
          <br />
          
            <label>
              Email:
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required // Es requerido si no es Escort
                className="register-input"
                placeholder="Email"
              />
            </label>
         
          <br />
          <label>
            Contraseña:
            <div className="password-container">
              <input
                type={showPassword ? 'text' : 'password'} // Cambia el tipo según el estado
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="register-input"
                placeholder="Contraseña"
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye} // Alternar íconos
                onClick={() => setShowPassword(!showPassword)} // Cambia el estado
                className="password-icon" // Clase para el ícono
              />
            </div>
          </label>
          <br />
          <button type="submit" className="register-button">Registrar</button>
          <button
              type="button"
              className="cancel-button"
              onClick={handleCancel}
              style={{marginTop: "10px"}}
            >
              {"<<"}Cancelar
            </button>
        </form>
      </div>
    </div>
    </div>
  );
};

export default RegisterPage;
