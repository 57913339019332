import React, {  useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faImages,
  faStar,
  faBell,
  faChartBar,
  faDollarSign,
  faTrash,
  faPowerOff,
} from "@fortawesome/free-solid-svg-icons";
import "./mypanel.css"; // Estilos ajustados para la pantalla MyPanel
import MasterPage from "./MasterPage";
import useAuth from "../hooks/useAuth";

/* const port = process.env.REACT_APP_API_PORT; */

const MyPanelAdmin = () => {
  const { modifiedUser } = useParams();
  
  const navigate = useNavigate();
  const isAuthenticated = useAuth(modifiedUser);
  const [showPopup, setShowPopup] = useState(false);

  const handleShowPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleMediaClick = () => {
    navigate("/main/mediav/" + modifiedUser);
  };

  const handleMediaBook = ()=> {
    navigate("/main/mediabookv/" + modifiedUser);
  };

  const handleActivateClick = () => {
    navigate("/estado/" + modifiedUser);
  };

  const handleModifyClick = () => {
    navigate("/updateprofile/" + modifiedUser);
  };
  const handleLogout = () => {
    localStorage.clear(); // Limpia todo el localStorage
    navigate("/login"); // Redirige a la página de inicio de sesión
  };

  if (!isAuthenticated) {
    return <p>Validando acceso...</p>;
  }

  return (
    <MasterPage>
      <div id="mypanel-root">
        <div>
          <h1 style={{ fontSize: "xx-large" }}>Administrador</h1>
          <h2 style={{ fontSize: "xx-large", textAlign: "center" }}>
            {modifiedUser}
          </h2>
          <div className="mypanel-form-page">
            <div className="mypanel-boby-page">
              <div className="mypanel-button-grid">
                <button className="mypanel-grid-button" onClick={handleActivateClick}>
                  <FontAwesomeIcon icon={faPowerOff} /> Activate
                </button>
                <button className="mypanel-grid-button" onClick={handleModifyClick}>
                  <FontAwesomeIcon icon={faUser} /> Perfil
                </button>
                <button
                  className="mypanel-grid-button"
                  onClick={handleMediaClick}
                >
                  <FontAwesomeIcon icon={faImages} /> Fotos y Videos
                </button>

                <button
                  className="mypanel-grid-button"
                  onClick={handleMediaBook}
                >
                  <FontAwesomeIcon icon={faImages} /> Book
                </button>

                <button className="mypanel-grid-button">
                  <FontAwesomeIcon icon={faStar} /> Calificaciones
                </button>
                <button className="mypanel-grid-button">
                  <FontAwesomeIcon icon={faBell} /> Notificaciones
                </button>
                <button className="mypanel-grid-button">
                  <FontAwesomeIcon icon={faChartBar} /> Estadísticas
                </button>
                <button
                  className="mypanel-grid-button"
                  onClick={handleShowPopup}
                >
                  <FontAwesomeIcon icon={faDollarSign} /> Pago
                </button>
                {showPopup && (
                  <div className="popup-overlay">
                    <div className="popup-content">
                      <h2>Khalii aún es gratis</h2>
                      <button onClick={handleClosePopup}>Cerrar</button>
                    </div>
                  </div>
                )}
                <button className="mypanel-grid-button">
                  <FontAwesomeIcon icon={faTrash} /> Eliminar Cuenta
                </button>
                <button className="mypanel-grid-button" onClick={handleLogout}>
                  <FontAwesomeIcon icon={faPowerOff} /> Salir
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MasterPage>
  );
};

export default MyPanelAdmin;
