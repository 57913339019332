import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaWhatsapp, FaUser, FaPhone } from "react-icons/fa";
import MasterPage from "./MasterPage";
import "./mypanelv.css";

const host = process.env.REACT_APP_API_HOST;


const FavoritesPage = () => {
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleWhatsAppClick = (profile) => {
    let cleanedPhone = profile.AnPhone?.trim();

    if (!cleanedPhone) {
      console.error("El número de teléfono no está definido o está vacío");
      return;
    }

    // Quitar el primer número si es un 0
    if (cleanedPhone.startsWith("0")) {
      cleanedPhone = cleanedPhone.slice(1);
    }

    const message = `Hola ${profile.AnName.trim()}, vi tu perfil en Khalii.`;
    const url = `https://wa.me/+598${cleanedPhone}?text=${encodeURIComponent(
      message
    )}`;
    window.location.href = url;
  };

  const handleProfileClick = (profileId) => {
    navigate(`/main/chicas/${profileId}`);
  };

  useEffect(() => {
    const fetchFavorites = async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        alert("Debe iniciar sesión para acceder a esta página.");
        navigate("/login");
        return;
      }

      try {
        const decoded = jwtDecode(token);
        const userId = decoded.id;
        const userTipo = decoded.tipo;
        const currentTime = Math.floor(Date.now() / 1000);

        if (decoded.exp < currentTime) {
          console.warn("El token ha expirado.");
          localStorage.removeItem("token");
          alert("Su sesión ha expirado. Por favor, inicie sesión nuevamente.");
          navigate("/login");
          return;
        }

        if (userTipo !== "V") {
          alert(
            "Debe iniciar sesión como visitante para usar esta funcionalidad."
          );
          navigate("/main");
          return;
        }

        const response = await axios.get(
          `${host}/api/user-favorites/${userId}`
        );

        const anIds = response.data.favorites;
        const profilesPromises = anIds.map((anId) =>
          axios.get(`${host}/api/perfil/${anId}`)
        );

        const profilesResponses = await Promise.all(profilesPromises);
        const profiles = profilesResponses.map((res) => res.data);

        setFavorites(profiles);
      } catch (error) {
        console.error("Error al obtener favoritos:", error);
        alert("Error al cargar los favoritos.");
      } finally {
        setLoading(false);
      }
    };

    fetchFavorites();
  }, [navigate]);

  const handleCallClick = (phone) => {
    window.location.href = `tel:+598${phone.trim()}`;
  };

  return (
    <MasterPage>
      <h1
        style={{
          fontSize: "xx-large",
          textAlign: "center",
          marginBottom: "20px",
          marginTop: "25px",
        }}
      >
        Mis Favoritos
      </h1>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          // alignItems: "center",
          minHeight: "80vh",
          maxWidth: "98%",
        }}
      >
        <Container
          style={{
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "10px",
            maxWidth: "800px", // Limitar ancho si es necesario
            marginBottom: "25px",
          }}
        >
          {loading ? (
            <p>Cargando favoritos...</p>
          ) : favorites.length === 0 ? (
            <p>No tienes favoritos aún.</p>
          ) : (
            <Row>
              {favorites.map((profile) => (
                <Col
                  style={{ marginBottom: "10px" }}
                  key={profile.AnId}
                  xs={12}
                  md={6}
                  className="mb-4"
                >
                  <Card className="p-3 shadow-sm">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50%",
                          overflow: "hidden",
                          marginRight: "20px",
                        }}
                      >
                        <img
                          src={profile.imgUrl}
                          alt={profile.AnName}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <div>
                        <h4>
                          {profile.AnName.trim()}, {profile.AnAge}
                        </h4>
                        <button
                          onClick={() => handleWhatsAppClick(profile)}
                          style={{
                            textDecoration: "none",
                            color: "#25D366",
                            //display: "flex",
                            alignItems: "center",
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                          }}
                        >
                          <FaWhatsapp
                            size={30}
                            style={{ marginRight: "-7px" }}
                          />
                        </button>

                        <button
                          onClick={() => handleProfileClick(profile.AnId)}
                          style={{
                            textDecoration: "none",
                            color: "#6c757d",
                            //display: "flex",
                            alignItems: "center",
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                          }}
                        >
                          <FaUser size={25} style={{ marginRight: "-7px" }} />
                        </button>

                        <button
                          onClick={() => handleCallClick(profile.AnPhone)}
                          style={{
                            textDecoration: "none",
                            color: "#1380e2",
                            //display: "flex",
                            alignItems: "center",
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                          }}
                        >
                          <FaPhone size={25} style={{ marginRight: "-7px" }} />
                        </button>
                        
                      </div>
                    </div>
                  </Card>
                  <hr style={{ marginTop: "5px", border: "1px solid #e5e8ec" }} />
                </Col>
                
              ))}
            </Row>
          )}
        </Container>
      </div>
    </MasterPage>
  );
};

export default FavoritesPage;
