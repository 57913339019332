import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import MasterPage from "../components/MasterPage";
import "../components/AboutUs.css";
const AboutUs = () => {
  return (
    <MasterPage>
       <Container className="about-us-container my-5 p-4 shadow">
        <Row>
          <Col>
            <h1 className="text-center mb-4">Sobre nosotros Khalii.com</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>
                  <strong>Khalii: ¿En qué consiste nuestra labor?</strong>
                </Card.Title>
                <Card.Text>
                  Nuestra misión es permitir que las trabajadoras sexuales
                  puedan gestionar sus actividades de manera independiente y
                  logren alcanzar sus metas laborales con eficiencia y calidad.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>
                  <strong>
                    ¿Son una agencia? ¿Cómo puedo colaborar con ustedes?
                  </strong>
                </Card.Title>
                <Card.Text>
                  No funcionamos como una agencia ni ofrecemos empleo. Khalii es
                  un servicio que puedes utilizar para obtener un entorno y las
                  herramientas necesarias que te permitan mantener tu
                  independencia, todo desde tu dispositivo móvil, en lugar de
                  tener que trabajar en la calle o acudir a lugares que puedan
                  resultar perjudiciales para las trabajadoras sexuales.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>
                  <strong>¿Cuáles son las tarifas que aplican?</strong>
                </Card.Title>
                <Card.Text>
                  No interferimos en tus decisiones. Tú tienes la libertad de
                  establecer tus propias tarifas, ofrecer los servicios que
                  prefieras y definir la forma en que los ofreces. Si tienes
                  dudas sobre las tarifas, puedes revisar los perfiles de otras
                  trabajadoras sexuales para tener una idea de los precios que
                  manejan.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>
                  <strong>
                    ¿Tienen un lugar para encuentros con los clientes?
                  </strong>
                </Card.Title>
                <Card.Text>
                  No, de la misma manera en que tus servicios son
                  independientes, tú tienes la libertad de elegir dónde te
                  encuentras con tus clientes.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>
                  <strong>¿Es necesario firmar un contrato?</strong>
                </Card.Title>
                <Card.Text>
                  No es necesario firmar un contrato. Puedes utilizar Khalii el
                  tiempo que desees, cancelar el servicio en cualquier momento,
                  ocultar tu perfil durante las vacaciones o incluso eliminarlo
                  cuando lo decidas.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>
                  <strong>¿Cómo puedo comenzar a utilizar Khalii?</strong>
                </Card.Title>
                <Card.Text>
                  Para unirte a nuestra plataforma, simplemente visita nuestro
                  sitio web o descarga nuestra aplicación móvil. Luego, sigue
                  los pasos para registrarte como una trabajadora sexual
                  independiente. Proporciona la información necesaria, como tu
                  perfil y tus preferencias, y estarás lista para empezar.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>
                  <strong>¿Qué herramientas y recursos ofrece Khalii?</strong>
                </Card.Title>
                <Card.Text>
                  Khalii te ofrece un espacio virtual donde puedes crear un
                  perfil personalizado que incluye fotos y detalles sobre tus
                  servicios. También proporcionamos herramientas de seguridad y
                  privacidad para proteger tu identidad y datos personales.
                  Puedes recibir mensajes y solicitudes de clientes potenciales
                  a través de la plataforma y gestionar tu negocio de manera
                  eficaz. Nuestro objetivo es brindarte el respaldo necesario
                  para que trabajes de forma autónoma y segura.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>
                  <strong>¿Cómo se realiza el pago por los servicios?</strong>
                </Card.Title>
                <Card.Text>
                  El proceso de pago se realiza directamente entre tú y tus
                  clientes. No intervenimos en las transacciones financieras.
                  Esto te brinda la flexibilidad de establecer tus propios
                  métodos de pago y acuerdos con tus clientes.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>
                  <strong>¿Ofrecen algún tipo de asistencia o soporte?</strong>
                </Card.Title>
                <Card.Text>
                  Sí, estamos aquí para ayudarte. Si tienes preguntas o
                  necesitas asistencia en cualquier momento, puedes comunicarte
                  con nuestro equipo de Atención al Cliente a través de WhatsApp
                  o enviándonos un correo electrónico a contacto@khalii.com.
                  Estamos dispuestos a responder a tus consultas y
                  proporcionarte apoyo siempre que lo necesites.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>
                  <strong>
                    ¿Tienen alguna política de privacidad o seguridad?
                  </strong>
                </Card.Title>
                <Card.Text>
                  Sí, en Khalii nos tomamos la privacidad y la seguridad muy en
                  serio. Hemos implementado medidas para proteger tu información
                  y tu identidad. Además, ofrecemos recomendaciones sobre cómo
                  mantener tus encuentros seguros. Es importante que tomes
                  precauciones adicionales para mantener tu bienestar mientras
                  trabajas de forma independiente.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </MasterPage>
  );
};

export default AboutUs;
