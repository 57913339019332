import React, { useState } from "react";
import { Card } from "react-bootstrap";
import "./Style.css"; // Archivo de estilos para el efecto flip

const CardExample = React.memo(
  ({
    name,
    imgSrc,
    onClick,
  }) => {
    const [flipped, setFlipped] = useState(false);

    // Función para prevenir acciones no deseadas
    const preventDefault = (e) => e.preventDefault();
    // Manejar el clic en la tarjeta y activar la navegación
    const handleCardClick = () => {
      setFlipped(true); // Iniciar la animación de volteo
      setTimeout(() => {
        onClick(); // Navegar al perfil después de la animación
      }, 600); // Duración de la animación
    };
    return (
      <div
        className={`card-container ${flipped ? "flipped" : ""}`}
        onClick={handleCardClick}
      >
        <div className="card-inner">
          <Card style={{ cursor: "pointer" }} className="custom-card">
            <div className="image-container">
              <Card.Img
                variant="top"
                src={imgSrc}
                onContextMenu={preventDefault}
                onDragStart={preventDefault}
                alt={`Imagen de ${name}`}
              />
           {/*    <div
                className="stars"
                style={{
                  position: "absolute",
                  bottom: "10px",
                  left: "10px",
                }}
              >
                {starRating > 0 ? (
                  <span className="star">★</span>
                ) : (
                  <span className="empty-star">☆</span>
                )}
                {starRating > 0 && (
                  <span className="bold-rating" style={{ marginLeft: "5px" }}>
                    {Number(starRating).toFixed(1)}
                  </span>
                )}
              </div> */}
            </div>
            <Card.Body className="card-body">
              <div className="card-header">
                {/* <img
                  className="status-img"
                  src={statusImage}
                  alt="status"
                /> */}
                <h1>
                <Card.Title 
                style={{
                  position: "absolute",
                  bottom: "30px",
                  left: "10px",
                  textShadow: "-2px -2px 0 white,  2px -2px 0 white,  -2px  2px 0 white,  2px  2px 0 white",
                }}>
                  {`${name.trim()}`}
                </Card.Title>
                </h1>
              </div>
              {/* <Card.Text>{`${localidad} - ${barrio}`}</Card.Text>
              <div className="card-horario">Horario: {horario}</div>
              {(AnEstadoWeb === 1 ||
                AnEstadoWeb === 2 ||
                AnEstadoWeb === 3) && (
                <div className="estado-contenido">
                  <FontAwesomeIcon
                    icon={faComment}
                    className="icono-mensaje"
                  />
                  <div className="mensaje-texto">
                    <span className="mensaje-tiempo">{timeAgo}</span>
                    <span>{displayMessage(anMsgEst)}</span>
                  </div>
                </div>
              )} */}
            </Card.Body>
          </Card>
          <div className="card-back">{/* Contenido de la parte trasera */}</div>
        </div>
      </div>
    );
  }
);

export default CardExample;
