import React, { useState, useEffect } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";

export default function GalleryCard({ images }) {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const isVideo = (fileUrl) => {
    const urlWithoutParams = fileUrl.split("?")[0];
    const videoExtensions = /\.(mp4|webm|ogg)$/i;
    return videoExtensions.test(urlWithoutParams);
  };

  const handleMediaClick = (i) => {
    /* console.log(`Click en galería, index: ${i}`); */
    setIndex(i);
    setOpen(true);
  };

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 768); // Considera móvil si el ancho es menor a 768px
    };

    window.addEventListener("resize", checkScreenSize);
    checkScreenSize(); // Llama a la función al montar el componente

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  useEffect(() => {
    const disableContextMenu = (e) => {
      e.preventDefault();
    };

    const disableTouchSave = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };

    const preventLongPressSave = (e) => {
      e.preventDefault();
    };
    window.addEventListener("contextmenu", disableContextMenu);
    window.addEventListener("touchstart", preventLongPressSave);
    window.addEventListener("touchmove", disableTouchSave); 

    return () => {
      window.removeEventListener("contextmenu", disableContextMenu);
      window.removeEventListener("touchstart", preventLongPressSave);
      window.removeEventListener("touchmove", disableTouchSave);
    };
  }, []);

  return (
    <div className="content" style={{ marginLeft: "-10px", marginTop: "-10px" }}>
      <div className={isMobile ? "custom-card" : "custom-card-profile"}>
        <div className="gallery-grid">
          {images.map((file, i) => (
            <div
              className="gallery-item"
              key={i}
              onClick={() => handleMediaClick(i)}
            >
              {isVideo(file.signedUrl) ? (
                <div className="video-thumbnail-container">
                  <video
                    className="gallery-video-thumbnail"
                    src={file.signedUrl + "#t=0.5"}
                    muted
                    playsInline
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                  {/* Ícono de FontAwesome */}
                  <div className="play-icon-overlay">
                    <FontAwesomeIcon
                      icon={faPlayCircle}
                      className="play-icon"
                    />
                  </div>
                </div>
              ) : (
                <img
                  className="gallery-image-thumbnail"
                  src={file.signedUrl}
                  alt={`Imagen ${i + 1}`}
                  style={{ objectFit: "cover", width: "100%", height: "100%" }}
                  loading="lazy"
                />
              )}
            </div>
          ))}
        </div>
      </div>

      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={images.map((file) => ({
          type: isVideo(file.signedUrl) ? "video" : "image",
          src: file.signedUrl,
          alt: "Vista de archivo multimedia",
          width: 1920,
          height: 1080,
          poster: isVideo(file.signedUrl)
            ? `${file.signedUrl}#t=0.5`
            : undefined,
        }))}
        index={index}
        onIndexChange={setIndex}
        render={{
          slide: ({ slide }) => {
            const isCurrentVideo = images[index]?.signedUrl === slide.src;
           // console.log(`Renderizando slide. Activo: ${isCurrentVideo}, URL: ${slide.src}`);
            
            return isVideo(slide.src) ? (
              <ReactPlayer
                url={slide.src}
                controls
                width="99vw"
                height="100vh"
                playing={open && isCurrentVideo} // Reproduce solo si está abierto y es el video actual
                light={false} // Desactiva la miniatura cuando se abre
              />
            ) : (
              <img
                className="gallery-image"
                src={slide.src}
                alt={slide.alt}
                style={{ height: isMobile ? "88vh" : "99vh" }}
              />
            );
          },
        }}
      />
    </div>
  );
}
