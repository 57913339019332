import React from "react";
import { Card } from "react-bootstrap";
import "./CardType4.css";

// Definir la función formatDate en este archivo
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

// Función para verificar si la fecha es válida
const isValidDate = (dateString) => {
  const date = new Date(dateString);
  const thresholdDate = new Date("2000-01-01");

  return date > thresholdDate && !isNaN(date);
};

const CardType4 = ({
  calificadorImg, // Imagen del calificador
  calEstrellas, // Estrellas de calificación
  calDateTime, // Fecha de la calificación
  calComentario, // Comentario de la calificación
  calUsuario,
  respuestaImg, // Imagen de quien responde
  calResDateTime, // Fecha de la respuesta
  calRespuesta, // Comentario de la respuesta
  respuestaNombre,
}) => {
  const isMobile = window.innerWidth <= 768;
  // Condición para no mostrar el bloque de respuesta si calResDateTime es nulo o menor a 01/01/2000
  const showRespuesta = calResDateTime && isValidDate(calResDateTime);

  return (
    <div className="content" style={{ marginLeft: "-10px", marginTop: "-9px" }}>
      <Card className={isMobile ? "custom-card" : "custom-card-profile"}>
        <Card.Body>
          {/* Bloque 1: Información del Calificador */}
          <div className="calificador-info">
            <div className="calificador-image">
              <img
                src={calificadorImg}
                alt="Calificador"
                className="profile-image"
              />
            </div>
            <div className="calificacion-content">
              <div className="calificacion-header">
                <strong>{calUsuario}</strong>{" "}
                <span className="stars">{"⭐".repeat(calEstrellas)}</span>
                <span className="time-since">{formatDate(calDateTime)}</span>
              </div>
              <div className="calificacion-comentario">
                <p>{calComentario}</p>
              </div>
            </div>
          </div>

          {/* Bloque 2: Respuesta */}
          {showRespuesta && (
            <div className="respuesta-info">
              <div className="respuesta-image">
                <img
                  src={respuestaImg}
                  alt="Respuesta"
                  className="profile-image"
                />
              </div>
              <div className="respuesta-content">
                <div className="respuesta-header">
                  <strong>{respuestaNombre}</strong>{" "}
                  <span className="time-since">{formatDate(calResDateTime)}</span>
                </div>
                <div className="respuesta-comentario">
                  <p>{calRespuesta}</p>
                </div>
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default CardType4;
