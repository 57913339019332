// App.js
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useParams,
} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import MainPage from "./pages/MainPage";
import PerfilPage from "./pages/PerfilPage";
import AltaPerfil from "./pages/AltaPerfil";
import UploadMediaPage from "./components/UploadMediaPage";
import ActivityPage from "./pages/ActivityPage";
import TActivityPage from "./pages/Trans/TransActive";
import GirlsAllPage from "./pages/Chicas/Todas"
import TransAllPage from "./pages/Trans/Todas";
import Panel from "./components/MyPanel";
import PanelV from "./components/MyPanelV";
import PanelAdmin from "./components/MyPanelAdmin";
import Media from "./components/Media";
import PrivateRoute from "./hooks/PrivateRoute";
import Estado from "./components/Estado";
import ModifyProfile from "./components/ModifyProfile";
import UserFavorites from "./components/UserFavorites.jsx";
import HousePage from "./pages/HousePage.jsx";
import ProfileFilter from "./pages/ProfileFilter.jsx";
import AdultContentCard from "./pages/AdultContentCard";
import CalificacionPage from "./components/CalificacionPage.jsx";
import ResCalificacionPage from "./components/ResCalificacionPage.jsx";
import AboutUs from "./pages/AboutUs.jsx";
import TermsConditions from "./pages/TermsConditions.jsx";
import Khalii from "./components/Khalii.jsx";
import MediaV from "./components/MediaV.jsx";
import MediaBookV from "./components/MediaBookV.jsx";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword.jsx";
import Books from "./components/Books.jsx";
import Book from "./components/Book.jsx";

function PanelWrapper() {
  const { modifiedUser } = useParams();
  return (
    <PrivateRoute modifiedUser={modifiedUser}>
      <Panel />
    </PrivateRoute>
  );
}
function PanelVWrapper() {
  const { modifiedUser } = useParams();
  return (
    <PrivateRoute modifiedUser={modifiedUser}>
      <PanelV />
    </PrivateRoute>
  );
}
function PanelAWrapper() {
  const { modifiedUser } = useParams();
  return (
    <PrivateRoute modifiedUser={modifiedUser}>
      <PanelAdmin />
    </PrivateRoute>
  );
}

function MediaWrapper() {
  const { anId } = useParams();
  return (
    <PrivateRoute modifiedUser={anId}>
      <Media />
    </PrivateRoute>
  );
}

function EstadoWrapper() {
  const { anId } = useParams();
  return (
    <PrivateRoute modifiedUser={anId}>
      <Estado />
    </PrivateRoute>
  );
}

function CalificacionesWrapper() {
  const { anId } = useParams();
  return (
    <PrivateRoute modifiedUser={anId}>
      <ResCalificacionPage />
    </PrivateRoute>
  );
}

function UploadWrapper() {
  const { anId } = useParams();
  return (
    <PrivateRoute modifiedUser={anId}>
      <UploadMediaPage />
    </PrivateRoute>
  );
}

function UpdateProfileWrapper() {
  const { anId } = useParams();
  return (
    <PrivateRoute modifiedUser={anId}>
      <ModifyProfile />
    </PrivateRoute>
  );
}

function FavoritesWrapper() {
  const { modifiedUser } = useParams();
  return (
    <PrivateRoute modifiedUser={modifiedUser}>
      <UserFavorites />
    </PrivateRoute>
  );
}

function App() {
  const adultContentAccepted = localStorage.getItem("adultContentAccepted") === "true";

  return (
    <Router>
      <Routes>
        {/* Si el usuario no aceptó, mostrar AdultContentCard */}
        {!adultContentAccepted ? (
          <Route path="*" element={<AdultContentCard />} />
        ) : (
          <>
            {/* Redirigir de / a /main */}
            <Route path="/" element={<Navigate to="/main" replace />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/nosotros" element={<AboutUs />} />
            <Route path="/terminos-y-condiciones" element={<TermsConditions />} />
            <Route path="/khalii" element={<Khalii />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/main" element={<MainPage />} />
            <Route path="/main/active" element={<ActivityPage />} />
            <Route path="/main/tactive" element={<TActivityPage />} />
            <Route path="/main/chicas/todas" element={<GirlsAllPage />} />
            <Route path="/main/trans/todas" element={<TransAllPage />} />
            <Route path="/altaperfil" element={<AltaPerfil />} />
            <Route path="/main/chicas/:profileId" element={<PerfilPage />} />
            <Route path="/main/house/:EmpId" element={<HousePage />} /> 
            <Route path="/main/filter" element={<ProfileFilter />} />
            <Route path="/main/chicas/calificar/:anId" element={<CalificacionPage />} />
            <Route path="/main/mediav/:modifiedUser" element={<MediaV />} />
            <Route path="/main/mediabookv/:modifiedUser" element={<MediaBookV />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/main/books" element={<Books/>}/>
            <Route path="/main/books/:BookId" element={<Book/>}/>

            {/* Wrappers para parámetros */}
            <Route path="/panel/:modifiedUser" element={<PanelWrapper />} />
            <Route path="/panelv/:modifiedUser" element={<PanelVWrapper />} />
            <Route path="/panelAdmin/:modifiedUser" element={<PanelAWrapper />} />
            <Route path="/media/:anId" element={<MediaWrapper />} />
            <Route path="/estado/:anId" element={<EstadoWrapper />} />
            <Route path="/calificaciones/:anId" element={<CalificacionesWrapper />} />
            <Route path="/upload/:anId" element={<UploadWrapper />} />
            <Route path="/updateprofile/:anId" element={<UpdateProfileWrapper />} />
            <Route path="/favorites/:modifiedUser" element={<FavoritesWrapper />} />
          </>
        )}
      </Routes>
    </Router>
  );
}

export default App;
