import React, { useState, useEffect } from "react";
import { Card, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  faHeart,
  faShareAlt,
  faClock,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons"; // Importa el ícono de compartir
import { Helmet } from "react-helmet"; // Importar Helmet
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import "yet-another-react-lightbox/styles.css";
import { useNavigate } from "react-router-dom"; // Importa useNavigate
const host = process.env.REACT_APP_API_HOST;

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    El usuario no permite contacto por WhatsApp
  </Tooltip>
);

const CardType1 = ({
  imgSrc,
  AnName,
  age,
  Barrio,
  Localidad,
  horario,
  AnEstadoWeb,
  anPhone,
  starRating,
  tarifa,
  contWP,
  AnId,
  tipo,
}) => {
  const [isFavorited, setIsFavorited] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const getStatusImage = (estado, tipo) => {
    if (AnEstadoWeb === 1 && tipo === 1) {
      return "/femaleActive.png";
    }
    if (AnEstadoWeb === 2 && tipo === 1) {
      return "/femaleMissing.png";
    }
    if (AnEstadoWeb === 3 && tipo === 1) {
      return "/femaleBusy.png";
    }
    if (AnEstadoWeb === 4 && tipo === 1) {
      return "/femaleNotActive.png";
    }
    if (AnEstadoWeb == null && tipo === 1) {
      return "/femaleNotActive.png";
    }

    if (AnEstadoWeb === 1 && tipo === 2) {
      return "/TransActive.png";
    }
    if (AnEstadoWeb === 2 && tipo === 2) {
      return "/TransMissing.png";
    }
    if (AnEstadoWeb === 3 && tipo === 2) {
      return "/TransBusy.png";
    }
    if (AnEstadoWeb === 4 && tipo === 2) {
      return "/TransNoActive.png";
    }
    if (AnEstadoWeb == null && tipo === 2) {
      return "/TransNoActive.png";
    }
  };

   // Registrar visita
   const registrarVisita = async (tipoVisita) => {
    try {
      const response = await axios.post(`${host}/api/perfilVisitas/registrar`, { AnId, tipo: tipoVisita });
      if (response.status === 200) console.log(`Visita ${tipoVisita} registrada.`);
    } catch (error) {
      console.error("Error registrando visita:", error);
    }
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [hasVisited, setHasVisited] = useState(false);
  useEffect(() => {
    if (!hasVisited) {
      registrarVisita("perfil");
      setHasVisited(true); // Marca como visitado
    }
  }, [AnId, hasVisited]); 

  useEffect(() => {
 
    const fetchFavoriteStatus = async () => {
      const token = localStorage.getItem("token");
      if (!token) return;

      try {
        const decoded = jwtDecode(token);
        const userId = decoded.id;

        // Validar si el token ha expirado
        const currentTime = Math.floor(Date.now() / 1000);
        if (decoded.exp < currentTime) {
          console.warn("El token ha expirado.");
          localStorage.removeItem("token");
          alert("Su sesión ha expirado. Por favor, inicie sesión nuevamente.");
          return;
        }

        const url = `${host}/api/user-favorites/${userId}/${AnId}`;
        const response = await axios.get(url, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.status === 200 && response.data.isFavorited) {
          setIsFavorited(true);
        } else {
          setIsFavorited(false);
        }
      } catch (error) {
        console.error("Error al verificar favoritos:", error);
      }
    };

    fetchFavoriteStatus();
  }, [AnId]); // Ejecuta el efecto cuando cambie el AnId

  const handleWhatsAppClick = () => {
    let cleanedPhone = anPhone.trim();

    if (!cleanedPhone) {
      console.error("El número de teléfono no está definido o está vacío");
      return;
    }
    // Quitar el primer número si es un 0
    if (cleanedPhone.startsWith("0")) {
      cleanedPhone = cleanedPhone.slice(1);
    }
    registrarVisita("whatsapp");
    const message = `Hola ${AnName.trim()}, vi tu perfil en Khalii`;
    const url = `https://wa.me/+598${cleanedPhone}?text=${encodeURIComponent(
      message
    )}`;
    window.location.href = url;
  };

  const handleFavoriteClick = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      alert("Debe iniciar sesión para agregar a favoritos.");
      return;
    }

    try {
      const decoded = jwtDecode(token); // Decodifica el token
      const userId = decoded.id; // Asume que el token tiene un campo `userId`
      const userTipo = decoded.tipo; // Asume que el token tiene un campo `tipo`
      const currentTime = Math.floor(Date.now() / 1000); // Tiempo actual en segundos

      // Validar si el token ha expirado
      if (decoded.exp < currentTime) {
        console.warn("El token ha expirado.");
        localStorage.removeItem("token");
        alert("Su sesión ha expirado. Por favor, inicie sesión nuevamente.");
        return;
      }

      // Validar el tipo de usuario
      if (userTipo !== "V") {
        alert(
          "Debe iniciar sesión como visitante para usar esta funcionalidad."
        );
        return;
      }

      // Realizar la llamada a la API
      const url = `${host}/api/user-favorites`;

      /* console.log("urlPOST: ", url); */
      if (!isFavorited) {
        // Agregar a favoritos (POST)
        const payload = { UserId: userId, AnId: AnId };
        /* console.log("payloadPOST: ", payload); */
        try {
          const response = await axios.post(url, payload, {
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (response.status === 201) {
            //alert("Anuncio agregado a favoritos.");
            setIsFavorited(true);
          } else {
            console.error("Error al agregar a favoritos:", response.statusText);
            alert("Hubo un problema al agregar a favoritos.");
          }
        } catch (error) {
          console.error("Error al agregar a favoritos:", error);
          alert("Ocurrió un problema al agregar a favoritos.");
        }
      } else {
        // Quitar de favoritos (DELETE)

        const deleteUrl = `${url}/${userId}/${AnId}`;
        /*     console.log("deleteUrl", deleteUrl); */
        try {
          const response = await axios.delete(deleteUrl, {
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (response.status === 200) {
            alert("Perfil eliminado de favoritos.");
            setIsFavorited(false);
          } else {
            console.error(
              "Error al eliminar de favoritos:",
              response.statusText
            );
            alert("Hubo un problema al eliminar de favoritos.");
          }
        } catch (error) {
          console.error("Error al eliminar de favoritos:", error);
          alert("Ocurrió un problema al eliminar de favoritos.");
        }
      }
    } catch (error) {
      console.error("Error al manejar favoritos:", error);
      alert("Ocurrió un problema. Por favor, inicie sesión nuevamente.");
    }
  };

  const [menuOpen, setMenuOpen] = useState(null);
  const handleShareClick = () => {
    const currentUrl = window.location.href;
    const profileImgUrl = imgSrc;
    const message = `Mira el perfil de ${AnName.trim()}, ${age} años: ${currentUrl}`;

    if (navigator.share) {
      navigator
        .share({
          title: `Perfil de ${AnName}`,
          text: message,
          url: currentUrl,
        })
        .then(() => console.log("Perfil compartido exitosamente"))
        .catch((error) => console.error("Error al compartir", error));
    } else {
      const shareUrl = `https://wa.me/?text=${encodeURIComponent(
        message
      )}&image=${encodeURIComponent(profileImgUrl)}`;
      window.open(shareUrl, "_blank");
    }
  };

  const handlePhoneClick = () => {
    registrarVisita("telefono");
    if (isMobile) {
      window.open(`tel:${anPhone.trim()}`, "_self"); // Inicia la llamada en dispositivos móviles
    }
  };

  const renderMenu = () => (
    <div
      className="menu-dropdown"
      style={
        isMobile
          ? {
              position: "absolute",
              top: "300px",
              right: "5px",
              zIndex: 30,
              fontSize: "19px",
            }
          : { position: "absolute", top: "281px", right: "-56px", zIndex: 30 }
      }
    >
      <ul>
        <li onClick={() => handleMenuAction("Calificar")}>Calificar</li>
        <li onClick={() => handleMenuAction("Denunciar")}>Denunciar</li>
      </ul>
    </div>
  );
  const navigate = useNavigate(); // Inicializa useNavigate
  const handleMenuAction = async (action) => {
    console.log(`Acción: ${action}`);
    setMenuOpen(false);
    try {
      if (action === "Calificar") {
        navigate("/main/chicas/calificar/" + AnId);
      } else if (action === "Denunciar") {
        navigate("/main/denunciar/" + AnId);
      }
    } catch (error) {
      console.error(`Error al ${action}:`, error);
    }
  };

  return (
    <div className="content" style={{ marginLeft: "-10px", marginTop: "11px" }}>
      <Helmet>
        <title>{`Perfil de ${AnName}`}</title>
        <meta property="og:title" content={`Perfil de ${AnName}`} />
        <meta property="og:description" content={`${AnName}, ${age} años`} />
        <meta property="og:image" content={imgSrc} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className={isMobile ? "card-container_prf" : "card-container"}>
        <Card className={isMobile ? "custom-card" : "custom-card-profile"}>
          <div className="image-container" style={{ position: "relative" }}>
            {isMobile ? (
              <Card.Img
                variant="top"
                src={imgSrc}
                alt={`Imagen de ${AnName}`}
              />
            ) : (
              <>
                <Card.Img
                  variant="top"
                  src={imgSrc}
                  alt={`Imagen de ${AnName}`}
                />
                <div className="small-image-container">
                  <img
                    src={imgSrc}
                    alt={`Imagen de ${AnName}`}
                    className="small-profile-image"
                  />
                </div>
              </>
            )}

            <div
              className={`favorite-icon ${isFavorited ? "favorited" : ""}`}
              onClick={handleFavoriteClick}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
                color: isFavorited ? "red" : "gray",
              }}
            >
              <FontAwesomeIcon icon={faHeart} />
            </div>

            <div
              className="share-icon"
              onClick={handleShareClick}
              style={{
                position: "absolute",
                bottom: "10px",
                right: "10px",
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon icon={faShareAlt} />
            </div>

            <div
              className="stars"
              style={{
                position: "absolute",
                bottom: "10px",
                left: "10px",
              }}
            >
              {starRating > 0
                ? Array.from({ length: 5 }, (_, index) =>
                    index < Math.floor(starRating) ? (
                      <span key={index} className="star">
                        ★
                      </span>
                    ) : (
                      <span key={index} className="empty-star">
                        ☆
                      </span>
                    )
                  )
                : Array.from({ length: 5 }, (_, index) => (
                    <span key={index} className="empty-star">
                      ☆
                    </span>
                  ))}{" "}
              {/* Muestra 5 estrellas vacías si starRating es 0 */}
              {starRating > 0 && (
                <span className="bold-rating">
                  {Number(starRating).toFixed(1)}
                </span> // Asegúrate de que sea un número
              )}
            </div>
          </div>
          <Card.Body>
            <div className="card-header">
              <img
                className="status-img"
                src={getStatusImage(AnEstadoWeb, tipo)}
                alt="status"
              />
              <Card.Title className="card-titleh5prf">{`${AnName.trim()}, ${age}`}</Card.Title>
            </div>

            {/* Nueva tabla para organizar el contenido */}
            <table className="card-info-table">
              <tbody>
                <tr>
                  <td>
                    <i
                      className="fas fa-map-marker-alt"
                      style={{ marginRight: "5px", fontSize: "medium" }}
                    ></i>
                  </td>
                  <td>
                    <span
                      className="card-text-prf"
                      onClick={() => {
                        const query = encodeURIComponent(
                          `${Localidad}, ${Barrio}`
                        );
                        const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${query}`;
                        window.open(mapsUrl, "_blank");
                      }}
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "grey",
                        fontSize: "medium",
                      }}
                    >
                      {`${Localidad} - ${Barrio}`}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <FontAwesomeIcon
                      icon={faClock}
                      style={{ marginRight: "5px", fontSize: "medium" }}
                    />
                  </td>
                  <td>Horario: {horario}</td>
                </tr>
                <tr>
                  <td>
                    <FontAwesomeIcon
                      icon={faDollarSign}
                      style={{
                        marginRight: "9px",
                        marginLeft: "2px",
                        fontSize: "medium",
                      }}
                    />
                  </td>
                  <td>Tarifa: {tarifa !== 0 ? `$U ${tarifa} p/hora.` : "-"}</td>
                </tr>
              </tbody>
            </table>

            <div className="button-group">
              <Button
                variant="outline-success"
                className={`btn-modern whatsapp-btn ${
                  !(contWP === true || contWP === 1) ? "disabled-red" : ""
                }`}
                onClick={handleWhatsAppClick}
                disabled={!(contWP === true || contWP === 1)}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={
                    !(contWP === true || contWP === 1) ? renderTooltip : <></>
                  }
                >
                  <span>
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </span>
                </OverlayTrigger>
              </Button>

              {isMobile ? (
                <Button
                  variant="outline-primary"
                  className="btn-modern phone-btn"
                  onClick={handlePhoneClick}
                >
                  <FontAwesomeIcon icon={faPhone} />
                </Button>
              ) : (
                <Button
                  variant="outline-primary"
                  className="btn-modern phone-btn"
                >
                  {`${anPhone.trim()}`}
                </Button>
              )}

              <Button
                variant="outline-secondary"
                className="btn-modern more-btn"
                onClick={() => setMenuOpen(!menuOpen)}
              >
                <FontAwesomeIcon icon={faEllipsisH} />
              </Button>
              {menuOpen && renderMenu()}
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default CardType1;
