import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faBell,
  faTrash,
  faPowerOff,
  faCamera,
  faHeart,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import "./mypanelv.css"; // Estilos ajustados para la pantalla MyPanel
import MasterPage from "./MasterPage";
import useAuth from "../hooks/useAuth";
import { Card, Col, Row } from "react-bootstrap";
import defaultUserImg from "../images/user.png";

const host = process.env.REACT_APP_API_HOST;
/* const port = process.env.REACT_APP_API_PORT; */

const MyPanelV = () => {
  const { modifiedUser } = useParams();
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const {isAuthenticated, tipo} = useAuth(modifiedUser);
  const [showPopup, setShowPopup] = useState(false);

  if (tipo ==="A")
  {
    navigate("/panelAdmin/" + modifiedUser);
  }
  const handleShowPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

/*   const handleMediaClick = () => {
    navigate("/media/" + modifiedUser);
  }; */

  const handleFavoritesClick = () => {
    navigate("/favorites/"+ modifiedUser);
  };

  const handleModifyClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (event) => {
      const file = event.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("imagen", file); // Corregido
     /*    console.log(
          `Cargando perfil desde: ${host}/api/profile/${modifiedUser}/image`,
          formData
        ); */
        /* console.log(`Valor de modifiedUser: ${modifiedUser}`); */
        axios.put(`${host}/api/profile/${modifiedUser}/image`, formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            alert("Imagen actualizada exitosamente.");
            setUserData((prevData) => ({
              ...prevData,
              imagenUrl: response.data.user.ImgPerfil, // Asegúrate de usar el campo correcto del backend
            }));
          })
          .catch((error) => {
            console.error("Error al subir la imagen:", error);
            alert(
              "No se pudo actualizar la imagen. Por favor, inténtelo de nuevo."
            );
          });
      }
    };
    input.click();
  };

  const handleLogout = () => {
    localStorage.clear(); // Limpia todo el localStorage
    navigate("/login"); // Redirige a la página de inicio de sesión
  };

  useEffect(() => {
    if (modifiedUser) {
   /*    console.log(
        `Cargando perfil desde: ${host}/api/profile/${modifiedUser}`
      ); */
      axios
        .get(`${host}/api/profile/${modifiedUser}`)
        .then((response) => {
          setUserData(response.data);
          /* console.log("user data: ", response.data); */
        })
        .catch((error) =>
          console.error("Error al cargar los datos del usuario:", error)
        );
    }
  }, [modifiedUser]);

  if (!isAuthenticated) {
    return <p>Validando acceso...</p>;
  }

  return (
    <MasterPage>
      <div id="mypanel-root">
        <div>
          <h1 style={{ fontSize: "xx-large", textAlign: "center" }}>Panel</h1>

          {/* Componente Card con los datos del usuario */}
          <Row className="justify-content-center">
            <Col md={6}>
              <Card
                className="text-center"
                style={{
                  width: "18rem",
                  margin: "20px auto",
                  padding: "20px",
                  backgroundColor: "#2C3E50",
                  borderRadius: "15px",
                }}
              >
                {/* Contenedor para la imagen de perfil, círculo y lápiz */}
                <div
                  style={{
                    position: "relative", // Necesario para posicionar el círculo y el lápiz
                    width: "150px",
                    height: "150px",
                    margin: "0 auto 20px",
                  }}
                >
                  {/* Imagen de perfil dentro de un contenedor redondo */}
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                      overflow: "hidden", // Mantiene el contorno redondo visible
                      border: "5px solid #ECF0F1", // Línea blanca del borde
                    }}
                  >
                    <img
                      src={userData?.imagenUrl || defaultUserImg}
                      alt="Imagen de perfil"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>

                  {/* Círculo de estado */}
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "50%",
                      backgroundColor: userData?.activo ? "green" : "gray",
                      border: "2px solid white",
                      position: "absolute",
                      bottom: "14px",
                      right: "32px",
                      transform: "translate(50%, 50%)",
                      zIndex: 1,
                    }}
                  ></div>

                  {/* Ícono de lápiz para editar */}
                  <div
                    style={{
                      position: "absolute",
                      top: "13px",
                      right: "32px",
                      transform: "translate(50%, -50%)",
                      backgroundColor: "whitesmoke",
                      borderRadius: "50%",
                      width: "30px",
                      height: "30px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "2px solid #ECF0F1", // Coincide con el borde blanco
                      cursor: "pointer", // Apunta que es clickeable
                      zIndex: 2,
                    }}
                    onClick={handleModifyClick} // Acción al hacer clic
                  >
                    <FontAwesomeIcon
                      icon={faCamera}
                      style={{ color: "#2C3E50" }}
                    />
                  </div>
                </div>

                {/* Información del usuario */}
                <Card.Body>
                  <Card.Title
                    style={{ fontSize: "xx-large", color: "whitesmoke" }}
                  >
                    {userData ? userData.usuario : "Cargando..."}
                  </Card.Title>
                  <Card.Text style={{ fontSize: "large", color: "whitesmoke" }}>
                    <strong>Email:</strong>{" "}
                    {userData ? userData.email : "Cargando..."}
                  </Card.Text>
                  <Card.Text style={{ fontSize: "small", color: "whitesmoke" }}>
                    <strong>Since:</strong>{" "}
                    {userData
                      ? new Date(userData.fechaDeCreacion).toLocaleDateString(
                          "es-ES",
                          {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }
                        )
                      : "Cargando..."}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <div className="mypanel-form-page">
            <div className="mypanel-boby-page">
              <div className="mypanel-button-grid">
                {/*<button
                  className="mypanel-grid-button"
                  onClick={handleModifyClick}
                >
                  <FontAwesomeIcon icon={faUser} /> Perfil
                </button>*/}
                <button
                  className="mypanel-grid-button"
                  onClick={handleFavoritesClick}
                >
                  <FontAwesomeIcon icon={faHeart} /> Favoritos
                </button>
                <button className="mypanel-grid-button">
                  <FontAwesomeIcon icon={faBell} /> Notificaciones
                </button>
                <button
                  className="mypanel-grid-button"
                  onClick={handleShowPopup}
                >
                  <FontAwesomeIcon icon={faUserGroup} /> Grupos
                </button>
                {showPopup && (
                  <div className="popup-overlay">
                    <div className="popup-content">
                      <h4
                        style={{
                          padding: "10px",
                          color: "white",
                          fontSize: "large",
                        }}
                      >
                        Proximamente! 🙌
                      </h4>
                      <button onClick={handleClosePopup}>Cerrar</button>
                    </div>
                  </div>
                )}

                <button className="mypanel-grid-button">
                  <FontAwesomeIcon icon={faTrash} /> Eliminar Cuenta
                </button>
                <button className="mypanel-grid-button" onClick={handleLogout}>
                  <FontAwesomeIcon icon={faPowerOff} /> Salir
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MasterPage>
  );
};

export default MyPanelV;
