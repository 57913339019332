import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import MasterPage from "./MasterPage";

const host = process.env.REACT_APP_API_HOST;

const ResetPassword = () => {
  const { token } = useParams(); // Obtiene el token desde la URL
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleResetPassword = async () => {
    if (!newPassword || !confirmPassword) {
      alert("Por favor, completa todos los campos");
      return;
    }

    if (newPassword !== confirmPassword) {
      alert("Las contraseñas no coinciden");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(`${host}/api/reset-password`, {
        token,
        newPassword,
      });
      alert(response.data.message);
      navigate("/login"); // Redirige al login tras el cambio exitoso
    } catch (error) {
      console.error("Error:", error);
      alert(
        error.response?.data?.message || "Error al restablecer la contraseña"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <MasterPage>
    <div style={styles.container}>
    <div className="reset-password-container">
      <h2>Restablecer Contraseña</h2>
      <input
        type="password"
        placeholder="Nueva contraseña"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        style={styles.input}
      />
      <input
        type="password"
        placeholder="Confirmar nueva contraseña"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        style={styles.input}
      />
      <button onClick={handleResetPassword} style={styles.button} disabled={loading}>
        {loading ? "Procesando..." : "Restablecer Contraseña"}
      </button>
    </div>
    </div>
    </MasterPage>
  );
};

const styles = {
    container: {
      maxWidth: "400px",
      margin: "50px auto",
      padding: "20px",
      textAlign: "center",
      border: "1px solid #ccc",
      borderRadius: "8px",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    },
    input: {
      width: "100%",
      padding: "10px",
      marginBottom: "10px",
      border: "1px solid #ccc",
      borderRadius: "5px",
    },
    button: {
      width: "100%",
      padding: "10px",
      backgroundColor: "#007bff",
      color: "white",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
  };

export default ResetPassword;
