import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import MasterPage from "../components/MasterPage";
import "../components/TermsConditions.css";

const TermsConditions = () => {
  return (
    <MasterPage>
      <Container className="terms-conditions-container my-5 p-4 shadow">
        <Row>
          <Col>
            <h1 className="text-center mb-4">Términos y Condiciones de Khalii.com</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>
                  <strong>Cláusula de Términos de Uso</strong>
                </Card.Title>
                <Card.Text>
                  <strong>Disposiciones Generales</strong><br />
                  www.khalii.com, en adelante "Khalii", "sitio", o "portal", aloja anuncios clasificados que promocionan servicios proporcionados por terceros. En ningún caso, Khalii interviene directa o indirectamente en las relaciones entre los usuarios del sitio, las cuales son completamente ajenas al sitio y, por lo tanto, exentas de responsabilidad. El uso del sitio, incluyendo todos los accesos, servicios o aplicaciones que pueda contener, está regido por los Términos de Uso y la Política de Privacidad, conjuntamente denominados "Términos", los cuales deben ser considerados detenidamente. El uso del sitio implica la aceptación de estos términos y condiciones. El sitio se reserva el derecho de modificar estos Términos y su política de Privacidad en cualquier momento y por cualquier motivo. La versión actualizada estará disponible en el sitio, por lo que se recomienda consultarla periódicamente.
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="mb-4">
              <Card.Body>
                <Card.Title>
                  <strong>Usuarios</strong>
                </Card.Title>
                <Card.Text>
                  Todo usuario, ya sea registrado o no, debe ser mayor de edad según la legislación local de su país de acceso o residencia (en Uruguay, la mayoría de edad es a los 18 años). De lo contrario, se le prohíbe el uso y permanencia en el sitio. El sitio se reserva el derecho de dar de baja cuentas de usuarios que no cumplan con este requisito o que se sospeche que no lo hacen, sin necesidad de previo aviso.
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="mb-4">
              <Card.Body>
                <Card.Title>
                  <strong>Conducta de los Usuarios</strong>
                </Card.Title>
                <Card.Text>
                  Todo usuario se compromete a evitar conductas como: <br />
                  A. Acoso, amenazas, intimidaciones o cualquier otra acción que cause malestar o angustia a individuos u organizaciones...<br />
                  B. Compartir información ilegal, falsa, engañosa...<br />
                  (continúa con los demás puntos de la cláusula, ajustándolos al formato de Card).
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="mb-4">
              <Card.Body>
                <Card.Title>
                  <strong>Publicaciones</strong>
                </Card.Title>
                <Card.Text>
                  A. El usuario que publica anuncios debe cumplir con las regulaciones y estar registrado como trabajador, incluyendo cuestiones de salud y fiscales...<br />
                  B. El vínculo con la empresa no implica una relación de empleo...<br />
                  (continúa con los demás puntos de esta sección).
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="mb-4">
              <Card.Body>
                <Card.Title>
                  <strong>Reembolsos</strong>
                </Card.Title>
                <Card.Text>
                  El sitio no proporcionará reembolsos en caso de eliminación de contenido o cancelación de servicios.
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="mb-4">
              <Card.Body>
                <Card.Title>
                  <strong>Responsabilidad</strong>
                </Card.Title>
                <Card.Text>
                  A. Los materiales del sitio están protegidos por derechos de autor, marcas y patentes...<br />
                  B. El sitio no se hace responsable de fallas en su funcionamiento, interrupciones, retrasos...<br />
                  (continúa con los demás puntos de esta sección).
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="mb-4">
              <Card.Body>
                <Card.Title>
                  <strong>Notificación de Infracciones</strong>
                </Card.Title>
                <Card.Text>
                  A. En caso de infracción de derechos de autor, se debe proporcionar información detallada al agente de derechos de autor del sitio...<br />
                  B. Para otras violaciones, se debe proporcionar información sobre la infracción al agente del sitio...
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="mb-4">
              <Card.Body>
                <Card.Title>
                  <strong>Legislación y Jurisdicción</strong>
                </Card.Title>
                <Card.Text>
                  La legislación aplicable será la de la República Oriental del Uruguay, y la jurisdicción competente será la de la Ciudad de Montevideo.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </MasterPage>
  );
};

export default TermsConditions;
