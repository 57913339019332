import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CardType2.css"; // Importar CSS específico para el popup

// Función para calcular el tiempo transcurrido
const timeAgo = (date) => {
  const now = new Date();
  const messageDate = new Date(date);
  const diffInSeconds = Math.floor((now - messageDate) / 1000);

  if (diffInSeconds < 60) return `${diffInSeconds} seg.`;
  if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} min.`;
  if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)} hrs.`;
  return `${Math.floor(diffInSeconds / 86400)} días`;
};

// Componente personalizado para las notificaciones de Toastify
const CustomToast = ({ imgUrl, AnName, AnMsgEst, AnMsgDateTime, anPhone }) => {
  const message = `Hola ${AnName.trim()}, vi tu perfil en Khalii`;
  let cleanedPhone = anPhone ? anPhone.replace(/\D/g, "") : "";
  cleanedPhone = cleanedPhone.slice(1);
  const url = `https://wa.me/+598${cleanedPhone}?text=${encodeURIComponent(message)}`;

  const handleClick = () => {
    window.location.href = url;// Abre WhatsApp en una nueva pestaña al hacer clic
  };

  return (
    <div
      style={{ display: "flex", alignItems: "center", cursor: "pointer" }} // Agrega cursor pointer para indicar que es clicable
      onClick={handleClick} // Asigna el evento onClick
    >
      <img
        src={imgUrl}
        alt="Perfil"
        style={{
          width: "70px", // Ancho
          height: "auto", // Alto
          maxWidth: "70px",
          maxHeight: "90px",
          objectFit: "cover",
          marginRight: "6px",
        }}
      />
      <div style={{ marginLeft: "5px" }}>
        <strong>{AnName}</strong>
        <span className="time-since"> hace {timeAgo(AnMsgDateTime)}:</span>
        <em style={{ fontSize: "14px" }}>{AnMsgEst}</em>
      </div>
    </div>
  );
};

const CardType2 = ({ AnMsgEst, AnMsgDateTime, imgUrl, AnName, anPhone }) => {
  const now = new Date();
  const messageDate = new Date(AnMsgDateTime);
  const diffInHours = Math.floor((now - messageDate) / (1000 * 60 * 60));
  const [isMobile, setIsMobile] = useState(false);

  // Verificar si es dispositivo móvil
  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 768); // Considerar móvil si el ancho es menor a 768px
    };

    // Escuchar el cambio de tamaño de ventana
    window.addEventListener("resize", checkScreenSize);
    checkScreenSize(); // Llama a la función en el montaje

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  // Mostrar la notificación con Toastify al montar el componente
  const [toastDisplayed, setToastDisplayed] = useState(false);

  useEffect(() => {
   
    if (!toastDisplayed) {
      toast(
        <CustomToast
          AnMsgDateTime={AnMsgDateTime}
          imgUrl={imgUrl}
          AnName={AnName}
          AnMsgEst={AnMsgEst}
          anPhone={anPhone} // Pasar el teléfono
        />,
        {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
      setToastDisplayed(true);
    }
  }, [toastDisplayed, AnMsgDateTime, imgUrl, AnName, AnMsgEst, anPhone]);
  
  // Si el tiempo es mayor a 3 horas, retornar un espacio transparente
  if (diffInHours > 3) {
    return <div className={`transparent-placeholder ${isMobile ? 'mobile' : ''}`}></div>;
  }

  return (
    <div className="content" style={{ marginLeft: "-10px", marginTop: "30px" }}>
      <div className="floating-notification">
        <Card className="notification-card">
          <Card.Body>
            <div className="notification-content">
              <div className="profile-info">
                <img src={imgUrl} alt="Perfil" className="profile-image" />
              </div>
              <Card.Text>
                <span className="profile-name">{AnName}</span>
                <span className="time-since">hace {timeAgo(AnMsgDateTime)}</span>
                <span className="message-status">{AnMsgEst}</span>
              </Card.Text>
            </div>
          </Card.Body>
        </Card>
      </div>
      {/* Contenedor de notificaciones Toastify */}
      <ToastContainer />
    </div>
  );
};

export default CardType2;
