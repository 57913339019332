import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../components/LoginPage.css"; // Asegúrate de que la ruta sea correcta
const host = process.env.REACT_APP_API_HOST;

const LoginPage = () => {
  const [usuario, setUsuario] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [userType, setUserType] = useState("escort"); // Estado para controlar el tipo de usuario
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${host}/api/login`, {
        usuario,
        password,
      });
      /* console.log("Response:", response.data); */

      const { token } = response.data;

      if (token) {
        localStorage.setItem("token", token); // Guardar el token en localStorage

        let modifiedUser = usuario;
        if (userType === "escort") {
          modifiedUser = usuario.slice(1); // Elimina el primer carácter si es 'escort'
          localStorage.setItem("AndId", modifiedUser);
        } else if (userType === "usuario"){
          localStorage.setItem("Usuario", modifiedUser);
        }
        

        if (userType === "escort") {
          navigate("/panel/" + modifiedUser);
        } else if (userType === "usuario") {
          navigate("/panelv/" + usuario);
        } else {
          navigate("/main");
        }
      }
    } catch (error) {
      console.error("Error al iniciar sesión:", error);
      alert("Error al iniciar sesión");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleForgotPassword = () => {
    // Lógica para manejar la acción de "Olvidó su contraseña"
    navigate("/forgot-password");
  };

  const navigateToLink = (url) => {
    window.location.href = url;
  };

  // Función para validar que sólo se ingresen números si es un Escort
  const handleUsuarioChange = (e) => {
    const value = e.target.value;
    // Si el tipo de usuario es 'escort', solo permitir números
    if (userType === "escort") {
      const onlyNumbers = value.replace(/\D/g, ""); // Remueve cualquier cosa que no sea número
      setUsuario(onlyNumbers);
    } else {
      setUsuario(value);
    }
  };

  return (
    <div className="background-section">
      <div
        className="neon"
        id="neonDiv"
        onClick={() => navigateToLink("https://www.khalii.com")}
      >
        <h1>
          Khali<span className="first">i</span>.com
        </h1>
      </div>
      <div className="wrapper">
        <form onSubmit={handleSubmit}>
          <h2>Iniciar Sesión</h2>

          {/* Radio button para seleccionar tipo de usuario */}
          <div className="user-type">
            <label>
              <input
                type="radio"
                name="userType"
                value="escort"
                checked={userType === "escort"}
                onChange={(e) => setUserType(e.target.value)}
              />
              Escort
            </label>
            <label>
              <input
                type="radio"
                name="userType"
                value="usuario"
                checked={userType === "usuario"}
                onChange={(e) => setUserType(e.target.value)}
              />
              Usuario
            </label>
          </div>

          <div className="input-field">
            <input
              type={userType === "escort" ? "tel" : "text"} // Cambiar tipo de input según el tipo de usuario
              id="UsuarioInput"
              value={usuario}
              onChange={handleUsuarioChange}
              required
              pattern={userType === "escort" ? "[0-9]*" : undefined} // Patrón de solo números para Escort
              maxLength={userType === "escort" ? 15 : undefined} // Limitar la cantidad de caracteres para Escort
            />
            <label htmlFor="UsuarioInput">
              {userType === "escort" ? "Nro celular" : "Usuario"}
            </label>
          </div>

          <div className="input-field">
            <input
              type={showPassword ? "text" : "password"}
              id="PasswordInput"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button
              type="button"
              className="toggle-password"
              onClick={togglePasswordVisibility}
            >
              <i
                id="eyeIcon"
                className={`fa-solid ${
                  showPassword ? "fa-eye-slash" : "fa-eye"
                }`}
                style={{ color: "#ffffff" }}
              ></i>
            </button>
            <label htmlFor="PasswordInput">Contraseña</label>
          </div>

          <div className="forget">
            <label htmlFor="remember">
              <input type="checkbox" id="remember" />
              <p>Recordarme</p>
            </label>
            <span
              role="button"
              onClick={handleForgotPassword}
              className="link-button"
            >
              ¿Olvidó su contraseña?
            </span>
          </div>
          <button type="submit">Iniciar sesión</button>
          <div className="register">
            <p>
              ¿No tienes cuenta?{" "}
              <span
                role="button"
                onClick={() => navigate("/register")}
                className="link-button"
              >
                <strong>Registrate</strong>
              </span>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
