import React, { useState } from "react";
import axios from "axios";
import MasterPage from "./MasterPage";

const host = process.env.REACT_APP_API_HOST;

const ForgotPassword = () => {
  const [userEmail, setUserEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleForgotPassword = async () => {
    if (!userEmail.trim()) {
      alert("Por favor, ingresa tu correo electrónico");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(`${host}/api/forgot-password`, {
        email: userEmail,
      });

      alert(response.data.message);
    } catch (error) {
      console.error("Error:", error);
      alert("Error al solicitar recuperación de contraseña, comunicate con soporte@khalii.com.");
    }

    setLoading(false);
  };

  return (
    <MasterPage>
    <div style={styles.container}>
      <h2>Recuperar Contraseña</h2>
      <p>Ingresa tu correo electrónico y te enviaremos instrucciones para recuperar tu contraseña.</p>

      <input
        type="email"
        placeholder="Correo electrónico"
        value={userEmail}
        onChange={(e) => setUserEmail(e.target.value)}
        style={styles.input}
        disabled={loading}
      />

      <button onClick={handleForgotPassword} style={styles.button} disabled={loading}>
        {loading ? "Enviando..." : "Enviar"}
      </button>
    </div>
    </MasterPage>
  );
};

const styles = {
  container: {
    maxWidth: "400px",
    margin: "50px auto",
    padding: "20px",
    textAlign: "center",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  },
  input: {
    width: "100%",
    padding: "10px",
    marginBottom: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
  button: {
    width: "100%",
    padding: "10px",
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default ForgotPassword;
