import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MasterPage from "../components/MasterPage";
import CardExample from "../components/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card"; // Para la card de la empresa
import Modal from "react-bootstrap/Modal"; // Importar el Modal
import "../components/Empresa.css";

import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faPhone } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

const HousePage = () => {
  const { EmpId } = useParams(); // Obtiene el ID del perfil desde la URL
  const [profiles, setProfiles] = useState([]);
  const [empresa, setEmpresa] = useState(null); // Estado para la empresa
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Estado para dispositivo móvil

  // Estado para manejar el modal
  const [showModal, setShowModal] = useState(false);

  const handleInfoClick = () => {
    setShowModal(true); // Abre el modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Cierra el modal
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const host = process.env.REACT_APP_API_HOST;
        const id = parseInt(EmpId, 10);
        // Obtener perfiles
        const profilesResponse = await axios.get(
          `${host}/api/perfil/emp/${id}`
        );
        setProfiles(profilesResponse.data);

        // Obtener datos de la empresa
        const empresaResponse = await axios.get(`${host}/api/empresas/${id}`); // Ajusta el endpoint según corresponda
        setEmpresa(empresaResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [EmpId]);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Actualiza si es móvil o no
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleCardClick = (AnId) => {
    navigate(`/main/chicas/${AnId}`);
  };

  const handleWhatsAppClick = (telefono) => {
    let cleanedPhone = telefono.trim();

    if (!cleanedPhone) {
      console.error("El número de teléfono no está definido o está vacío");
      return;
    }
    // Quitar el primer número si es un 0
    if (cleanedPhone.startsWith("0")) {
      cleanedPhone = cleanedPhone.slice(1);
    }
    const message = `Hola, vi tu perfil en Khalii, me gustaría agendarme.`;
    const url = `https://wa.me/+598${cleanedPhone}?text=${encodeURIComponent(
      message
    )}`;
    window.location.href = url;
  };

  const handlePhoneClick = (telefono) => {
    if (telefono) {
      window.open(`tel:${telefono}`);
    }
  };

  const handleLocationClick = (direccion) => {
    if (direccion) {
      // Ajusta la URL según la API de mapas que uses
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${direccion}`,
        "_blank"
      );
    }
  };

  const handleWebClick = (telefono) => {
    // Aquí puedes definir la lógica según tus necesidades.
    const webUrl = empresa.url;
    window.open(webUrl, "_blank"); // Abre la URL en una nueva pestaña.
  };

  return (
    <MasterPage>
      <div className="main-page-container">
        <h1>Casas & Aptos</h1>

        {/* Card de la Empresa */}
        {empresa && (
          <Card className="empresa-card mb-4">
            <Card.Img
              variant="top"
              src={empresa.urlImagen || "/Logo_blue_angels.png"}
              alt="Empresa"
              className="empresa-card-image"
            />
            <Card.Body>
              <Card.Title className="empresa-card-title">
                {empresa.nombre}
              </Card.Title>
              <div className="empbutton-group d-flex justify-content-between mt-3">
                {/* Botón de WhatsApp */}
                <Button
                  variant="outline-success"
                  className="btn-modern whatsapp-btn"
                  onClick={() => handleWhatsAppClick(empresa.telefono)}
                >
                  <FontAwesomeIcon icon={faWhatsapp} />{" "}
                  {!isMobile && " WhatsApp"}
                </Button>

                {/* Botón de Teléfono */}
                <Button
                  variant="outline-primary"
                  className="btn-modern phone-btn"
                  onClick={() => handlePhoneClick(empresa.telefono)}
                >
                  <FontAwesomeIcon icon={faPhone} /> {!isMobile && " Teléfono"}
                </Button>

                {/* Botón de Ubicación */}
                <Button
                  variant="outline-warning"
                  className="btn-modern location-btn"
                  onClick={() => handleLocationClick(empresa.direccion)}
                >
                  <FontAwesomeIcon icon={faMapMarkedAlt} />{" "}
                  {!isMobile && " Ubicación"}
                </Button>

                {/* Botón de Sitio Web */}
                <Button
                  variant="outline-primary"
                  className="btn-modern web-btn"
                  onClick={() => handleWebClick(empresa.telefono)}
                >
                  <FontAwesomeIcon icon={faGlobe} /> {!isMobile && " Web"}
                </Button>

                {/* Botón de Video */}
                <Button
                  variant="outline-primary"
                  className="btn-modern video-btn"
                  onClick={handleInfoClick}
                >
                  <FontAwesomeIcon icon={faInfo} /> {!isMobile && " Info"}
                </Button>
              </div>
            </Card.Body>
          </Card>
        )}
        {/* Modal para mostrar la información */}
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <button className="close-button" onClick={handleCloseModal}>
              ×
            </button>
            <Modal.Title>Info:</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  empresa?.informacion
                    .replace("Horarios:","<strong><br />Horarios:</strong>"
                    )
                    .replace("Lunes - Jueves", "<br />Lunes - Jueves") 
                    .replace("Viernes - Sábado", "<br />Viernes - Sábado")
                    .replace("Domingo ", "<br />Domingo ")||
                  "Información no disponible.",
              }}
            ></p>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        {/* Cards de Perfiles */}
        <Row className="g-4-row">
          {profiles.length > 0 ? (
            profiles
              .filter(
                (profile) => profile.AnEstado === 5 || profile.AnEstado === 1
              )
              .map((profile, index) => {
                let columnClass = "profile-col col-lg-4 col-md-6 col-12";
                if (profile.AnMsgEst && profile.AnEstadoWeb !== 4) {
                  columnClass =
                    profile.AnMsgEst.trim().length > 125
                      ? "profile-col long-message col-lg-4 col-md-6 col-12"
                      : "profile-col message col-lg-4 col-md-6 col-12";
                }

                return (
                  <Col key={index} className={columnClass}>
                    <CardExample
                      name={profile.AnName}
                      age={profile.AnAge}
                      imgSrc={profile.imgUrl}
                      starRating={profile.calificacionTotal}
                      AnEstadoWeb={profile.AnEstadoWeb}
                      localidad={
                        profile.Barrio?.Localidad?.LocalidadName ||
                        "Localidad no disponible"
                      }
                      barrio={
                        profile.Barrio?.BarrioName || "Barrio no disponible"
                      }
                      anMsgEst={profile.AnMsgEst}
                      AnMsgDateTime={profile.AnMsgDateTime}
                      horario={profile.AnHorario}
                      tipo={profile.AnTipo}
                      onClick={() => handleCardClick(profile.AnId)}
                    />
                  </Col>
                );
              })
          ) : (
            <p>No profiles available</p>
          )}
        </Row>
      </div>
    </MasterPage>
  );
};

export default HousePage;
