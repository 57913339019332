/* import React from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";


const AdultContentCard = () => {
  const navigate = useNavigate();

  const handleEnter = () => {
    // Registrar que el usuario ha aceptado el contenido
    localStorage.setItem("adultContentAccepted", "true");
    navigate("/main");
  };

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <Card>
      <h1>Contenido solo para mayores de 18 años</h1>

      <img
        src="Logo_Khalii_Header_New.png"
        alt="Advertencia"
        style={{ margin: "20px auto", display: "block" }}
      />
      <p>Al navegar en este sitio web estás aceptando nuestras Politicas de uso, privacidad, y cookies. Bajo ninguna circunstancia el sitio interviene directa o indirectamente en la relacion entre los usuarios del sitio, las que resultan completamente ajenas al sitio y por lo tanto se encuentran exentas de responsabilidad.</p>
      <button onClick={handleEnter} style={{ padding: "10px 20px", fontSize: "16px" }}>
        Entrar
      </button>
      </Card>
    </div>
    
  );
};

export default AdultContentCard; */


import React from "react";
import { Card, Button } from "react-bootstrap";
import "../components/AgeConfirmation.css";

const AdultContentCard = () => {
  const handleAccept = () => {
    localStorage.setItem("adultContentAccepted", "true");
    window.location.reload();
  };

  return (
    <div className="adult-card-container">
      <div className="adult-card">
        <h1>Contenido solo para mayores de 18 años</h1>
        <img
        src="Logo_Khalii_Header_New.png"
        alt="Advertencia"
        style={{display: "block", width: "80%" , alignContent: "center", marginLeft: "20px"}}
      />
        <Card style={{ width: "100%" }} className="p-4 text-center">
          <Card.Body>
            <Card.Title>Advertencia de contenido adulto</Card.Title>
            <Card.Text>
            Al navegar en este sitio web estás aceptando nuestras Politicas de uso, privacidad, y cookies.
             Bajo ninguna circunstancia el sitio interviene directa o indirectamente en la relacion entre los usuarios del sitio, 
            las que resultan completamente ajenas al sitio y por lo tanto se encuentran exentas de responsabilidad.
              Este sitio contiene contenido para mayores de 18 años. Al hacer clic en
              "Entrar", confirmas que eres mayor de edad.

            </Card.Text>
            <Button className="custom-button" onClick={handleAccept}>
              Entrar
            </Button>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default AdultContentCard;
