import React, { useState, useEffect } from "react";
import axios from "axios";
import MasterPage from "../components/MasterPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRulerVertical,
  faPalette,
  faUser,
  faVenusMars,
} from "@fortawesome/free-solid-svg-icons";
import { Range } from "react-range";
import "../components/ProfileFilter.css";
import "../components/Style.css";
import CardExample from "../components/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";

const host = process.env.REACT_APP_API_HOST;

const ProfileFilter = () => {
  const [selectedFilters, setSelectedFilters] = useState({
    name: "",
    height: null,
    hair: null,
    type: null,
    LocalidadId: "",
    BarrioId: "",
    ageRange: [18, 60],
    priceRange: [0, 10000],
    language: "",
    orientation: null,
    boobs: null,
    peso: null,
    generation: null,
    etnia: null,
    Ath: false,
    Atm: false,
    Atpd: false,
    Ato: false,
    Att2c: false,
    Att2e: false,
    Atp: false,
    Ats: false,
    Escort: false,
    Dama: false,
    Econo: false,
    condon: false,
    acabarCuerpo: false,
    acabarCara: false,
    gargantaProfunda: false,
    oralCompleto: false,
    natural: false,
    lesbicoReal: false,
    aptoCompartido: false,
    pagoTarjeta: false,
    jacuzzi: false,
    conDucha: false,
    climatizado: false,
    centroMasajes: false,
    casa: false,
    camaMatrimonio: false,
    bebidasAlcoholicas: false,
    aptoPrivado: false,
    parkingCerca: false,
    copro: false,
    besosBoca: false,
    besoNegro: false,
    lenceria: false,
    juguetesEroticos: false,
    footjob: false,
    fantasias: false,
    doblePenetracion: false,
    strapOn: false,
  });

  // Función para limpiar los filtros
  const clearFilters = () => {
    setSelectedFilters({
      name: "",
      height: "",
      hair: "",
      type: "",
      LocalidadId: "",
      BarrioId: "",
      ageRange: [18, 60],
      priceRange: [0, 10000],
      language: "",
      orientation: null,
      boobs: null,
      peso: null,
      generation: null,
      etnia: null,
      Ath: false,
      Atm: false,
      Atpd: false,
      Ato: false,
      Att2c: false,
      Att2e: false,
      Atp: false,
      Ats: false,
      Escort: false,
      Dama: false,
      Econo: false,
      condon: false,
      acabarCuerpo: false,
      acabarCara: false,
      gargantaProfunda: false,
      oralCompleto: false,
      natural: false,

      aptoCompartido: false,
      pagoTarjeta: false,
      jacuzzi: false,
      conDucha: false,
      climatizado: false,
      centroMasajes: false,
      casa: false,
      camaMatrimonio: false,
      bebidasAlcoholicas: false,
      aptoPrivado: false,
      parkingCerca: false,
      lesbicoReal: false,
      copro: false,
      besosBoca: false,
      besoNegro: false,
      lenceria: false,
      juguetesEroticos: false,
      footjob: false,
      fantasias: false,
      anal: false,
      strapOn: false,
    });
  };

  const navigate = useNavigate();
  const [profiles, setProfiles] = useState([]);
  const [localidades, setLocalidades] = useState([]);
  const [barrios, setBarrios] = useState([]);

  const heightOptions = [
    { value: 1, label: "Alta" },
    { value: 2, label: "Estatura promedio" },
    { value: 3, label: "Bajita" },
  ];

  const hairOptions = [
    { value: 1, label: "Rubia" },
    { value: 2, label: "Morocha" },
    { value: 3, label: "Pelirroja" },
    { value: 4, label: "Castaña" },
    { value: 5, label: "Otro" },
  ];

  const typeOptions = [
    { value: 1, label: "Chica" },
    { value: 2, label: "Trans/Travesti" },
  ];

  const langAttributes = [
    { value: "Inglés", label: "Inglés" },
    { value: "Alemán", label: "Alemán" },
    { value: "Italiano", label: "Italiano" },
    { value: "Francés", label: "Francés" },
    { value: "Portugués", label: "Portugués" },
  ];

  const orientationOptions = [
    { value: 1, label: "Heterosexual" },
    { value: 2, label: "Bisexual" },
    { value: 3, label: "Homosexual" },
  ];

  const boobsOptions = [
    { value: 1, label: "Grandes" },
    { value: 2, label: "Pequeños" },
    { value: 3, label: "Naturales" },
    { value: 4, label: "Operados" },
  ];

  const weightOptions = [
    { value: 1, label: "Delgadas" },
    { value: 2, label: "Peso promedio" },
    { value: 3, label: "Gorditas" },
    { value: 4, label: "Grandes" },
  ];

  const generationOptions = [
    { value: 1, label: "Lolitas" },
    { value: 2, label: "Universitarias" },
    { value: 3, label: "Maduras" },
  ];

  const etniaOptions = [
    { value: 1, label: "Asiaticas" },
    { value: 2, label: "Blancas" },
    { value: 3, label: "Latinas" },
    { value: 4, label: "Morenas" },
  ];

  const AtHOptions = [{ value: true, label: "Hombres" }];
  const AtMOptions = [{ value: true, label: "Mujeres" }];
  const AtPDOptions = [{ value: true, label: "Personas Disc" }];
  const AtOOptions = [{ value: true, label: "Orgías" }];
  const Att2COptions = [{ value: true, label: "Trio 2 clientes" }];
  const AtT2EOptions = [{ value: true, label: "Trio 2 escorts" }];
  const AtPOptions = [{ value: true, label: "Parejas" }];
  const AtSOptions = [{ value: true, label: "Swingers" }];
  const TSEOptions = [{ value: true, label: "Escort de lujo" }];
  const TSDOptions = [{ value: true, label: "Dama de compañia" }];
  const TSEcoOptions = [{ value: true, label: "Económica" }];

  const TSOptionConCondom = [{ value: true, label: "Con condón" }];
  const TSOptionFinishOnBody = [{ value: true, label: "Acabar en cuerpo" }];
  const TSOptionFinishOnFace = [{ value: true, label: "Acabar en cara" }];
  const TSOptionDeepThroat = [{ value: true, label: "Garganta profunda" }];
  const TSOptionFullOral = [{ value: true, label: "Oral completo" }];
  const TSOptionNatural = [{ value: true, label: "Natural" }];

  const TSOptionRealLesbian = [{ value: true, label: "Lésbico real" }];
  const TSOptionCoprophilia = [{ value: true, label: "Copro" }];
  const TSOptionKissing = [{ value: true, label: "Besos en boca" }];
  const TSOptionRimming = [{ value: true, label: "Beso Negro" }];
  const TSOptionLingerie = [{ value: true, label: "Lencería" }];
  const TSOptionEroticToys = [{ value: true, label: "Juguetes Eróticos" }];
  const TSOptionFootjob = [{ value: true, label: "Footjob" }];
  const TSOptionFantasies = [{ value: true, label: "Fantasías" }];
  const TSOptionDoublePenetration = [{ value: true, label: "Anal" }];
  const TSOptionStrapOn = [{ value: true, label: "Strap on" }];

  const TSOptionSharedApt = [{ value: true, label: "Apto compartido" }];
  const TSOptionCardPayment = [{ value: true, label: "Pago con tarjeta" }];
  const TSOptionJacuzzi = [{ value: true, label: "Jacuzzi" }];
  const TSOptionWithShower = [{ value: true, label: "Con ducha" }];
  const TSOptionAirConditioned = [{ value: true, label: "Climatizado" }];
  const TSOptionMassageCenter = [{ value: true, label: "Centro de masajes" }];
  const TSOptionHouse = [{ value: true, label: "Casa" }];
  const TSOptionDoubleBed = [{ value: true, label: "Cama matrimonio" }];
  const TSOptionAlcoholicDrinks = [
    { value: true, label: "Bebidas alcoholicas" },
  ];
  const TSOptionPrivateApt = [{ value: true, label: "Apto privado" }];
  const TSOptionNearbyParking = [{ value: true, label: "Parking cerca" }];

  // Llamada a la API para obtener perfiles
  const fetchProfiles = async () => {
    try {
      const response = await axios.get(`${host}/api/perfil`);
      setProfiles(response.data);
      console.log("perfiles: ", response.data);
    } catch (error) {
      console.error("Error fetching perfiles:", error);
    }
  };

  // Cargar perfiles al cargar el componente
  useEffect(() => {
    fetchProfiles();
  }, []);

  // Cargar localidades al cargar el componente
  useEffect(() => {
    const fetchLocalidades = async () => {
      try {
        const response = await axios.get(`${host}/api/localidades`);
        setLocalidades(response.data);
      } catch (error) {
        console.error("Error al obtener las localidades:", error);
      }
    };

    fetchLocalidades();
  }, []);

  // Obtener barrios cuando cambia la localidad seleccionada
  useEffect(() => {
    const fetchBarrios = async () => {
      if (selectedFilters.LocalidadId) {
        try {
          const response = await axios.get(
            `${host}/api/barrio/localidad/${selectedFilters.LocalidadId}`
          );
          setBarrios(Array.isArray(response.data) ? response.data : []);
        } catch (error) {
          console.error("Error al obtener los barrios:", error);
        }
      } else {
        setBarrios([]);
      }
    };

    fetchBarrios();
  }, [selectedFilters.LocalidadId]);

  const handleFilterChange = (category, value) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [category]: value,
    }));
  };

  const handleLocalidadChange = (e) => {
    const { name, value } = e.target;
    setSelectedFilters((prev) => ({
      ...prev,
      [name]: value,
      BarrioId: "",
    }));
  };

  const handleBarrioChange = (e) => {
    const { name, value } = e.target;
    setSelectedFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAgeRangeChange = (values) => {
    setSelectedFilters((prev) => ({
      ...prev,
      ageRange: values,
    }));
  };

  const handlePriceRangeChange = (values) => {
    setSelectedFilters((prev) => ({
      ...prev,
      priceRange: values,
    }));
  };

  const handleFilterButtonClick = () => {
    fetchProfiles(); // Llamada a la API para obtener perfiles filtrados
  };

  const filteredProfiles = profiles.filter((profile) => {
    // Mapear los idiomas disponibles según los valores booleanos
    const languages = {
      Inglés: profile.AnAtt9,
      Alemán: profile.AnAtt10,
      Italiano: profile.AnAtt11,
      Francés: profile.AnAtt12,
      Portugués: profile.AnAtt13,
    };

    return (
      (selectedFilters.name
        ? profile.AnName.includes(selectedFilters.name)
        : true) &&
      (selectedFilters.height
        ? profile.AnAtt1 === selectedFilters.height
        : true) &&
      (selectedFilters.hair ? profile.AnAtt2 === selectedFilters.hair : true) &&
      (selectedFilters.type ? profile.AnTipo === selectedFilters.type : true) &&
      (selectedFilters.orientation
        ? profile.AnAtt14 === selectedFilters.orientation
        : true) &&
      (selectedFilters.boobs
        ? profile.AnAtt15 === selectedFilters.boobs
        : true) &&
      (selectedFilters.Ath ? profile.AnAtt3 === selectedFilters.Ath : true) &&
      (selectedFilters.Atm ? profile.AnAtt4 === selectedFilters.Atm : true) &&

      (selectedFilters.aptoCompartido ? profile.AnAtt49 === selectedFilters.aptoCompartido: true) &&
      (selectedFilters.aptoPrivado? profile.AnAtt50 === selectedFilters.aptoPrivado: true) &&
      (selectedFilters.camaMatrimonio? profile.AnAtt52 === selectedFilters.camaMatrimonio: true) &&
      (selectedFilters.casa ? profile.AnAtt53 === selectedFilters.casa : true) &&
      (selectedFilters.centroMasajes? profile.AnAtt54 === selectedFilters.centroMasajes: true) &&
      (selectedFilters.climatizado? profile.AnAtt55 === selectedFilters.climatizado: true) &&
      (selectedFilters.conDucha? profile.AnAtt56 === selectedFilters.conDucha
        : true) &&
      (selectedFilters.jacuzzi
        ? profile.AnAtt57 === selectedFilters.jacuzzi
        : true) &&
      (selectedFilters.pagoTarjeta
        ? profile.AnAtt58 === selectedFilters.pagoTarjeta
        : true) &&
      (selectedFilters.parkingCerca
        ? profile.AnAtt59 === selectedFilters.parkingCerca
        : true) &&
      (selectedFilters.bebidasAlcoholicas
        ? profile.AnAtt51 === selectedFilters.bebidasAlcoholicas
        : true) &&
      (selectedFilters.Atpd
        ? profile.AnAtt20 === selectedFilters.Atpd
        : true) &&
      (selectedFilters.Ato ? profile.AnAtt19 === selectedFilters.Ato : true) &&
      (selectedFilters.condon
        ? profile.AnAtt33 === selectedFilters.condon
        : true) &&
      (selectedFilters.acabarCuerpo
        ? profile.AnAtt38 === selectedFilters.acabarCuerpo
        : true) &&
      (selectedFilters.acabarCara
        ? profile.AnAtt37 === selectedFilters.acabarCara
        : true) &&
      (selectedFilters.gargantaProfunda
        ? profile.AnAtt36 === selectedFilters.gargantaProfunda
        : true) &&
      (selectedFilters.oralCompleto
        ? profile.AnAtt35 === selectedFilters.oralCompleto
        : true) &&
      (selectedFilters.natural
        ? profile.AnAtt34 === selectedFilters.natural
        : true) &&
      (selectedFilters.Att2c
        ? profile.AnAtt18 === selectedFilters.Att2c
        : true) &&
      (selectedFilters.Att2e
        ? profile.AnAtt17 === selectedFilters.Att2e
        : true) &&
      (selectedFilters.Escort
        ? profile.AnAtt24 === selectedFilters.Escort
        : true) &&
      (selectedFilters.Dama
        ? profile.AnAtt23 === selectedFilters.Dama
        : true) &&
      (selectedFilters.Econo
        ? profile.AnAtt22 === selectedFilters.Econo
        : true) &&
      (selectedFilters.Atp ? profile.AnAtt5 === selectedFilters.Atp : true) &&
      (selectedFilters.Ats ? profile.AnAtt21 === selectedFilters.Ats : true) &&
      (selectedFilters.lesbicoReal
        ? profile.AnAtt39 === selectedFilters.lesbicoReal
        : true) &&
      (selectedFilters.doblePenetracion
        ? profile.AnAtt40 === selectedFilters.doblePenetracion
        : true) &&
      (selectedFilters.fantasias
        ? profile.AnAtt41 === selectedFilters.fantasias
        : true) &&
      (selectedFilters.footjob
        ? profile.AnAtt42 === selectedFilters.footjob
        : true) &&
      (selectedFilters.juguetesEroticos
        ? profile.AnAtt43 === selectedFilters.juguetesEroticos
        : true) &&
      (selectedFilters.lenceria
        ? profile.AnAtt44 === selectedFilters.lenceria
        : true) &&
      (selectedFilters.besoNegro
        ? profile.AnAtt45 === selectedFilters.besoNegro
        : true) &&
      (selectedFilters.besosBoca
        ? profile.AnAtt46 === selectedFilters.besosBoca
        : true) &&
      (selectedFilters.anal
        ? profile.AnAtt47 === selectedFilters.anal
        : true) &&
      (selectedFilters.strapOn
        ? profile.AnAtt48 === selectedFilters.strapOn
        : true) &&
      (selectedFilters.VtaCont
        ? profile.AnAtt60 === selectedFilters.VtaCont
        : true) &&
      (selectedFilters.peso
        ? profile.AnAtt16 === selectedFilters.peso
        : true) &&
      (selectedFilters.generation
        ? profile.AnAtt8 === selectedFilters.generation
        : true) &&
      (selectedFilters.etnia
        ? profile.AnAtt7 === selectedFilters.etnia
        : true) &&
      (selectedFilters.LocalidadId
        ? parseInt(profile.LocalidadId) === parseInt(selectedFilters.LocalidadId)
        : true) &&
      (selectedFilters.BarrioId
        ? parseInt(profile.BarrioId) === parseInt(selectedFilters.BarrioId)
        : true) &&
      profile.AnAge >= selectedFilters.ageRange[0] &&
      profile.AnAge <= selectedFilters.ageRange[1] &&
      profile.AnTarifa >= selectedFilters.priceRange[0] &&
      profile.AnTarifa <= selectedFilters.priceRange[1] &&
      (selectedFilters.language
        ? languages[selectedFilters.language] // Verificar si el idioma seleccionado es verdadero
        : true)
    );
  });
  // Función para manejar el clic en la tarjeta
  const handleCardClick = (AnId) => {
    navigate(`/main/chicas/${AnId}`);
  };

  return (
    <MasterPage>
      <div className="main-page-container">
        <h1 className="text-center">Filtrar Perfiles</h1>
        <div className="profile-filter">
          {/* Filtro por Nombre */}
          <div className="filter-category">
            <h5>
              <FontAwesomeIcon icon={faUser} className="me-2" />
              Nombre
            </h5>
            <input
              type="text"
              className="filter-input"
              placeholder="Buscar por nombre"
              value={selectedFilters.name}
              onChange={(e) => handleFilterChange("name", e.target.value)}
            />
          </div>

          {/* Filtro por Tipo */}
          <div className="filter-category">
            <h5>
              <FontAwesomeIcon icon={faVenusMars} className="me-2" />
              Tipo
            </h5>
            <div className="filterbutton-group">
              {typeOptions.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="radio"
                    name="type"
                    checked={selectedFilters.type === option.value}
                    onChange={() => handleFilterChange("type", option.value)}
                  />
                  {option.label}
                </label>
              ))}
            </div>
          </div>

          {/* Filtro por Localidad */}
          <div className="filter-category">
            <h5>Departamento</h5>
            <select
              name="LocalidadId"
              value={selectedFilters.LocalidadId}
              onChange={handleLocalidadChange}
              className="filter-select"
            >
              <option value="">Selecciona un departamento</option>
              {localidades.map((localidad) => (
                <option
                  key={localidad.LocalidadId}
                  value={localidad.LocalidadId}
                >
                  {localidad.LocalidadName}
                </option>
              ))}
            </select>
          </div>

          {/* Filtro por Barrio */}
          <div className="filter-category">
            <h5>Barrio</h5>
            <select
              name="BarrioId"
              value={selectedFilters.BarrioId}
              onChange={handleBarrioChange}
              className="filter-select"
            >
              <option value="">Selecciona un barrio</option>
              {barrios.map((barrio) => (
                <option key={barrio.BarrioId} value={barrio.BarrioId}>
                  {barrio.BarrioName}
                </option>
              ))}
            </select>
          </div>
          {/* Filtro por Rango de Edad */}
          <div className="filter-category">
            <h5 style={{ marginBottom: "5px" }}>Rango de Edad</h5>
            <Range
              step={1}
              min={18}
              max={60}
              values={selectedFilters.ageRange}
              onChange={handleAgeRangeChange}
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "6px",
                    width: "100%",
                    backgroundColor: "#ccc",
                    borderRadius: "3px",
                  }}
                >
                  {children}
                </div>
              )}
              renderThumb={({ index, props }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "20px",
                    width: "20px",
                    borderRadius: "50%",
                    backgroundColor: "blue",
                    cursor: "pointer",
                  }}
                />
              )}
            />
            <div className="age-range-display" style={{ marginBottom: "5px" }}>
              Edad: {selectedFilters.ageRange[0]} -{" "}
              {selectedFilters.ageRange[1]}
            </div>

            <h5 style={{ marginBottom: "5px" }}>Rango de Precio</h5>
            <Range
              step={10}
              min={0}
              max={10000}
              values={selectedFilters.priceRange}
              onChange={handlePriceRangeChange}
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "6px",
                    width: "100%",
                    backgroundColor: "#ccc",
                    borderRadius: "3px",
                  }}
                >
                  {children}
                </div>
              )}
              renderThumb={({ index, props }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "20px",
                    width: "20px",
                    borderRadius: "50%",
                    backgroundColor: "blue",
                    cursor: "pointer",
                  }}
                />
              )}
            />
            <div
              className="price-range-display"
              style={{ marginBottom: "5px" }}
            >
              Precio: ${selectedFilters.priceRange[0]} - $
              {selectedFilters.priceRange[1]}
            </div>
          </div>
          {/* Filtro por Altura */}
          <div className="filter-category">
            <h5>
              <FontAwesomeIcon icon={faRulerVertical} className="me-2" />
              Altura
            </h5>
            <div className="filterbutton-group">
              {heightOptions.map((option) => (
                <button
                  key={option.value}
                  className={`btn-modern ${
                    selectedFilters.height === option.value ? "btn-active" : ""
                  }`}
                  onClick={() => handleFilterChange("height", option.value)}
                >
                  {option.label}
                </button>
              ))}
            </div>
          </div>

          {/* Filtro por Cabello */}
          <div className="filter-category">
            <h5>
              <FontAwesomeIcon icon={faPalette} className="me-2" />
              Cabello
            </h5>
            <div className="filterbutton-group">
              {hairOptions.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="radio"
                    name="hair"
                    checked={selectedFilters.hair === option.value}
                    onChange={() => handleFilterChange("hair", option.value)}
                  />
                  {option.label}
                </label>
              ))}
            </div>
          </div>

          {/* Filtro por Idioma */}
          <div className="filter-category">
            <h5>Idioma</h5>
            <select
              name="language"
              value={selectedFilters.language}
              onChange={(e) => handleFilterChange("language", e.target.value)}
              className="filter-select"
            >
              <option value="">Seleccionar idioma</option>
              {langAttributes.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          {/* Filtro por Orintacion */}
          <div className="filter-category">
            <h5>
              <FontAwesomeIcon icon={faVenusMars} className="me-2" />
              Orientación sexual
            </h5>
            <div className="filterbutton-group">
              {orientationOptions.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="radio"
                    name="type"
                    checked={selectedFilters.orientation === option.value}
                    onChange={() =>
                      handleFilterChange("orientation", option.value)
                    }
                  />
                  {option.label}
                </label>
              ))}
            </div>
          </div>

          {/* Filtro por boobs */}
          <div className="filter-category">
            <h5>Pechos</h5>
            <div className="filterbutton-group">
              {boobsOptions.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="radio"
                    name="type"
                    checked={selectedFilters.boobs === option.value}
                    onChange={() => handleFilterChange("boobs", option.value)}
                  />
                  {option.label}
                </label>
              ))}
            </div>
          </div>

          {/* Filtro por peso */}
          <div className="filter-category">
            <h5>Peso</h5>
            <div className="filterbutton-group">
              {weightOptions.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="radio"
                    name="type"
                    checked={selectedFilters.peso === option.value}
                    onChange={() => handleFilterChange("peso", option.value)}
                  />
                  {option.label}
                </label>
              ))}
            </div>
          </div>

          {/* Filtro por generacion */}
          <div className="filter-category">
            <h5>Generación</h5>
            <div className="filterbutton-group">
              {generationOptions.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="radio"
                    name="type"
                    checked={selectedFilters.generation === option.value}
                    onChange={() =>
                      handleFilterChange("generation", option.value)
                    }
                  />
                  {option.label}
                </label>
              ))}
            </div>
          </div>

          {/* Filtro por generacion */}
          <div className="filter-category">
            <h5>Etnia</h5>
            <div className="filterbutton-group">
              {etniaOptions.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="radio"
                    name="type"
                    checked={selectedFilters.etnia === option.value}
                    onChange={() => handleFilterChange("etnia", option.value)}
                  />
                  {option.label}
                </label>
              ))}
            </div>
          </div>

          {/* Filtro por Atiendo a: */}
          <div className="filter-category">
            <h5>Atiendo a:</h5>
            <div className="filterbutton-group">
              {AtHOptions.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.Ath === option.value}
                    onChange={() => handleFilterChange("Ath", option.value)}
                  />
                  {option.label}
                </label>
              ))}
              {AtMOptions.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.Atm === option.value}
                    onChange={() => handleFilterChange("Atm", option.value)}
                  />
                  {option.label}
                </label>
              ))}
              {AtPDOptions.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.Atpd === option.value}
                    onChange={() => handleFilterChange("Atpd", option.value)}
                  />
                  {option.label}
                </label>
              ))}
              {AtOOptions.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.Ato === option.value}
                    onChange={() => handleFilterChange("Ato", option.value)}
                  />
                  {option.label}
                </label>
              ))}
              {Att2COptions.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.Att2c === option.value}
                    onChange={() => handleFilterChange("Att2c", option.value)}
                  />
                  {option.label}
                </label>
              ))}
              {AtT2EOptions.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.Att2e === option.value}
                    onChange={() => handleFilterChange("Att2e", option.value)}
                  />
                  {option.label}
                </label>
              ))}
              {AtPOptions.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.Atp === option.value}
                    onChange={() => handleFilterChange("Atp", option.value)}
                  />
                  {option.label}
                </label>
              ))}
              {AtSOptions.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.Ats === option.value}
                    onChange={() => handleFilterChange("Ats", option.value)}
                  />
                  {option.label}
                </label>
              ))}
            </div>
          </div>

          {/* Filtro por Tipo de servicio: */}
          <div className="filter-category">
            <h5>Tipo de servicio:</h5>
            <div className="filterbutton-group">
              {TSEcoOptions.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.Econo === option.value}
                    onChange={() => handleFilterChange("Econo", option.value)}
                  />
                  {option.label}
                </label>
              ))}
              {TSDOptions.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.Dama === option.value}
                    onChange={() => handleFilterChange("Dama", option.value)}
                  />
                  {option.label}
                </label>
              ))}
              {TSEOptions.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.Escort === option.value}
                    onChange={() => handleFilterChange("Escort", option.value)}
                  />
                  {option.label}
                </label>
              ))}
            </div>
          </div>

          {/* Filtro por sexo oral: */}
          <div className="filter-category">
            <h5>Sexo oral:</h5>
            <div className="filterbutton-group">
              {TSOptionConCondom.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.condon === option.value}
                    onChange={() => handleFilterChange("condon", option.value)}
                  />
                  {option.label}
                </label>
              ))}
              {TSOptionFinishOnBody.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.acabarCuerpo === option.value}
                    onChange={() =>
                      handleFilterChange("acabarCuerpo", option.value)
                    }
                  />
                  {option.label}
                </label>
              ))}
              {TSOptionFinishOnFace.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.acabarCara === option.value}
                    onChange={() =>
                      handleFilterChange("acabarCara", option.value)
                    }
                  />
                  {option.label}
                </label>
              ))}
              {TSOptionDeepThroat.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.gargantaProfunda === option.value}
                    onChange={() =>
                      handleFilterChange("gargantaProfunda", option.value)
                    }
                  />
                  {option.label}
                </label>
              ))}
              {TSOptionFullOral.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.oralCompleto === option.value}
                    onChange={() =>
                      handleFilterChange("oralCompleto", option.value)
                    }
                  />
                  {option.label}
                </label>
              ))}
              {TSOptionNatural.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.natural === option.value}
                    onChange={() => handleFilterChange("natural", option.value)}
                  />
                  {option.label}
                </label>
              ))}
            </div>
          </div>

          {/* Filtro por Otros: */}
          <div className="filter-category">
            <h5>Otros:</h5>
            <div className="filterbutton-group">
              {TSOptionRealLesbian.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.lesbicoReal === option.value}
                    onChange={() =>
                      handleFilterChange("lesbicoReal", option.value)
                    }
                  />
                  {option.label}
                </label>
              ))}
              {TSOptionCoprophilia.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.copro === option.value}
                    onChange={() => handleFilterChange("copro", option.value)}
                  />
                  {option.label}
                </label>
              ))}
              {TSOptionKissing.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.besosBoca === option.value}
                    onChange={() =>
                      handleFilterChange("besosBoca", option.value)
                    }
                  />
                  {option.label}
                </label>
              ))}
              {TSOptionRimming.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.besoNegro === option.value}
                    onChange={() =>
                      handleFilterChange("besoNegro", option.value)
                    }
                  />
                  {option.label}
                </label>
              ))}
              {TSOptionLingerie.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.lenceria === option.value}
                    onChange={() =>
                      handleFilterChange("lenceria", option.value)
                    }
                  />
                  {option.label}
                </label>
              ))}
              {TSOptionEroticToys.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.juguetesEroticos === option.value}
                    onChange={() =>
                      handleFilterChange("juguetesEroticos", option.value)
                    }
                  />
                  {option.label}
                </label>
              ))}
              {TSOptionFootjob.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.footjob === option.value}
                    onChange={() => handleFilterChange("footjob", option.value)}
                  />
                  {option.label}
                </label>
              ))}
              {TSOptionFantasies.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.fantasias === option.value}
                    onChange={() =>
                      handleFilterChange("fantasias", option.value)
                    }
                  />
                  {option.label}
                </label>
              ))}
              {TSOptionDoublePenetration.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.anal === option.value}
                    onChange={() => handleFilterChange("anal", option.value)}
                  />
                  {option.label}
                </label>
              ))}
              {TSOptionStrapOn.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.strapOn === option.value}
                    onChange={() => handleFilterChange("strapOn", option.value)}
                  />
                  {option.label}
                </label>
              ))}
            </div>
          </div>

          {/* Filtro por Lugar: */}
          <div className="filter-category">
            <h5>Lugar:</h5>
            <div className="filterbutton-group">
              {TSOptionSharedApt.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.aptoCompartido === option.value}
                    onChange={() =>
                      handleFilterChange("aptoCompartido", option.value)
                    }
                  />
                  {option.label}
                </label>
              ))}
              {TSOptionPrivateApt.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.aptoPrivado === option.value}
                    onChange={() =>
                      handleFilterChange("aptoPrivado", option.value)
                    }
                  />
                  {option.label}
                </label>
              ))}
              {TSOptionAlcoholicDrinks.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={
                      selectedFilters.bebidasAlcoholicas === option.value
                    }
                    onChange={() =>
                      handleFilterChange("bebidasAlcoholicas", option.value)
                    }
                  />
                  {option.label}
                </label>
              ))}
              {TSOptionDoubleBed.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.camaMatrimonio === option.value}
                    onChange={() =>
                      handleFilterChange("camaMatrimonio", option.value)
                    }
                  />
                  {option.label}
                </label>
              ))}
              {TSOptionHouse.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.casa === option.value}
                    onChange={() => handleFilterChange("casa", option.value)}
                  />
                  {option.label}
                </label>
              ))}
              {TSOptionMassageCenter.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.centroMasajes === option.value}
                    onChange={() =>
                      handleFilterChange("centroMasajes", option.value)
                    }
                  />
                  {option.label}
                </label>
              ))}
              {TSOptionAirConditioned.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.climatizado === option.value}
                    onChange={() =>
                      handleFilterChange("climatizado", option.value)
                    }
                  />
                  {option.label}
                </label>
              ))}
              {TSOptionWithShower.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.conDucha === option.value}
                    onChange={() =>
                      handleFilterChange("conDucha", option.value)
                    }
                  />
                  {option.label}
                </label>
              ))}
              {TSOptionJacuzzi.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.jacuzzi === option.value}
                    onChange={() => handleFilterChange("jacuzzi", option.value)}
                  />
                  {option.label}
                </label>
              ))}
              {TSOptionCardPayment.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.pagoTarjeta === option.value}
                    onChange={() =>
                      handleFilterChange("pagoTarjeta", option.value)
                    }
                  />
                  {option.label}
                </label>
              ))}
              {TSOptionNearbyParking.map((option) => (
                <label key={option.value} className="filter-radio">
                  <input
                    type="checkbox"
                    name="type"
                    checked={selectedFilters.parkingCerca === option.value}
                    onChange={() =>
                      handleFilterChange("parkingCerca", option.value)
                    }
                  />
                  {option.label}
                </label>
              ))}
            </div>
          </div>

          {/* Botones */}
          <div className="filterbutton-group">
            <div className="filter-buttons">
              <button
                className="btn-modern apply-filter-button"
                onClick={handleFilterButtonClick}
              >
                Aplicar Filtros
              </button>
            </div>
            <div className="filter-buttons">
              <button
                className="btn-modern apply-filter-button"
                onClick={clearFilters}
              >
                Limpiar Filtros
              </button>
            </div>
          </div>
        </div>
        {/* Mostrar los perfiles filtrados */}
        <Row className="g-4-row">
          {filteredProfiles.length > 0 ? (
            filteredProfiles
              .filter(
                (profile) => profile.AnEstado === 5 || profile.AnEstado === 1 // Filtrar perfiles por AnEstado
              )
              .map((profile, index) => {
                // Lógica para determinar las clases CSS dinámicamente
                let columnClass = "profile-col col-lg-4 col-md-6 col-12"; // Clases base

                if (profile.AnMsgEst && profile.AnEstadoWeb !== 4) {
                  if (profile.AnMsgEst.trim().length > 125) {
                    columnClass =
                      "profile-col long-message col-lg-4 col-md-6 col-12"; // Mensajes largos
                  } else {
                    columnClass =
                      "profile-col message col-lg-4 col-md-6 col-12"; // Mensajes normales
                  }
                }

                return (
                  <Col key={index} className={columnClass}>
                    <CardExample
                      name={profile.AnName}
                      age={profile.AnAge}
                      imgSrc={profile.imgUrl}
                      starRating={profile.calificacionTotal}
                      AnEstadoWeb={profile.AnEstadoWeb}
                      localidad={
                        profile.Barrio?.Localidad?.LocalidadName ||
                        "Localidad no disponible"
                      }
                      barrio={
                        profile.Barrio?.BarrioName || "Barrio no disponible"
                      }
                      anMsgEst={profile.AnMsgEst}
                      AnMsgDateTime={profile.AnMsgDateTime}
                      horario={profile.AnHorario}
                      tipo={profile.AnTipo}
                      onClick={() => handleCardClick(profile.AnId)} // Evento clic
                    />
                  </Col>
                );
              })
          ) : (
            <p>No se encontraron perfiles.</p>
          )}
        </Row>
      </div>
    </MasterPage>
  );
};

export default ProfileFilter;
