import React from "react";
import { useNavigate } from "react-router-dom"; // Importa useNavigate
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./MobileTabMenu.css";

const MobileTabMenu = ({ isOpen, toggle, tipo, modifiedUser }) => {
  const navigate = useNavigate(); // Inicializa useNavigate

  const handleUserClick = () => {
    if (tipo === "E") {
      navigate(`/panel/${modifiedUser}`); // Redirigir a "/panel" si el tipo es 'E'
    } else if(tipo === "V"){
      navigate(`/panelv/${modifiedUser}`)
    }
    else {
      navigate("/login"); // Redirigir a "/login" en caso contrario
    }
  };

  // Función para manejar la redirección a la página de active
  const handleActiveClick = () => {
    navigate("/main/active"); // Redirige a la página de Activas
  };

  const handleFavoritesClick = () => {
    navigate("/favorites/"+ modifiedUser);
  };

  const handleFilterClick = () => {
    navigate("/main/filter");
  };

  return (
    <div className={`mobile-tab-menu ${isOpen ? "open" : ""}`} onClick={toggle}>
      <div className="tab-container">
        <div className="tab part1"></div>
        <div className="tab part2">
          <i
            className={`fa-solid ${isOpen ? "fa-angle-down" : "fa-angle-up"}`}
          ></i>
        </div>
        <div className="tab part3"></div>
      </div>
      {isOpen && (
        <div className="grid-buttons">
          <div className="grid-row">
            <div className="grid-button" onClick={handleActiveClick}>
              <span>Activas</span>
              <i className="fas fa-play icon"></i>
            </div>
{/*             <div className="grid-button">
              <span>GPS</span>
              <i className="fas fa-map-marker-alt icon"></i>
            </div> */}
            <div className="grid-button" onClick={handleFilterClick}>
              <span>Filtros</span>
              <i className="fas fa-filter icon"></i>
            </div>
          </div>
          <div className="grid-row">
            {tipo !== "E" && (
              <div className="grid-button" onClick={handleFavoritesClick}>
                <span>Favoritos</span>
                <i className="fas fa-heart icon"></i>
              </div>
            )}

            <div className="grid-button" onClick={handleUserClick}>
              {/* Redirige al hacer click */}
              <span>{tipo === "E" || tipo === "V" ? "Panel" : "Usuario"}</span>
              <i className="fas fa-user icon"></i>
            </div>

            <div className="grid-button">
              <span>Notificaciones</span>
              <i className="fas fa-bell icon"></i>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileTabMenu;
