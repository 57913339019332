import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./CalificacionPage.css";
import MasterPage from "./MasterPage";
import useAuth from "../hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faReply,
  faEyeSlash,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

const host = process.env.REACT_APP_API_HOST;

const handleBack = () => {
  window.history.back();
};

const ResCalificacionPage = () => {
  const { anId } = useParams();
  const [calificaciones, setCalificaciones] = useState([]);
  const { isAuthenticated, isLoading } = useAuth(anId);
  const navigate = useNavigate();
  const [responses, setResponses] = useState({}); // Estado para almacenar respuestas


  useEffect(() => {
    if (!anId) navigate("/main");
  }, [anId, navigate]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      alert("Debe iniciar sesión para acceder a esta página.");
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchProfileAndCalificaciones = async () => {
      try {
        const calificacionesResponse = await axios.get(
          `${host}/api/calificacion/calificacion/${anId}`
        );
        const data = calificacionesResponse.data;

        console.log("Respuesta completa de la API:", data);

        if (data && Array.isArray(data.calificaciones)) {
          setCalificaciones(data.calificaciones);
        } else {
          console.warn("La API no devolvió el formato esperado.");
          setCalificaciones([]);
        }
      } catch (error) {
        console.error("Error al obtener datos:", error);
        setCalificaciones([]);
      }
    };

    fetchProfileAndCalificaciones();
  }, [anId]);

  const handleResponseChange = (id, value) => {
    setResponses((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleResponseSubmit = async (id) => {
    console.log(`${host}/api/calificacion/calificacion/${id}/responder`);
    try {
      const responseText = responses[id];
      await axios.put(`${host}/api/calificacion/calificacion/${id}/responder`, {
        CalRespuesta: responseText, // Cambiado para coincidir con el backend
      });
      alert("Respuesta enviada con éxito.");
      setCalificaciones((prev) =>
        prev.map((cal) =>
          cal.CalId === id ? { ...cal, CalRespuesta: responseText } : cal
        )
      );
    } catch (error) {
      console.error("Error al enviar respuesta:", error);
      alert("Error al enviar respuesta.");
    }
  };


  if (isLoading) {
    return <p>Validando acceso...</p>;
  }

  return (
    <MasterPage>
      <div className="main-page-container">
        <h1 onClick={handleBack} style={{ cursor: "pointer" }}>
          {"<  Calificaciones"}
        </h1>
        <div className="calificaciones-container">
          {Array.isArray(calificaciones) && calificaciones.length > 0 ? (
            calificaciones.map((cal, index) => (
              <div key={index} className="calificacion-card">
                <small
                  style={{
                    fontFamily: "sans-serif",
                    marginBottom: "5px",
                    marginTop: "5px",
                  }}
                >
                  {new Date(cal.CalDateTime).toLocaleString()}
                </small>
                <div className="Cal-card-header">
                  <h5>{cal.CalUser}</h5>
                  <p className="stars">{"⭐".repeat(cal.CalEstrellas)}</p>
                </div>

                <div className="Cal-card-body">
                  <p>{cal.CalComentario}</p>
                </div>

                {cal.CalRespuesta ? (
                  <div className="Cal-card-response">
                    <h6>Respuesta</h6>
                    <div className="Cal-card-body">
                      <p>{cal.CalRespuesta}</p>
                    </div>
                  </div>
                ) : (
                  <div className="Cal-card-response-input">
                    <textarea
                      value={responses[cal.CalId] || ""}
                      onChange={(e) =>
                        handleResponseChange(cal.CalId, e.target.value)
                      }
                      placeholder="Escribe una respuesta..."
                      rows="3"
                      style={{
                        width: "100%",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        padding: "10px",
                      }}
                    />
                    <button
                      className="Cal-btn-action"
                      onClick={() => handleResponseSubmit(cal.CalId)}
                    >
                      Enviar Respuesta
                    </button>
                  </div>
                )}

                <div className="Cal-action-buttons">
                  <button
                    className="Cal-btn-action"
                    onClick={() => console.log("Ocultar")}
                  >
                    <FontAwesomeIcon icon={faEyeSlash} /> Ocultar
                  </button>
                  <button
                    className="Cal-btn-action"
                    onClick={() => console.log("Borrar")}
                  >
                    <FontAwesomeIcon icon={faTrash} /> Borrar
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p>No hay calificaciones disponibles para este perfil.</p>
          )}
        </div>
      </div>
    </MasterPage>
  );
};

export default ResCalificacionPage;
