import React, { useState, useEffect } from "react";
import { Card, Toast, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import "./Buttons.css";
import Flag from "react-world-flags";

const CardType3 = ({ profile }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [profileData, setProfileData] = useState(profile);
  const isMobile = window.innerWidth <= 768;

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const getAtentionA = (att1) => {
    if (att1 === true) return "Apto.";
  };

  const getAtentionD = (att1) => {
    if (att1 === true) return "Domicilio";
  };

  const getAtentionH = (att1) => {
    if (att1 === true) return "Hotel";
  };

  const getHeightDescription = (att1) => {
    if (att1 === 1) return "Alta";
    if (att1 === 2) return "Estatura promedio";
    if (att1 === 3) return "Bajita";
    return "Sin información";
  };

  const getHairDescription = (att1) => {
    if (att1 === 1) return "Rubia";
    if (att1 === 2) return "Morocha";
    if (att1 === 3) return "Pelirroja";
    if (att1 === 4) return "Castaña";
    if (att1 === 5) return "Otro";
    return "Sin información";
  };

  const getMenAtention = (att1) => {
    if (att1 === true) return "Hombres";
    return "";
  };

  const getWMenAtention = (att1) => {
    if (att1 === true) return "Mujeres";
    return "";
  };

  const getCoupleAtention = (att1) => {
    if (att1 === true) return "Parejas";
    return "";
  };

  const get2EAtention = (att1) => {
    if (att1 === true) return "Trio 2 escorts";
    return "";
  };

  const get2CAtention = (att1) => {
    if (att1 === true) return "Trio 2 clientes";
    return "";
  };

  const getOrgAtention = (att1) => {
    if (att1 === true) return "Orgías";
    return "";
  };

  const getDiscAtention = (att1) => {
    if (att1 === true) return "Personas Disc.";
    return "";
  };

  const getSwinAtention = (att1) => {
    if (att1 === true) return "Swingers";
    return "";
  };

  const getCola = (att1) => {
    if (att1 === true) return "Cola grande";
    return "";
  };

  const getEtnia = (att1) => {
    if (att1 === 1) return "Asiática";
    if (att1 === 2) return "Blanca";
    if (att1 === 3) return "Latina";
    if (att1 === 4) return "Morena";

    return "Sin información";
  };

  const getGeneration = (att1) => {
    if (att1 === 1) return "Lolitas";
    if (att1 === 2) return "Universitarias";
    if (att1 === 3) return "Maduras";
    return "Sin información";
  };

  const getLangEN = (att1) => {
    if (att1 === true) {
      return <Flag code="gb" style={{ width: "20px", height: "20px" }} />;
    }
  };

  const getLangDE = (att1) => {
    if (att1 === true) {
      return <Flag code="de" style={{ width: "20px", height: "20px" }} />;
    }
  };

  const getLangIT = (att1) => {
    if (att1 === true) {
      return <Flag code="it" style={{ width: "20px", height: "20px" }} />;
    }
  };

  const getLangFR = (att1) => {
    if (att1 === true) {
      return <Flag code="fr" style={{ width: "20px", height: "20px" }} />;
    }
  };

  const getLangPT = (att1) => {
    if (att1 === true) {
      return <Flag code="br" style={{ width: "20px", height: "20px" }} />;
    }
  };

  const getOrientation = (att1) => {
    if (att1 === 1) return "Heterosexual";
    if (att1 === 2) return "Bisexual";
    if (att1 === 3) return "Homosexual";
    return "Sin información";
  };

  const getBoobs = (att1) => {
    if (att1 === 1) return "Pechos grandes";
    if (att1 === 2) return "Pechos pequeños";
    if (att1 === 3) return "Pechos naturales";
    if (att1 === 4) return "Pechos operados";
    return "Sin información";
  };

  const getWeight = (att1) => {
    if (att1 === 1) return "Delgada";
    if (att1 === 2) return "Peso promedio";
    if (att1 === 3) return "Gordita";
    if (att1 === 4) return "Grande";
    return "Sin información";
  };

  const getServiceType1 = (att1) => {
    if (att1 === true) return "Económicas";

    return "Sin información";
  };
  const getServiceType2 = (att1) => {
    if (att1 === true) return "Dama de compañía";
    return "Sin información";
  };
  const getServiceType3 = (att1) => {
    if (att1 === true) return "Escort de lujo";
    return "Sin información";
  };

  const getOuts1 = (att1) => {
    if (att1 === true) return "Domicilios";
    return "Sin información";
  };

  const getOuts2 = (att1) => {
    if (att1 === true) return "Despedidas";
    return "Sin información";
  };

  const getOuts3 = (att1) => {
    if (att1 === true) return "Eventos";
    return "Sin información";
  };

  const getOuts4 = (att1) => {
    if (att1 === true) return "Fiestas";
    return "Sin información";
  };

  const getOuts5 = (att1) => {
    if (att1 === true) return "Hoteles";
    return "Sin información";
  };

  const getOuts6 = (att1) => {
    if (att1 === true) return "Viajes cortos";
    return "Sin información";
  };

  const getOuts7 = (att1) => {
    if (att1 === true) return "Viajes largos";
    return "Sin información";
  };

  const getOuts8 = (att1) => {
    if (att1 === true) return "Viajes al exterior";
    return "Sin información";
  };

  const getOral1 = (att1) => {
    if (att1 === true) return "Con condón";
    return "Sin información";
  };

  const getOral2 = (att1) => {
    if (att1 === true) return "Natural";
    return "Sin información";
  };

  const getOral3 = (att1) => {
    if (att1 === true) return "Me lo trago";
    return "Sin información";
  };

  const getOral4 = (att1) => {
    if (att1 === true) return "Garganta profunda";
    return "Sin información";
  };

  const getOral5 = (att1) => {
    if (att1 === true) return "Acabado en cara";
    return "Sin información";
  };

  const getOral6 = (att1) => {
    if (att1 === true) return "Acabado en cuerpo";
    return "Sin información";
  };

  const getOther1 = (att1) => {
    if (att1 === true) return "Lésbico real";
    return "Sin información";
  };

  const getOther2 = (att1) => {
    if (att1 === true) return "Doble penetración";
    return "Sin información";
  };

  const getOther3 = (att1) => {
    if (att1 === true) return "Fantasías";
    return "Sin información";
  };

  const getOther4 = (att1) => {
    if (att1 === true) return "Footjob";
    return "Sin información";
  };

  const getOther5 = (att1) => {
    if (att1 === true) return "Juguetes eróticos";
    return "Sin información";
  };

  const getOther6 = (att1) => {
    if (att1 === true) return "Lencería";
    return "Sin información";
  };

  const getOther7 = (att1) => {
    if (att1 === true) return "Beso negro";
    return "Sin información";
  };

  const getOther8 = (att1) => {
    if (att1 === true) return "Beso en boca";
    return "Sin información";
  };

  const getOther9 = (att1) => {
    if (att1 === true) return "Anal";
    return "Sin información";
  };

  const getOther10 = (att1) => {
    if (att1 === true) return "Strap on";
    return "Sin información";
  };

  const getOther11 = (att1) => {
    if (att1 === true) return "Venta contenido";
    return "Sin información";
  };

  const getPlace1 = (att1) => {
    if (att1 === true) return "Apto compartido";
    return "Sin información";
  };

  const getPlace2 = (att1) => {
    if (att1 === true) return "Apto privado";
    return "Sin información";
  };

  const getPlace3 = (att1) => {
    if (att1 === true) return "Bebidas";
    return "Sin información";
  };

  const getPlace4 = (att1) => {
    if (att1 === true) return "Cama matrimonio";
    return "Sin información";
  };

  const getPlace5 = (att1) => {
    if (att1 === true) return "Casa";
    return "Sin información";
  };

  const getPlace6 = (att1) => {
    if (att1 === true) return "Centro masajes";
    return "Sin información";
  };

  const getPlace7 = (att1) => {
    if (att1 === true) return "Climatizado";
    return "Sin información";
  };

  const getPlace8 = (att1) => {
    if (att1 === true) return "Con ducha";
    return "Sin información";
  };

  const getPlace9 = (att1) => {
    if (att1 === true) return "Jacuzzi";
    return "Sin información";
  };

  const getPlace10 = (att1) => {
    if (att1 === true) return "Pago con tarjeta";
    return "Sin información";
  };

  const getPlace11 = (att1) => {
    if (att1 === true) return "Parking cerca";
    return "Sin información";
  };

  useEffect(() => {
    setProfileData(profile);
  }, [profile]);

  //ATIENDO EN:
  const atention_attributes = [
    { label: getAtentionA(profileData?.AnAtiendeA) },
    { label: getAtentionD(profileData?.AnAtiendeD) },
    { label: getAtentionH(profileData?.AnAtiendeH) },
  ].filter((attr) => attr.label && attr.label !== "Sin información");

  //PERFIL:
  const profile_attributes = [
    { label: getHeightDescription(profileData?.AnAtt1) },
    { label: getHairDescription(profileData?.AnAtt2) },

    { label: getCola(profileData?.AnAtt6) },
    { label: getEtnia(profileData?.AnAtt7) },
    { label: getGeneration(profileData?.AnAtt8) },

    { label: getOrientation(profileData?.AnAtt14) },
    { label: getBoobs(profileData?.AnAtt15) },
    { label: getWeight(profileData?.AnAtt16) },
  ].filter((attr) => attr.label && attr.label !== "Sin información"); // Filtra los atributos vacíos o "Sin información"

  //IDIOMAS:
  const lang_attributes = [
    { label: getLangEN(profileData?.AnAtt9) },
    { label: getLangDE(profileData?.AnAtt10) },
    { label: getLangIT(profileData?.AnAtt11) },
    { label: getLangFR(profileData?.AnAtt12) },
    { label: getLangPT(profileData?.AnAtt13) },
  ].filter((attr) => attr.label && attr.label !== "Sin información"); // Filtra los atributos vacíos o "Sin información"

  /*********************************************************************************/
  /*********************************SERVICIOS***************************************/
  /*********************************************************************************/
  //Atiendo a:
  const who_attributes = [
    { label: getMenAtention(profileData?.AnAtt3) },
    { label: getWMenAtention(profileData?.AnAtt4) },
    { label: getCoupleAtention(profileData?.AnAtt5) },
    { label: get2EAtention(profileData?.AnAtt17) },
    { label: get2CAtention(profileData?.AnAtt18) },
    { label: getOrgAtention(profileData?.AnAtt19) },
    { label: getDiscAtention(profileData?.AnAtt20) },
    { label: getSwinAtention(profileData?.AnAtt21) },
  ].filter((attr) => attr.label && attr.label !== "Sin información"); // Filtra los atributos vacíos o "Sin información"

  //Tipo servicio:
  const service_attributes = [
    { label: getServiceType1(profileData?.AnAtt22) },
    { label: getServiceType2(profileData?.AnAtt23) },
    { label: getServiceType3(profileData?.AnAtt24) },
  ].filter((attr) => attr.label && attr.label !== "Sin información"); // Filtra los atributos vacíos o "Sin información"

  //Salidas
  const outs_attributes = [
    { label: getOuts1(profileData?.AnAtt25) },
    { label: getOuts2(profileData?.AnAtt26) },
    { label: getOuts3(profileData?.AnAtt27) },
    { label: getOuts4(profileData?.AnAtt28) },
    { label: getOuts5(profileData?.AnAtt29) },
    { label: getOuts6(profileData?.AnAtt30) },
    { label: getOuts7(profileData?.AnAtt31) },
    { label: getOuts8(profileData?.AnAtt32) },
  ].filter((attr) => attr.label && attr.label !== "Sin información");

  //Oral
  const oral_attributes = [
    { label: getOral1(profileData?.AnAtt33) },
    { label: getOral2(profileData?.AnAtt34) },
    { label: getOral3(profileData?.AnAtt35) },
    { label: getOral4(profileData?.AnAtt36) },
    { label: getOral5(profileData?.AnAtt37) },
    { label: getOral6(profileData?.AnAtt38) },
  ].filter((attr) => attr.label && attr.label !== "Sin información");

  //Otros
  const other_attributes = [
    { label: getOther1(profileData?.AnAtt39) },
    { label: getOther2(profileData?.AnAtt40) },
    { label: getOther3(profileData?.AnAtt41) },
    { label: getOther4(profileData?.AnAtt42) },
    { label: getOther5(profileData?.AnAtt43) },
    { label: getOther6(profileData?.AnAtt44) },
    { label: getOther7(profileData?.AnAtt45) },
    { label: getOther8(profileData?.AnAtt46) },
    { label: getOther9(profileData?.AnAtt47) },
    { label: getOther10(profileData?.AnAtt48) },
    { label: getOther11(profileData?.AnAtt60) },
  ].filter((attr) => attr.label && attr.label !== "Sin información");
  //Lugas
  const place_attributes = [
    { label: getPlace1(profileData?.AnAtt49) },
    { label: getPlace2(profileData?.AnAtt50) },
    { label: getPlace3(profileData?.AnAtt51) },
    { label: getPlace4(profileData?.AnAtt52) },
    { label: getPlace5(profileData?.AnAtt53) },
    { label: getPlace6(profileData?.AnAtt54) },
    { label: getPlace7(profileData?.AnAtt55) },
    { label: getPlace8(profileData?.AnAtt56) },
    { label: getPlace9(profileData?.AnAtt57) },
    { label: getPlace10(profileData?.AnAtt58) },
    { label: getPlace11(profileData?.AnAtt59) },
  ].filter((attr) => attr.label && attr.label !== "Sin información");

  return (
    <div className="content" style={{ marginLeft: "-10px", marginTop: "25px" }}>
      <Card className={isMobile ? "custom-card" : "custom-card-profile"}>
        <Toast>
          <Toast.Header closeButton={false}>
            <strong className="me-auto">Info:</strong>
            <FontAwesomeIcon
              icon={isExpanded ? faAngleUp : faAngleDown}
              style={{
                cursor: "pointer",
                marginLeft: "auto",
              }}
              onClick={toggleExpand}
            />
          </Toast.Header>
          {isExpanded && (
            <>
              <Toast.Body>
                {profileData?.AnObs || "Sin observaciones."}
              </Toast.Body>
              <hr />
              <hr />

              {atention_attributes.length > 0 && (
                <div
                  className="me-auto"
                  style={{ marginLeft: "10px", marginBottom: "5px" }}
                >
                  <div className="InfoText" style={{ fontWeight: "bold" }}>
                    Atiendo en:
                  </div>
                  <div className="custom-container">
                    <div className="custom-row">
                      {atention_attributes.map((attr, index) => (
                        <div key={index} className="col-12 col-sm-6 col-md-4">
                          <Button
                            variant="outline-primary"
                            className="btn-modern phone-btn"
                          >
                            {attr.label}
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {profile_attributes.length > 0 && (
                <div
                  className="me-auto"
                  style={{ marginLeft: "10px", marginBottom: "5px" }}
                >
                  <div className="InfoText" style={{ fontWeight: "bold" }}>
                    Perfil:
                  </div>

                  <div className="custom-container">
                    <div className="custom-row">
                      {profile_attributes.map((attr, index) => (
                        <div key={index} className="col-12 col-sm-6 col-md-4">
                          <Button
                            variant="outline-primary"
                            className="btn-modern phone-btn"
                          >
                            {attr.label}
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {lang_attributes.length > 0 && (
                <div
                  className="me-auto"
                  style={{ marginLeft: "10px", marginBottom: "5px" }}
                >
                  <div className="InfoText" style={{ fontWeight: "bold" }}>
                    Idiomas:
                  </div>
                  <div className="custom-container">
                    <div className="custom-row">
                      {lang_attributes.map((attr, index) => (
                        <div key={index} className="col-12 col-sm-6 col-md-4">
                          <Button
                            variant="outline-primary"
                            className="btn-modern phone-btn"
                          >
                            {attr.label}
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {who_attributes.length > 0 && (
                <div
                  className="me-auto"
                  style={{ marginLeft: "25px", marginBottom: "5px" }}
                >
                  <div className="InfoText" style={{ fontWeight: "bold" }}>
                    Atiendo a:
                  </div>

                  <div className="custom-container">
                    <div className="custom-row">
                      {who_attributes.map((attr, index) => (
                        <div key={index} className="col-12 col-sm-6 col-md-4">
                          <Button
                            variant="outline-primary"
                            className="btn-modern phone-btn"
                          >
                            {attr.label}
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {service_attributes.length > 0 && (
                <div
                  className="me-auto"
                  style={{ marginLeft: "25px", marginBottom: "5px" }}
                >
                  <div className="InfoText" style={{ fontWeight: "bold" }}>
                    Tipo de servicio:
                  </div>

                  <div className="custom-container">
                    <div className="custom-row">
                      {service_attributes.map((attr, index) => (
                        <div key={index} className="col-12 col-sm-6 col-md-4">
                          <Button
                            variant="outline-primary"
                            className="btn-modern phone-btn"
                          >
                            {attr.label}
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {outs_attributes.length > 0 && (
                <div
                  className="me-auto"
                  style={{ marginLeft: "25px", marginBottom: "5px" }}
                >
                  <div className="InfoText" style={{ fontWeight: "bold" }}>
                    Salidas:
                  </div>

                  <div className="custom-container">
                    <div className="custom-row">
                      {outs_attributes.map((attr, index) => (
                        <div key={index} className="col-12 col-sm-6 col-md-4">
                          <Button
                            variant="outline-primary"
                            className="btn-modern phone-btn"
                          >
                            {attr.label}
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {oral_attributes.length > 0 && (
                <div
                  className="me-auto"
                  style={{ marginLeft: "25px", marginBottom: "5px" }}
                >
                  <div className="InfoText" style={{ fontWeight: "bold" }}>
                    Sexo oral:
                  </div>

                  <div className="custom-container">
                    <div className="custom-row">
                      {oral_attributes.map((attr, index) => (
                        <div key={index} className="col-12 col-sm-6 col-md-4">
                          <Button
                            variant="outline-primary"
                            className="btn-modern phone-btn"
                          >
                            {attr.label}
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {other_attributes.length > 0 && (
                <div
                  className="me-auto"
                  style={{ marginLeft: "25px", marginBottom: "5px" }}
                >
                  <div className="InfoText" style={{ fontWeight: "bold" }}>
                    Otros:
                  </div>

                  <div className="custom-container">
                    <div className="custom-row">
                      {other_attributes.map((attr, index) => (
                        <div key={index} className="col-12 col-sm-6 col-md-4">
                          <Button
                            variant="outline-primary"
                            className="btn-modern phone-btn"
                          >
                            {attr.label}
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {place_attributes.length > 0 && (
                <div
                  className="me-auto"
                  style={{ marginLeft: "25px", marginBottom: "5px" }}
                >
                  <div className="InfoText" style={{ fontWeight: "bold" }}>
                    Lugar:
                  </div>

                  <div className="custom-container">
                    <div className="custom-row">
                      {place_attributes.map((attr, index) => (
                        <div key={index} className="col-12 col-sm-6 col-md-4">
                          <Button
                            variant="outline-primary"
                            className="btn-modern phone-btn"
                          >
                            {attr.label}
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </Toast>
      </Card>
    </div>
  );
};

export default CardType3;
