import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import "./CalificacionPage.css"; // Archivo CSS para estilos personalizados.
import MasterPage from "./MasterPage";
const host = process.env.REACT_APP_API_HOST;

const handleBack = () => {
  window.history.back();
};
const CalificacionPage = () => {
  const { anId } = useParams();
  const [profile, setProfile] = useState(null);
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [calificacion, setCalificacion] = useState({
    CalEstrellas: 0,
    CalComentario: "",
    CalSiNo: true,
    CalEstado: 1,
  });
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Actualiza si es móvil o no
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      alert("Debe iniciar sesión para acceder a esta página.");
      navigate("/login");
      return;
    }

    try {
      const decoded = jwtDecode(token);
      console.log("decoded", decoded);
      const currentTime = Math.floor(Date.now() / 1000);
      if (decoded.exp < currentTime) {
        alert("Su sesión ha expirado. Por favor, inicie sesión nuevamente.");
        localStorage.removeItem("token");
        navigate("/login");
        return;
      }
      if (decoded.tipo !== "V") {
        alert(
          "Debe iniciar sesión como visitante para usar esta funcionalidad."
        );
        navigate("/main");
        return;
      }
      setUserId(decoded.id);
    } catch (error) {
      console.error("Error al decodificar el token:", error);
    }
  }, [navigate]);

  useEffect(() => {
    console.log("Host API:", host);
    console.log("anId recibido:", anId);

    const fetchProfile = async () => {
      try {
        const response = await axios.get(`${host}/api/perfil/${anId}`);
        console.log("Datos del perfil:", response.data);
        setProfile(response.data);
      } catch (error) {
        console.error("Error al obtener el perfil:", error);
      }
    };

    fetchProfile();
  }, [anId]);

  const handleEstrellasClick = (index) => {
    setCalificacion({ ...calificacion, CalEstrellas: index + 1 });
  };

  const handleSubmit = async () => {
    // Verificar que al menos se haya seleccionado una estrella
    if (calificacion.CalEstrellas === 0) {
      alert("Por favor, seleccione al menos una estrella.");
      return; // No continuar con el envío si no se seleccionó ninguna estrella
    }
    if (calificacion.CalComentario === "") {
      alert("Por favor, escriba un comentario.");
      return; // No continuar con el envío si no se seleccionó ninguna estrella
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/api/calificacion/`,
        {
          ...calificacion,
          CalAnId: anId,
          CalUser: userId,
        }
      );
      if (response.status === 201) {
        alert("Calificación enviada con éxito.");
        navigate(`/main/chicas/${anId}`);
      }
    } catch (error) {
      console.error(
        "Error al enviar la calificación:",
        error.response || error
      );
      alert("Hubo un error al enviar la calificación.");
    }
  };

  if (!profile) {
    return (
      <p style={{ fontSize: "small", color: "white" }}>Cargando perfil...</p>
    );
  }

  return (
    <MasterPage>
      <div className="main-page-container">
        <h1 onClick={handleBack} style={{ cursor: "pointer" }}>
          {"<  Calificación"}
        </h1>

        <div className="calificacion-container">
          <div className="perfil-card">
            <img src={profile.imgUrl} alt="Perfil" className="perfil-foto" />
            <h3>{profile.AnName}</h3>
          </div>
          <div className="calificacion-form">
            <div className="calificacion-si-no">
              <label>
                ¿Le gustó la atención?
                <input
                  type="radio"
                  name="CalSiNo"
                  value={true}
                  checked={calificacion.CalSiNo === true}
                  onChange={() =>
                    setCalificacion({ ...calificacion, CalSiNo: true })
                  }
                />
                Sí
              </label>
              <label>
                <input
                  type="radio"
                  name="CalSiNo"
                  value={false}
                  checked={calificacion.CalSiNo === false}
                  onChange={() =>
                    setCalificacion({ ...calificacion, CalSiNo: false })
                  }
                />
                No
              </label>
            </div>
            <div className="calificacion-estrellas">
              {[...Array(5)].map((_, index) => (
                <span
                  key={index}
                  className={`estrella ${
                    index < calificacion.CalEstrellas ? "llena" : ""
                  }`}
                  onClick={() => handleEstrellasClick(index)}
                >
                  ★
                </span>
              ))}
            </div>
            <textarea
              style={{
                fontSize: isMobile ? "18px" : "20px", // Diferentes tamaños según la condición
              }}
              placeholder="Escriba un comentario..."
              value={calificacion.CalComentario}
              onChange={(e) =>
                setCalificacion({
                  ...calificacion,
                  CalComentario: e.target.value,
                })
              }
            />
            <button className="calificacion-button" onClick={handleSubmit}>
              Enviar Calificación
            </button>
          </div>
        </div>
      </div>
    </MasterPage>
  );
};

export default CalificacionPage;
