import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import MasterPage from "./MasterPage";

import GalleryCard from "./GalleryCard"; // Importa la galería


const host = process.env.REACT_APP_API_HOST;
//const port = process.env.REACT_APP_API_PORT;

const Book = () => {
  const { BookId } = useParams(); // Obtiene el ID del perfil desde la URL
  const [galeria, setGaleria] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Estado para dispositivo móvil
  const handleBack = () => {
    window.history.back();
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Actualiza si es móvil o no
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchGaleria = async () => {
      try {
        console.log('BookId', BookId);
        const response = await axios.get(
          `${host}/api/books/${BookId}/gallery`
        );
        setGaleria(response.data); // Asegúrate de que esto sea correcto
        console.log('response.data', response.data);
      } catch (error) {
        console.error("Error al obtener la galería:", error);
      }
    };
    fetchGaleria();
    // Llama a la función para obtener calificaciones
  }, [BookId]);

  return (
    <MasterPage>
      <div className={isMobile ? "content custom-margin" : ""}>
        <h1 onClick={handleBack} style={{ cursor: "pointer" }}>
          {"<"}
        </h1>
        <div className="content_prf">
          <div>
            
            <GalleryCard images={galeria} />
          </div>
        </div>
      </div>
    </MasterPage>
  );
};

export default Book;
