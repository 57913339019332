// PrivateRoute.jsx
import { Navigate } from "react-router-dom";
import useAuth from "./useAuth";

const PrivateRoute = ({ children, modifiedUser }) => {
/*   console.log("AnId que mando en PrivateRoute: ", modifiedUser); */
  const { isAuthenticated, isLoading } = useAuth(modifiedUser);

  if (isLoading) return null; // Espera hasta que termine la verificación

  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
