import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import MasterPage from "../components/MasterPage";
import GalleryUpdate from "./GalleryUpdate";
import "./Style.css";
import useAuth from "../hooks/useAuth";

const Estado = () => {
  const [estado, setEstado] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [gallery, setGallery] = useState([]);
  const navigate = useNavigate();
  const { anId } = useParams();
  const [profile, setProfile] = useState(null);
  const { isAuthenticated, isLoading, tipo } = useAuth(anId);
  const host = process.env.REACT_APP_API_HOST;
/*   const port = process.env.REACT_APP_API_PORT; */

  // Fetch gallery and profile using useCallback for memoization
  const fetchGallery = useCallback(async () => {
    try {
      const response = await axios.get(
        `${host}/api/perfilGaleria/${anId}`
      );
      setGallery(response.data);
    } catch (error) {
      console.error("Error al obtener la galería:", error);
      setError("Error al cargar la galería.");
    }
  }, [anId, host]);

  const fetchProfile = useCallback(async () => {
    try {
      const response = await axios.get(
        `${host}/api/perfil/${anId}`
      );
      setProfile(response.data);
    } catch (error) {
      console.error("Error al obtener el perfil:", error);
    }
  }, [anId, host]);

  useEffect(() => {
    if (!anId) {
      navigate("/main");
    } else if (isAuthenticated) {
      fetchGallery();
      fetchProfile();
    }
  }, [anId, navigate, fetchGallery, fetchProfile, isAuthenticated]);

  useEffect(() => {
    if (profile && profile.AnMsgEst) {
      setEstado(profile.AnMsgEst);
    }
  }, [profile]);

  const handleEstadoChange = (e) => {
    setEstado(e.target.value);
  };

  const updateEstado = async (estado, tipoEstado) => {
    setLoading(true);
    setError("");
    setSuccess(false);
  
    try {
      await axios.put(`${host}/api/perfil/estado/${anId}`, {
        estado, // Enviar el mensaje de estado
        tipoEstado, // Enviar el tipo de estado
      });
  
      setSuccess(true);
      setProfile((prev) => ({ ...prev, AnMsgEst: estado }));
    } catch (error) {
      console.error("Error al actualizar el estado:", error);
      setError("Hubo un problema al actualizar el estado.");
    } finally {
      setLoading(false);
    }
  };
  
  // Enviar estado y tipoEstado cuando se haga submit en el formulario
  const handleEstadoSubmit = async (e) => {
    e.preventDefault();
  
    if (!estado) {
      setError("Por favor ingresa un mensaje de estado");
      return;
    }
  
    await updateEstado(estado, 1); // Enviar el estado y tipoEstado para "Guardar Estado"
  };
  
  // Enviar solo tipoEstado 4 para "Apagar"
  const handleApagar = async () => {
    await updateEstado("", 4); // Tipo de estado 4 para "Apagar"
  };
  
  // Enviar solo tipoEstado 5 para "Ocultar perfil"
  const handleOcultar = async () => {
    await updateEstado("", 5); // Tipo de estado 5 para "Ocultar perfil"
  };
  

  if (isLoading) {
    return <p>Validando acceso...</p>;
  }

  if (!isAuthenticated) {
    navigate("/login");
    return null;
  }

  return (
    <MasterPage tipo={tipo} modifiedUser={anId}>
      <div className="estado-page-custom">
        <h1
          onClick={() => window.history.back()}
          style={{ cursor: "pointer" }}
          className="estado-title-custom"
        >
          {"<"} Actualizar Estado
        </h1>
        <div className="estado-form-container-custom">
          <form onSubmit={handleEstadoSubmit} className="estado-form-custom">
            <div className="profile-info">
              {profile && profile.imgUrl2 ? (
                <img
                  src={profile.imgUrl2}
                  alt="Perfil"
                  className="profile-image"
                />
              ) : (
                <div className="profile-image-placeholder">Sin imagen</div>
              )}

              <textarea
                value={estado}
                onChange={handleEstadoChange}
                placeholder="Escribe tu estado..."
                className="estado-input-custom"
                rows="4"
              ></textarea>
            </div>
            {error && <p className="error-message-custom">{error}</p>}
            {success && (
              <p className="success-message-custom">
                Estado actualizado exitosamente
              </p>
            )}
            <button
              type="submit"
              className="estado-button-custom"
              disabled={loading}
            >
              {loading ? "Guardando..." : "Guardar Estado"}
            </button>

            {/* Nuevos botones */}
            <div className="additional-buttons-container">
              <button
                type="button"
                className="additional-button"
                onClick={handleApagar}
              >
                Apagar
              </button>
              <button
                type="button"
                className="additional-button"
                onClick={handleOcultar}
              >
                Ocultar perfil
              </button>
            </div>
          </form>
        </div>

        {/* Galería */}
        <div className="gallery-container-custom">
          <h2 className="gallery-title-custom">Galería</h2>
          <GalleryUpdate initialImages={gallery} />
        </div>
      </div>
    </MasterPage>
  );
};

export default Estado;
