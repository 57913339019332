import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import MasterPage from "./MasterPage";
import GalleryUpdate from "./GalleryUpdate";
import "./Media.css";
import "./Style.css";
import useAuth from "../hooks/useAuth";

const MediaBookV = () => {
  const navigate = useNavigate();
  const { anId: paramAnId } = useParams();
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [gallery, setGallery] = useState([]);
  const [anId, setAnId] = useState(paramAnId || "");

  const { modifiedUser } = useParams();
  const { isAuthenticated, isLoading, tipo } = useAuth(modifiedUser);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, isLoading, navigate]);

  useEffect(() => {
    if (tipo && tipo !== "A") {
      navigate("/main");
    }
  }, [tipo, navigate]);

  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
  };

/*   const handleSearch = () => {
    if (anId.trim() === "") {
      setError("Ingrese un AnId válido");
      return;
    }
    fetchGallery();
  }; */

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!selectedFiles || !anId) {
      setError("Por favor selecciona un archivo y asegúrate de ingresar un AnId");
      return;
    }

    const formData = new FormData();
    Array.from(selectedFiles).forEach((file) => {
      formData.append("files", file);
    });

    setLoading(true);

    try {
      const host = process.env.REACT_APP_API_HOST;
      await axios.post(`${host}/api/uploadBook/${anId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setSuccess(true);
      /* fetchGallery(); */
    } catch (error) {
      console.error("Error al subir archivos:", error);
      setError("Hubo un problema al subir los archivos.");
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return <p>Validando acceso...</p>;
  }

  if (!isAuthenticated) {
    return null;
  }

  return (
    <MasterPage modifiedUser={modifiedUser}>
      <div className="upload-media-page-custom">
        <h1
          onClick={() => window.history.back()}
          style={{ cursor: "pointer" }}
          className="upload-title-custom"
        >
          {"<"} Subir Fotos o Videos
        </h1>
        <div className="upload-form-container-custom">
          <form className="upload-form-custom">
            <input
              type="text"
              value={anId}
              onChange={(e) => setAnId(e.target.value)}
              placeholder="Ingrese AnId"
              className="file-input-custom"
            />
        {/* <button onClick={handleSearch} className="upload-button-custom">
              Buscar
            </button> */}
          </form>
        </div>
        <div className="upload-form-container-custom">
          <form onSubmit={handleUpload} className="upload-form-custom">
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              accept="image/*,video/*"
              className="file-input-custom"
            />
            {error && <p className="error-message-custom">{error}</p>}
            {success && (
              <p className="success-message-custom">
                Archivos subidos exitosamente
              </p>
            )}
            <button
              type="submit"
              className="upload-button-custom"
              disabled={loading}
            >
              {loading ? "Subiendo..." : "Subir Archivos"}
            </button>
          </form>
        </div>
        <div className="gallery-container-custom">
          <h2 className="gallery-title-custom">Galería</h2>
          <GalleryUpdate initialImages={gallery} />
        </div>
      </div>
    </MasterPage>
  );
};

export default MediaBookV;