import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import "../components/ModifyPage.css";

const host = process.env.REACT_APP_API_HOST;
/* const port = process.env.REACT_APP_API_PORT;
 */
const ModifyProfile = () => {
  const [profile, setProfile] = useState({
    AnName: "",
    AnPhone: "",
    AnEmail: "",
    AnAge: "",
    AnTarifa: "",
    AnContactoWP: false,
    AnVerTarifa: false,
    AnAtiendeH: false,
    AnAtiendeA: false,
    AnAtiendeD: false,
    AnHorario: "",
    AnObs: "",
    LocalidadId: "",
    BarrioId: "",
    AnCategoria: "",
    AnTipo: "",
  });

  const [localidades, setLocalidades] = useState([]);
  const [barrios, setBarrios] = useState([]);

  const { anId } = useParams();
  const navigate = useNavigate();
  const isAuthenticated = useAuth(anId);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(
          `${host}/api/perfil/${anId}`
        );
        setProfile(response.data);
      } catch (error) {
        console.error("Error al obtener el perfil:", error);
      }
    };

    fetchProfile();
  }, [anId]); // Solo depende de anId

  useEffect(() => {
    const fetchLocalidades = async () => {
      try {
        const response = await axios.get(
          `${host}/api/localidades`
        );
        setLocalidades(response.data);
      } catch (error) {
        console.error("Error al obtener las localidades:", error);
      }
    };

    fetchLocalidades();
  }, []); // Este solo se ejecuta una vez al montar

  useEffect(() => {
    const fetchBarrios = async () => {
      if (profile.LocalidadId) {
        try {
          const response = await axios.get(
            `${host}/api/barrio/localidad/${profile.LocalidadId}`
          );
          setBarrios(response.data);
        } catch (error) {
          console.error("Error al obtener los barrios:", error);
        }
      } else {
        setBarrios([]);
      }
    };

    fetchBarrios();
  }, [profile.LocalidadId]); // Se ejecuta solo cuando cambia LocalidadId

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    let newValue = value; // Default for text inputs
    if (type === "checkbox") {
      newValue = checked;
    } else if (type === "number") {
      newValue = parseInt(value, 10) || ""; // Convert to number, default to empty
    }

    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: newValue,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${host}/api/perfil/${anId}`, profile);
      navigate(`/panel/${anId}`); // Redirige al perfil actualizado
    } catch (error) {
      console.error("Error al modificar el perfil:", error);
    }
  };

  const handleCancel = () => {
    navigate(`/panel/${anId}`);
  };

  const handleBack = () => {
    window.history.back();
  };

  return (
    <div>
      <h1 onClick={handleBack} style={{ cursor: "pointer" }}> {"<"}   Modificar Perfil</h1>
      {/* <h1>Modificar Perfil</h1> */}
      <div className="modify-profile-page">
        <form onSubmit={handleSubmit} className="modify-profile-form">
          {/* Combobox de Categoría */}
          <div className="form-group">
            <label htmlFor="AnCategoria">Categoría</label>
            <select
              id="AnCategoria"
              name="AnCategoria"
              value={profile.AnCategoria}
              onChange={handleChange}
              className="register-select"
              required
            >
              <option value="">Selecciona una categoría</option>
              <option value="1">Elite 1</option>
              <option value="2">Elite 2</option>
              <option value="3">Elite 3</option>
              <option value="4">Normal</option>
            </select>
          </div>
          {/* Nombre */}
          <div className="form-group">
            <label htmlFor="AnName">Nombre</label>
            <input
              type="text"
              id="AnName"
              name="AnName"
              value={profile.AnName}
              onChange={handleChange}
              required
            />
          </div>
          {/* Edad */}
          <div className="mb-3">
            <label className="form-check-label" htmlFor="AnAge">
              Edad
            </label>
            <input
              type="number"
              className="register-input"
              id="AnAge"
              name="AnAge"
              placeholder="Edad"
              value={profile.AnAge}
              onChange={handleChange}
              required
            />
          </div>
          {/* Combobox de Género */}
          <div className="form-group">
            <label htmlFor="AnTipo">Género</label>
            <select
              id="AnTipo"
              name="AnTipo"
              value={profile.AnTipo}
              onChange={handleChange}
              className="register-select"
              required
            >
              <option value="">Selecciona un género</option>
              <option value="1">Chica</option>
              <option value="2">Trans</option>
              <option value="3">Chico</option>
            </select>
          </div>
          {/* Teléfono */}
          <div className="form-group">
            <label htmlFor="AnPhone">Teléfono</label>
            <input
              type="text"
              id="AnPhone"
              name="AnPhone"
              value={profile.AnPhone}
              onChange={handleChange}
              required
            />
          </div>
          {/* Contacto por WhatsApp */}
          <div className="form-check mb-3">
            <label className="form-check-label" htmlFor="AnContactoWP">
              Contacto por WhatsApp?
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnContactoWP"
              name="AnContactoWP"
              checked={profile.AnContactoWP}
              onChange={handleChange}
            />
          </div>
          {/* Horario */}
          <div className="form-group">
            <label htmlFor="AnHorario">Horario</label>
            <input
              type="text"
              id="AnHorario"
              name="AnHorario"
              value={profile.AnHorario}
              onChange={handleChange}
              required
            />
          </div>

          {/* Email */}
          <div className="mb-3">
            <label className="form-check-label" htmlFor="AnEmail">
              Email:
            </label>
            <input
              type="email"
              className="register-input"
              id="AnEmail"
              name="AnEmail"
              placeholder="Email"
              value={profile.AnEmail}
              onChange={handleChange}
            />
          </div>

          {/* Combobox de Localidad */}
          <div className="form-group">
            <label htmlFor="LocalidadId">Localidad</label>
            <select
              id="LocalidadId"
              name="LocalidadId"
              value={profile.LocalidadId}
              onChange={handleChange}
              required
            >
              <option value="">Selecciona una localidad</option>
              {localidades.map((localidad) => (
                <option
                  key={localidad.LocalidadId}
                  value={localidad.LocalidadId}
                >
                  {localidad.LocalidadName}
                </option>
              ))}
            </select>
          </div>

          {/* Combobox de Barrio */}
          <div className="form-group">
            <label htmlFor="BarrioId">Barrio</label>
            <select
              id="BarrioId"
              name="BarrioId"
              value={profile.BarrioId}
              onChange={handleChange}
            >
              <option value="">Selecciona un barrio</option>
              {barrios.map((barrio) => (
                <option key={barrio.BarrioId} value={barrio.BarrioId}>
                  {barrio.BarrioName}
                </option>
              ))}
            </select>
          </div>

          {/* Mostrar tarifa */}
          <div className="form-check mb-3">
            <label className="form-check-label ms-2" htmlFor="AnVerTarifa">
              Mostrar Tarifa?
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnVerTarifa"
              name="AnVerTarifa"
              checked={profile.AnVerTarifa}
              onChange={handleChange}
            />
          </div>
          {/* Tarifa */}
          <div className="mb-3">
            <label className="form-check-label ms-2" htmlFor="AnVerTarifa">
              Tarifa
            </label>
            <input
              type="number"
              className="register-input"
              id="AnTarifa"
              name="AnTarifa"
              placeholder="Tarifa"
              value={profile.AnTarifa}
              onChange={handleChange}
            />
          </div>
          <div>
            <h4>Atiendo en:</h4>
          </div>
          {/* Atiendo en: */}
          <div className="form-check mb-3">
            <label className="form-check-label" htmlFor="AnAtiendeH">
              Hotel
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtiendeH"
              name="AnAtiendeH"
              checked={profile.AnAtiendeH}
              onChange={handleChange}
            />

            <label className="form-check-label" htmlFor="AnAtiendeA">
              Apto
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtiendeA"
              name="AnAtiendeA"
              checked={profile.AnAtiendeA}
              onChange={handleChange}
            />

            <label className="form-check-label" htmlFor="AnAtiendeD">
              Domicilio
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtiendeD"
              name="AnAtiendeD"
              checked={profile.AnAtiendeD}
              onChange={handleChange}
            />
          </div>
          {/* Observaciones */}
          <label htmlFor="AnObs">Observaciones</label>
          <div className="mb-3">
            <textarea
              className="register-input"
              id="AnObs"
              name="AnObs"
              placeholder="Observaciones"
              value={profile.AnObs}
              onChange={handleChange}
            />
          </div>

          {/* Combobox de Altura */}
          <div className="form-group">
            <label htmlFor="AnAtt1">Altura</label>
            <select
              id="AnAtt1"
              name="AnAtt1"
              value={profile.AnAtt1}
              onChange={handleChange}
              className="register-select"
              required
            >
              <option value="">Seleccionar</option>
              <option value="1">Alta</option>
              <option value="2">Estatura promedio</option>
              <option value="3">Bajita</option>
            </select>
          </div>
          {/* Combobox de Pelo */}
          <div className="form-group">
            <label htmlFor="AnAtt2">Pelo</label>
            <select
              id="AnAtt2"
              name="AnAtt2"
              value={profile.AnAtt2}
              onChange={handleChange}
              className="register-select"
              required
            >
              <option value="">Seleccionar</option>
              <option value="1">Rubia</option>
              <option value="2">Morocha</option>
              <option value="3">Pelirroja</option>
              <option value="4">Castaña</option>
              <option value="5">Otro</option>
            </select>
          </div>
          {/* Combobox de Pechos */}
          <div className="form-group">
            <label htmlFor="AnAtt15">Pechos</label>
            <select
              id="AnAtt15"
              name="AnAtt15"
              value={profile.AnAtt15}
              onChange={handleChange}
              className="register-select"
            >
              <option value="">Seleccionar</option>
              <option value="1">Grandes</option>
              <option value="2">Pequeños</option>
              <option value="3">Naturales</option>
              <option value="4">Operados</option>
            </select>
          </div>
          {/* Combobox de Peso */}
          <div className="form-group">
            <label htmlFor="AnAtt16">Peso</label>
            <select
              id="AnAtt16"
              name="AnAtt16"
              value={profile.AnAtt16}
              onChange={handleChange}
              className="register-select"
            >
              <option value="">Seleccionar</option>
              <option value="1">Delgada</option>
              <option value="2">Peso promedio</option>
              <option value="3">Gordita</option>
              <option value="4">Grande</option>
            </select>
          </div>
          {/* Combobox de Etnia */}
          <div className="form-group">
            <label htmlFor="AnAtt7">Etnia</label>
            <select
              id="AnAtt7"
              name="AnAtt7"
              value={profile.AnAtt7}
              onChange={handleChange}
              className="register-select"
            >
              <option value="">Seleccionar</option>
              <option value="1">Asiática</option>
              <option value="2">Blanca</option>
              <option value="3">Latina</option>
              <option value="4">Morena</option>
            </select>
          </div>
          {/* Combobox de Orientacion */}
          <div className="form-group">
            <label htmlFor="AnAtt14">Orientación sexual</label>
            <select
              id="AnAtt14"
              name="AnAtt14"
              value={profile.AnAtt14}
              onChange={handleChange}
              className="register-select"
            >
              <option value="">Seleccionar</option>
              <option value="1">Heterosexual</option>
              <option value="2">Bisexual</option>
              <option value="3">Homosexual</option>
            </select>
          </div>
          {/* Combobox de Generacion */}
          <div className="form-group">
            <label htmlFor="AnAtt8">Generación</label>
            <select
              id="AnAtt8"
              name="AnAtt8"
              value={profile.AnAtt8}
              onChange={handleChange}
              className="register-select"
            >
              <option value="">Seleccionar</option>
              <option value="1">Lolitas</option>
              <option value="2">Universitarias</option>
              <option value="3">Maduras</option>
            </select>
          </div>
          {/* Idiomas: */}
          <div>
            <h4>Idiomas:</h4>
          </div>
          <div className="form-check mb-3">
            <label className="form-check-label" htmlFor="AnAtt9">
              Inglés
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt9"
              name="AnAtt9"
              checked={profile.AnAtt9}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt10">
              Alemán
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt10"
              name="AnAtt10"
              checked={profile.AnAtt10}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt11">
              Italiano
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt11"
              name="AnAtt11"
              checked={profile.AnAtt11}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt12">
              Francés
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt12"
              name="AnAtt12"
              checked={profile.AnAtt12}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt13">
              Portugués
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt13"
              name="AnAtt13"
              checked={profile.AnAtt13}
              onChange={handleChange}
            />
          </div>
          <div>
            <hr></hr>
            <h3 style={{ textAlign: "center" }}>Servicios</h3>
            <hr></hr>
          </div>

          <div>
            <h4>Tipo de servicio:</h4>
          </div>
          <div className="form-check mb-3">
            <label className="form-check-label" htmlFor="AnAtt22">
              Económicas
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt22"
              name="AnAtt22"
              checked={profile.AnAtt22}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt23">
              Escort de lujo
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt23"
              name="AnAtt23"
              checked={profile.AnAtt23}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt24">
              Dama de compañia
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt24"
              name="AnAtt24"
              checked={profile.AnAtt24}
              onChange={handleChange}
            />

            <hr></hr>
          </div>

          <div>
            <h4>Sexo oral:</h4>
          </div>
          <div className="form-check mb-3">
            <label className="form-check-label" htmlFor="AnAtt33">
              Con condón
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt33"
              name="AnAtt33"
              checked={profile.AnAtt33}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt34">
              Acabado en cuerpo
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt34"
              name="AnAtt34"
              checked={profile.AnAtt34}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt35">
              Acabado en cara
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt35"
              name="AnAtt35"
              checked={profile.AnAtt35}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt36">
              Garganta profunda
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt36"
              name="AnAtt36"
              checked={profile.AnAtt36}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt37">
              Me lo trago
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt37"
              name="AnAtt37"
              checked={profile.AnAtt37}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt38">
              Al natural
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt38"
              name="AnAtt38"
              checked={profile.AnAtt38}
              onChange={handleChange}
            />

            <hr></hr>
          </div>
          <div>
            <h4>Atiendo a:</h4>
          </div>
          <label className="form-check-label" htmlFor="AnAtt3">
            Hombres
          </label>
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt3"
              name="AnAtt3"
              checked={profile.AnAtt3}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt4">
              Mujeres
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt4"
              name="AnAtt4"
              checked={profile.AnAtt4}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt5">
              Parejas
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt5"
              name="AnAtt5"
              checked={profile.AnAtt5}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt17">
              Trio 2 escorts
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt17"
              name="AnAtt17"
              checked={profile.AnAtt17}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt18">
              Trio 2 clientes
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt18"
              name="AnAtt18"
              checked={profile.AnAtt18}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt19">
              Orgías
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt19"
              name="AnAtt19"
              checked={profile.AnAtt19}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt20">
              Personas disc.
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt20"
              name="AnAtt20"
              checked={profile.AnAtt20}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt21">
              Swingers
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt21"
              name="AnAtt21"
              checked={profile.AnAtt21}
              onChange={handleChange}
            />

            <hr></hr>
          </div>
          <div>
            <h4>Salidas:</h4>
          </div>
          <label className="form-check-label" htmlFor="AnAtt25">
            Domicilios
          </label>
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt25"
              name="AnAtt25"
              checked={profile.AnAtt25}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt26">
              Viajes largos
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt26"
              name="AnAtt26"
              checked={profile.AnAtt26}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt27">
              Viajes cortos
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt27"
              name="AnAtt27"
              checked={profile.AnAtt27}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt28">
              Hoteles
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt28"
              name="AnAtt28"
              checked={profile.AnAtt28}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt29">
              Fiestas
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt29"
              name="AnAtt29"
              checked={profile.AnAtt29}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt30">
              Eventos
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt30"
              name="AnAtt30"
              checked={profile.AnAtt30}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt31">
              Despedidas
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt31"
              name="AnAtt31"
              checked={profile.AnAtt31}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt32">
              Viajes al exterior
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt32"
              name="AnAtt32"
              checked={profile.AnAtt32}
              onChange={handleChange}
            />

            <hr></hr>
          </div>
          <div>
            <h4>Otros:</h4>
          </div>
          <label className="form-check-label" htmlFor="AnAtt39">
            Lésbico real
          </label>
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt39"
              name="AnAtt39"
              checked={profile.AnAtt39}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt40">
              Copro
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt40"
              name="AnAtt40"
              checked={profile.AnAtt40}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt41">
              Besos en boca
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt41"
              name="AnAtt41"
              checked={profile.AnAtt41}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt42">
              Beso negro
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt42"
              name="AnAtt42"
              checked={profile.AnAtt42}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt43">
              Lencería
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt43"
              name="AnAtt43"
              checked={profile.AnAtt43}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt44">
              Juguetes eróticos
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt44"
              name="AnAtt44"
              checked={profile.AnAtt44}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt45">
              Footjob
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt45"
              name="AnAtt45"
              checked={profile.AnAtt45}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt46">
              Fantasías
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt46"
              name="AnAtt46"
              checked={profile.AnAtt46}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt47">
              Anal
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt47"
              name="AnAtt47"
              checked={profile.AnAtt47}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt48">
              Strap on
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt48"
              name="AnAtt48"
              checked={profile.AnAtt48}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt60">
              Venta contenido
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt60"
              name="AnAtt60"
              checked={profile.AnAtt60}
              onChange={handleChange}
            />

            <hr></hr>
          </div>

          <div>
            <h4>Lugar:</h4>
          </div>

          <div className="form-check mb-3">
            <label className="form-check-label" htmlFor="AnAtt49">
              Apto compartido
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt49"
              name="AnAtt49"
              checked={profile.AnAtt49}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt50">
              Apto privado
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt50"
              name="AnAtt50"
              checked={profile.AnAtt50}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt51">
              Bebidas
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt51"
              name="AnAtt51"
              checked={profile.AnAtt51}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt52">
              Cama matrimonio
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt52"
              name="AnAtt52"
              checked={profile.AnAtt52}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt53">
              Casa
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt53"
              name="AnAtt53"
              checked={profile.AnAtt53}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt54">
              Centro de masajes
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt54"
              name="AnAtt54"
              checked={profile.AnAtt54}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt55">
              Climatizado
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt55"
              name="AnAtt55"
              checked={profile.AnAtt55}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt56">
              Con ducha
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt56"
              name="AnAtt56"
              checked={profile.AnAtt56}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt57">
              Jacuzzi
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt57"
              name="AnAtt57"
              checked={profile.AnAtt57}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt58">
              Pago con tarjeta
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt58"
              name="AnAtt58"
              checked={profile.AnAtt58}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="AnAtt59">
              Parking cerca
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="AnAtt59"
              name="AnAtt59"
              checked={profile.AnAtt59}
              onChange={handleChange}
            />
          </div>
          {/* Botones de acción */}
          <div className="form-actions">
            <button type="submit" className="btn btn-primary">
              Guardar
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleCancel}
            >
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ModifyProfile;
