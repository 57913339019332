// useAuth.js
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {jwtDecode} from "jwt-decode"; // Corrige el nombre si está mal importado

const useAuth = (requiredId) => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [tipo, setTipo] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      setIsLoading(false);
      navigate("/login");
      return;
    }

    try {
      const decoded = jwtDecode(token);
  
      const currentTime = Math.floor(Date.now() / 1000); // Tiempo actual en segundos

      // Validar si el token ha expirado
   
      if (decoded.exp < currentTime) {
        console.warn("El token ha expirado.");
        localStorage.removeItem("token");
        navigate("/login");
        return;
      }

      setTipo(decoded.tipo);
      
      // Validar si el ID del token coincide con el requerido
      if (String(decoded.id) === String(requiredId)) {
        setIsAuthenticated(true);
      } else {
        console.warn("El ID del token no coincide con el requerido.");
        navigate("/login");
      }
    } catch (error) {
      console.error("Error al decodificar el token:", error);
      localStorage.removeItem("token");
      navigate("/login");
    } finally {
      setIsLoading(false); // Finaliza la carga después de la verificación
    }
  }, [navigate, requiredId]);

  return { isAuthenticated, isLoading, tipo };
};

export default useAuth;
