import React, { useEffect, useRef, useState } from 'react';
import { jwtDecode } from "jwt-decode"; // Importación de jwtDecode
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../components/Style.css';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import MobileTabMenu from './MobileTabMenu';
import '../components/Footer.css'; // Importar CSS del footer (opcional)



// Footer Component
const Footer = () => {
  return (

    <footer className='footer' style={{ backgroundColor: "black", color: "white", padding: "20px 0" }}>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "15px" }}>
        <a href='/khalii' style={{ fontSize: "18px", fontWeight: "bold", margin: 0, color: "#ea0286" , marginInlineStart: "10px"}}>Khalii 2.0</a>
        <a href="/nosotros" style={{ color: "white", textDecoration: "none", marginLeft: "15px" }}>
          Nosotros
        </a>
        <a href="/terminos-y-condiciones" style={{ color: "white", textDecoration: "none", marginLeft: "15px" }}>
          Términos y Condiciones
        </a>
        <a href="https://www.rtalabel.org/index.html" target="_blank" rel="noopener noreferrer">
          <img
            src="/120x60_RTA-5042-1996-1400-1577-RTA_c.gif" // Reemplaza con la ruta real de tu imagen
            alt="Enlace"
            style={{ height: "30px", width: "auto" }}
          />
        </a>
      </div>
    </footer>
  );
};

const MasterPage = ({ children }) => {
  // (Rest of the MasterPage code remains unchanged)
  const [menu, setMenu] = useState([]);
  const [openSubmenus, setOpenSubmenus] = useState({});
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isMobileTabOpen, setIsMobileTabOpen] = useState(false);
  const [tipo, setTipo] = useState('');
  const [modifiedUser, setModifiedUser] = useState('');
  const navigate = useNavigate();

  const sidebarRef = useRef(null);
  const mobileTabRef = useRef(null);

  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const host = process.env.REACT_APP_API_HOST;
        const response = await axios.get(`${host}/api/menu`);
        setMenu(response.data);
      } catch (error) {
        console.error('Error fetching menu:', error);
      }
    };
    fetchMenu();

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setTipo(decodedToken.tipo);
        setModifiedUser(decodedToken.id);
      } catch (error) {
        console.error('Error decoding token:', error);
        localStorage.removeItem('token');
      }
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarOpen &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        !event.target.closest('.menu-button')
      ) {
        setSidebarOpen(false);
      }

      if (
        isMobileTabOpen &&
        mobileTabRef.current &&
        !mobileTabRef.current.contains(event.target)
      ) {
        setIsMobileTabOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [sidebarOpen, isMobileTabOpen]);

  const handleToggle = (id) => {
    setOpenSubmenus((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleMobileTabMenu = () => {
    setIsMobileTabOpen(!isMobileTabOpen);
  };

  const handleLoginRedirect = () => {
    if (tipo === 'E') {
      navigate(`/panel/${modifiedUser}`);
    } else {
      navigate('/login');
    }
  };

  const renderMenuItems = (items) => {
    return (
      <ul>
        {items.map((item) => (
          <li key={item.id} className={item.subitems.length ? 'has-subitems' : ''}>
            <Link
              to={item.subitems.length ? '#' : item.url}
              onClick={
                item.subitems.length
                  ? (e) => {
                      e.preventDefault();
                      handleToggle(item.id);
                    }
                  : undefined
              }
            >
              <i className={`fa ${item.icono}`}></i> {item.nombre}
              {item.subitems.length > 0 && (
                <span className="submenu-toggle">
                  <i className={`fa ${openSubmenus[item.id] ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                </span>
              )}
            </Link>
            {item.subitems.length > 0 && openSubmenus[item.id] && (
              <div className="submenu submenu-items">
                {renderMenuItems(item.subitems)}
              </div>
            )}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="master-page">
      <header className="header">
        <div className="logo">
          <img src="/Logo_Khalii_Header_New.png" alt="Logo" />
        </div>
        {isMobile ? (
          <button className="menu-button" onClick={toggleSidebar}>
            <span className="hamburger"></span>
          </button>
        ) : (
          <button className="user-icon-button" onClick={handleLoginRedirect}>
            <i className="fas fa-user"></i>
          </button>
        )}
      </header>

      <div ref={sidebarRef} className={`sidebar ${sidebarOpen ? 'open' : ''}`}>
        {menu.length > 0 ? renderMenuItems(menu) : <p style={{marginTop: "10px"}}>Cargando...</p>}
      </div>

      <div className={`content ${sidebarOpen ? 'menu-open' : ''}`}>
        {children}
      </div>

      {isMobile && (
        <div ref={mobileTabRef}>
          <MobileTabMenu isOpen={isMobileTabOpen} toggle={toggleMobileTabMenu} tipo={tipo} modifiedUser={modifiedUser} />
        </div>
      )}

      {/* Footer añadido */}
      <Footer />
    </div>
  );
};

export default MasterPage;
